
<template>
    <!--begin::Root-->
		<div class="d-flex flex-column flex-root" id="kt_app_root">
			<!--begin::Authentication - Sign-in -->
			<div class="d-flex flex-column flex-lg-row flex-column-fluid">
				<!--begin::Body-->
				<div class="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
					<!--begin::Form-->
					<div class="d-flex flex-center flex-column flex-lg-row-fluid">
						<!--begin::Wrapper-->
						<div class="w-lg-500px p-10">
							<!--begin::Form-->
							<form class="form w-100" novalidate="novalidate" id="kt_sign_in_form" data-kt-redirect-url="/" action="" @submit.prevent="onSubmit">
								<!--begin::Heading-->
								<div class="text-center mb-11">
									<!--begin::Logo-->
						<a class="mb-0 mb-lg-12">
							<img alt="Logo" src="./../../../../public/frontend/media/logos/logo4.png" class="h-60px h-lg-75px" />
						</a>
						<!--end::Logo-->
									<!--begin::Title-->
									<h1 class="text-gray-900 fw-bolder mb-3">Đăng nhập hệ thống Nolimit</h1>
									<!--end::Title-->
									<!--begin::Subtitle-->
									<div class="text-gray-500 fw-semibold fs-6">Chào mừng bạn trở lại với User/Admin của nền tảng Nolimit AdsManager. Chúc bạn có 1 ngày làm việc với năng suất vượt trội.</div>
									<!--end::Subtitle=-->
								</div>
								<!--begin::Heading-->
								<!--begin::Input group=-->
								<div class="fv-row mb-8">
									<!--begin::Email-->
									<input v-model="user.userName" type="text" placeholder="Tên đăng nhập" name="userName" autocomplete="off" class="form-control bg-transparent" />
									<!--end::Email-->
									<div v-if="this.errors.userName != ''" class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
										<div data-field="userName" data-validator="notEmpty">{{ this.errors.userName }}</div>
									</div>
								</div>
								
								<!--end::Input group=-->
								<div class="fv-row mb-3">
									<!--begin::Password-->
									<input v-model="user.password" type="password" placeholder="Mật khẩu" name="password" autocomplete="off" class="form-control bg-transparent" />
									<!--end::Password-->
									<div v-if="this.errors.password != ''" class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
										<div data-field="password" data-validator="notEmpty">{{ this.errors.password }}</div>
									</div>
								</div>
								<!--end::Input group=-->
								<!--begin::Wrapper-->
								<div class="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
									<div></div>
									<!--begin::Link-->
									<!--end::Link-->
								</div>
								<!--end::Wrapper-->
								<!--begin::Submit button-->
								<div class="d-grid mb-10">
									<button type="submit" id="kt_sign_in_submit" class="btn btn-primary">
										<!--begin::Indicator label-->
										<span class="indicator-label">Đăng nhập</span>
										<!--end::Indicator label-->
										<!--begin::Indicator progress-->
										<span class="indicator-progress">Please wait... 
										<span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
										<!--end::Indicator progress-->
									</button>
								</div>
								<!--end::Submit button-->
								<!--begin::Sign up-->
								<div class="text-gray-500 text-center fw-semibold fs-6">Chưa có tài khoản? 
								<router-link to="/SignUp" class="link-primary">Đăng ký ngay</router-link></div>
								<!--end::Sign up-->
							</form>
							<!--end::Form-->
						</div>
						<!--end::Wrapper-->
					</div>
					<!--end::Form-->
					
				</div>
				<!--end::Body-->
				<!--begin::Aside-->
				<div class="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2 background-authen" style="">
					<!--begin::Content-->
					<div class="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100" style="min-height: 100vh;">
						<!--begin::Logo-->
						<a href="index.html" class="mb-0 mb-lg-12">
							<img alt="Logo" src="./../../../../public/frontend/media/logos/logo-background.png" class="h-60px h-lg-75px" />
						</a>
						<!--end::Logo-->
						<!--begin::Image-->
						<!-- <img class="d-none d-lg-block mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20" src="./../../../../public/frontend/media/misc/auth-screens.png" alt="" /> -->
						
						<!--end::Image-->
						<div style="height: 150px;"></div>
					</div>
					<!--end::Content-->
				</div>
				<!--end::Aside-->
			</div>
			<!--end::Authentication - Sign-in-->
		</div>
</template>
<style>
@media only screen and (max-width: 995px) {
.background-authen{
	display: none !important;
}
}

</style>
<style scope>
.bgi-position-center{
	background-image: url(./../../../../public/frontend/media/auth/bg-custom.jpg) !important
}
.fv-plugins-message-container{
	display: flex !important;
}
</style>
<script>
import Swal from 'sweetalert2';
import router from '@/router/router.js';
export default{
	data() {
		return {
			user:{
				userName: "",
				password: "",
				rememberPaswword: true
                },
			errors:{
				userName: "",
				password: "",
			},	
			isValidate:false,
		}
	},
	methods: {
		validate()
		{
			this.isValidate = true;
			this.errors={
				userName: "",
				password: "",
			};
			if(!this.user.userName){
				this.errors.userName = "Vui lòng điền tên đăng nhập"
				this.isValidate = false;
			}else if(!this.validUserName(this.user.userName)){
				this.errors.userName = "Tên đăng nhập phải có ít nhất 6 đến 15 ký tự"
				this.isValidate = false;
			}
			if(!this.user.password){
				this.errors.password = "Vui lòng điền mật khẩu"
				this.isValidate = false;
			}else if(!this.validPassword(this.user.password)){
				this.errors.password = "Mật khẩu phải ít nhất 8 ký tự và có ít nhất 1 chữ và 1 số, không gồm ký tự đặc biệt!"
				this.isValidate = false;
			}
			return this.isValidate;
		},
		validUserName(userName){
			var re = /^[A-Za-z0-9_-]{6,15}$/;
			return re.test(userName);
		},	
		validPassword(password){
			var re = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
			return re.test(password);
		},	
		onSubmit(){
			if(this.validate()){
				this.$apiService.signIn(this.user)
				.then((res)=>{
					window.localStorage.setItem("id-token", res.data.data);
					Swal.fire({
						text: "Đăng nhập thành công!",
						icon: "success",
						buttonsStyling: false,
						confirmButtonText: "Tôi hiểu rồi!",
						heightAuto: false,
						customClass: {
						confirmButton: "btn fw-semobold btn-light-primary",
						},
					}).then(() => {
						router.push({ name: 'DashBoardV2' });
					});
				}).catch((res)=>{
					console.log(res.response.data.message);
					Swal.fire({
						text: res.response.data.message, // Hiển thị thông điệp lỗi từ server
						icon: "error",
						buttonsStyling: false,
						confirmButtonText: "Thử lại!",
						heightAuto: false,
						customClass: {
						confirmButton: "btn fw-semobold btn-light-danger",
						},
					}).then(() => {
					});
				})
			}
			// Xử lý lỗi phản hồi từ server
		},
	},
}
</script>