<template>
  <div class="flex-lg-auto w-lg-500px">
    <!--begin::Card-->
    <div
      class="card"
      data-kt-sticky="true"
      data-kt-sticky-name="invoice"
      data-kt-sticky-offset="{default: false, lg: '200px'}"
      data-kt-sticky-width="{lg: '250px', lg: '300px'}"
      data-kt-sticky-left="auto"
      data-kt-sticky-top="150px"
      data-kt-sticky-animation="false"
      data-kt-sticky-zindex="95"
      style=""
    >
      <!--begin::Card body-->
      <div class="card-body p-10">
        <!--begin::Input group-->
        <div class="mb-10" style="margin-bottom: 0px !important">
          <!--begin::Label-->
          <label
            class="form-label fw-bold fs-6 text-gray-700"
            style="font-size: 20px !important"
            > {{ this.$NOLIMITResource[this.dataUser.language].Recharge.Title }}
            </label>
          <!--end::Label-->
          <div class="input-group mb-5">
            <input
              type="text"
              class="form-control"
              placeholder="Nhập số tiền muốn nạp vào website"
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
              v-model="this.depositHistory.depositAmount"
            />
            <span class="input-group-text" id="basic-addon2">
              VND
            </span>
          </div>
        </div>
        <!--end::Input group-->
        <p style="color:#1e94ce;text-align: start;margin-left: 10px;">{{ this.$NOLIMITResource[this.dataUser.language].Recharge.DepositAmount }}: {{formatAmountDisplay}}</p>
        <!--begin::Actions-->
        <div class="mb-0">
          <!--begin::Row-->
          <div class="row mb-5"></div>
          <!--end::Row-->
          <button
            type="submit"
            class="btn btn-primary w-100"
            id="kt_invoice_submit_button"
            @click="addDeposit"
          >
            <i class="ki-duotone ki-triangle fs-3"
              ><span class="path1"></span><span class="path2"></span
              ><span class="path3"></span
            ></i>
            {{ this.$NOLIMITResource[this.dataUser.language].Recharge.CreateInvoice }}
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Card body-->
    </div>
    <!--end::Card-->
  </div>
</template>
<style scoped>
.w-lg-500px {
  margin-left: 40px;
}
</style>
<script>
import { mapActions } from "vuex";
import router from '@/router/router.js';
export default {
  name: "DepositRecharge",
  data() {
    return {
      dataUser:{language:"VN"},
      depositHistory: {
        depositAmount: 0,
      },
      errors:{
				amount: "",
			}
    };
  },
  computed:{
    formatAmountDisplay() {
      return this.formatCurrencyValue(this.depositHistory.depositAmount) + " VND";
    },
  },
  async created() {
    await this.$apiService
    .getUserProfile().then((response) => {
      if(response.data.data.language == "VN" || response.data.data.language == "EN"){
          this.dataUser = response.data.data;
      }else{
          this.dataUser = response.data.data;
          this.dataUser.language = "VN";
      }
    })
  },
  methods: {
    ...mapActions("listModule", ["swalSuccess","swalError","swalWarning"]),
    addDeposit() {
      if(this.validate()){
        this.$apiService.getBankNow().then((response)=>{
          this.depositHistory.nameBank = response.data.data.bank;
          this.depositHistory.numberBank = response.data.data.value;
          this.depositHistory.ownerBank = response.data.data.name;
          this.$apiService.addDepositHistory(this.depositHistory)
        .then((res) => {

          this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].Recharge.CreateInvoiceSuccess).then(() => {
						router.push({ name: 'DetailDeposit', query: { idDeposit: res.data.depositHistoryId }, props: true });
					});
        })
        .catch((res) => {
          this.swalError({message:res.response.data.message,language: this.dataUser.language});
        });
        })
      }else{
        this.swalError({message:this.$NOLIMITResource[this.dataUser.language].Recharge.AmountNotValidate, language: this.dataUser.language});
      }
    },
    validate()
		{
			this.isValidate = true;
			this.errors={
          amount:''
			};
			if(!this.depositHistory.depositAmount){
				this.errors.amount = this.$NOLIMITResource[this.dataUser.language].AdsAccount.ValidateEmptyNumber
				this.isValidate = false;
			}else if(!this.validAmount(this.depositHistory.depositAmount)){
				this.errors.amount = this.$NOLIMITResource[this.dataUser.language].AdsAccount.ValidateIsNotNumber
				this.isValidate = false;
			}
			return this.isValidate;
		},
    validAmount(amount){
			var re = /^[0-9]+(\.[0-9]+)?$/;
			return re.test(amount);
		},
    formatCurrencyValue(value) {
      // Chuyển đổi số thành chuỗi
      let stringValue = value.toString();

      // Chia chuỗi thành mảng các nhóm mỗi 3 chữ số
      let parts = stringValue.split('.');
      let mainPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

      // Nếu có phần thập phân, thêm vào chuỗi
      let result = parts.length > 1 ? mainPart + ',' + parts[1] : mainPart;

      return result;
    },
  },
};
</script>
