<template>
  <div>
    <div id="fb-root" class="fb_reset">
      <div style="position: absolute; top: -10000px; width: 0px; height: 0px">
        <div></div>
      </div>
      <div
        class="fb_iframe_widget fb_invisible_flow"
        fb-iframe-plugin-query="app_id=&amp;attribution=biz_inbox&amp;container_width=1010&amp;current_url=https%3A%2F%2Fsmit.vn%2Fpolicy&amp;is_loaded_by_facade=true&amp;local_state=%7B%22v%22%3A2%2C%22path%22%3A2%2C%22chatState%22%3A1%2C%22visibility%22%3A%22hidden%22%2C%22showUpgradePrompt%22%3A%22not_shown%22%2C%22greetingVisibility%22%3A%22hidden%22%2C%22shouldShowLoginPage%22%3Afalse%7D&amp;locale=vi_VN&amp;log_id=c5b27a79-f64b-4f9e-81ce-c29dbf4a5458&amp;page_id=106817685565872&amp;request_time=1714122471875&amp;sdk=joey"
      >
        <span style="vertical-align: bottom; width: 1000px; height: 0px"
          ><iframe
            name="f772a7a41e3536b0b"
            width="1000px"
            height="1000px"
            data-testid="dialog_iframe"
            title=""
            frameborder="0"
            allowtransparency="true"
            allowfullscreen="true"
            scrolling="no"
            allow="encrypted-media"
            src="https://www.facebook.com/v13.0/plugins/customerchat.php?app_id=&amp;attribution=biz_inbox&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df227075fa6004cd11%26domain%3Dsmit.vn%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Fsmit.vn%252Ff8c3844ec68c328bd%26relation%3Dparent.parent&amp;container_width=1010&amp;current_url=https%3A%2F%2Fsmit.vn%2Fpolicy&amp;is_loaded_by_facade=true&amp;local_state=%7B%22v%22%3A2%2C%22path%22%3A2%2C%22chatState%22%3A1%2C%22visibility%22%3A%22hidden%22%2C%22showUpgradePrompt%22%3A%22not_shown%22%2C%22greetingVisibility%22%3A%22hidden%22%2C%22shouldShowLoginPage%22%3Afalse%7D&amp;locale=vi_VN&amp;log_id=c5b27a79-f64b-4f9e-81ce-c29dbf4a5458&amp;page_id=106817685565872&amp;request_time=1714122471875&amp;sdk=joey"
            style="
              padding: 0px;
              position: fixed;
              z-index: 2147483646;
              border-radius: 16px;
              top: auto;
              background: none;
              width: 324px;
              bottom: 84px;
              max-height: 0px;
              right: 16px;
              visibility: visible;
              height: 293px;
            "
            class=""
          ></iframe
        ></span>
      </div>
      <div
        class="fb_dialog fb_dialog_advanced"
        alignment="right"
        desktop_bottom_spacing="24"
      >
        <div class="fb_dialog_content">
          <iframe
            name="blank_f772a7a41e3536b0b"
            width="60px"
            tabindex="-1"
            data-testid="bubble_iframe"
            frameborder="0"
            allowtransparency="true"
            allowfullscreen="true"
            scrolling="no"
            allow="encrypted-media"
            src="https://www.facebook.com/v13.0/plugins/customer_chat/bubble"
            style="
              background: none;
              border-radius: 60px;
              bottom: 24px;
              box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 12px 0px;
              display: block;
              height: 60px;
              margin: 0px 12px;
              overflow: visible;
              padding: 0px;
              position: fixed;
              right: 12px;
              top: auto;
              width: 60px;
              z-index: 2147483644;
            "
          ></iframe
          ><iframe
            name="availabilityStatus_f772a7a41e3536b0b"
            tabindex="-1"
            data-testid="availabilityStatus_iframe"
            frameborder="0"
            allowtransparency="true"
            allowfullscreen="true"
            scrolling="no"
            allow="encrypted-media"
            src="https://www.facebook.com/v13.0/plugins/customer_chat/bubble"
            style="
              border-radius: 50%;
              bottom: 21.5px;
              height: 15px;
              position: fixed;
              right: 24px;
              width: 15px;
              z-index: 2147483646;
            "
          ></iframe
          ><iframe
            name="unread_f772a7a41e3536b0b"
            tabindex="-1"
            data-testid="unread_iframe"
            frameborder="0"
            allowtransparency="true"
            allowfullscreen="true"
            scrolling="no"
            allow="encrypted-media"
            src="https://www.facebook.com/v13.0/plugins/customer_chat/bubble"
            style="
              background: none;
              border-radius: 4pt;
              bottom: 68px;
              height: 24px;
              position: fixed;
              right: 22px;
              width: 20px;
              z-index: 2147483645;
            "
          ></iframe
          ><iframe
            name="greeting_f772a7a41e3536b0b"
            tabindex="-1"
            data-testid="greeting_iframe"
            frameborder="0"
            allowtransparency="true"
            allowfullscreen="true"
            scrolling="no"
            allow="encrypted-media"
            src="https://www.facebook.com/v13.0/plugins/customer_chat/bubble"
            style="border: none; max-height: 0px; min-height: 0px"
          ></iframe>
        </div>
      </div>
    </div>
    <div id="app" data-v-app="">
      <div data-v-1b27b06e="" class="navbar">
        <a data-v-1b27b06e="" href="https://smit.vn"
          ><img data-v-1b27b06e="" class="logo" src="./../../../../public/frontend/media/logos/logo3.png"
        /></a>
        <div data-v-1b27b06e="" class="router">
        
        </div>
        <router-link data-v-1b27b06e="" class="login bg-main btn hover" to="/SignIn">
          Đăng nhập
        </router-link>
        <div data-v-1b27b06e="" class="menu-icon">
          <div data-v-1b27b06e="" class="menu"></div>
        </div>
      </div>
      <div data-v-1b27b06e="" class="menu mobile">
        <div data-v-1b27b06e="" class="menu-wrapper">
          <div data-v-1b27b06e="" class="menu-item main">
            <a data-v-1b27b06e="" href="/" class="active-link item"
              >Trang chủ</a
            >
            <div data-v-1b27b06e="" class="item list">Bộ giải pháp</div>
            <a data-v-1b27b06e="" href="/about-us" class="item">Về chúng tôi</a
            ><a
              data-v-1b27b06e=""
              class="item"
              target="_blank"
              href="https://youtube.smit.vn"
              >Hướng dẫn</a
            ><a
              data-v-1b27b06e=""
              class="item"
              target="_blank"
              href="https://support.smit.vn"
              >Hỗ trợ</a
            >
            <div data-v-1b27b06e="" class="login">Đăng nhập</div>
            <div data-v-1b27b06e="" class="reserve">
              © 2022 All rights reserved smit.vn
            </div>
          </div>
          <div data-v-1b27b06e="" class="menu-item solution">
            <div data-v-1b27b06e="" class="back">Back</div>
            <div data-v-1b27b06e="" class="solution-menu first">
              <div data-v-1b27b06e="" class="title">Quản lý và báo cáo</div>
              <div data-v-1b27b06e="" class="items">
                <a
                  data-v-1b27b06e=""
                  href="/ads-check-by-smit"
                  class="solution-menu-box"
                  style="margin-top: 20px"
                  ><img
                    data-v-1b27b06e=""
                    src="/images/navbar/menu-adscheck.svg"
                  />
                  <div data-v-1b27b06e="">
                    <div
                      data-v-1b27b06e=""
                      style="
                        font-family: Bold;
                        font-size: 15px;
                        color: rgb(35, 38, 47);
                      "
                    >
                      Ads Check by NOLIMIT
                    </div>
                    <div
                      data-v-1b27b06e=""
                      style="
                        font-family: Regular;
                        color: rgb(119, 126, 144);
                        font-size: 15px;
                      "
                    >
                      Xem &amp; quản lý TKQC
                    </div>
                  </div></a
                ><a
                  data-v-1b27b06e=""
                  href="/ads-check-lite"
                  class="solution-menu-box"
                  style="margin-top: 20px"
                  ><img
                    data-v-1b27b06e=""
                    src="/images/navbar/menu-adscheck-lite.svg"
                  />
                  <div data-v-1b27b06e="">
                    <div
                      data-v-1b27b06e=""
                      style="
                        font-family: Bold;
                        font-size: 15px;
                        color: rgb(35, 38, 47);
                      "
                    >
                      Ads Check Lite
                    </div>
                    <div
                      data-v-1b27b06e=""
                      style="
                        font-family: Regular;
                        color: rgb(119, 126, 144);
                        font-size: 15px;
                      "
                    >
                      Xem nhanh chỉ số TKQC
                    </div>
                  </div></a
                ><a
                  data-v-1b27b06e=""
                  class="solution-menu-box"
                  href="/coming-soon"
                  style="margin-top: 20px"
                  ><img
                    data-v-1b27b06e=""
                    src="/images/navbar/menu-adsmanager-2.svg"
                  />
                  <div data-v-1b27b06e="">
                    <div
                      data-v-1b27b06e=""
                      style="
                        font-family: Bold;
                        font-size: 15px;
                        color: rgb(35, 38, 47);
                      "
                    >
                      Ads Manager Pro
                    </div>
                    <div
                      data-v-1b27b06e=""
                      style="
                        font-family: Regular;
                        color: rgb(119, 126, 144);
                        font-size: 15px;
                      "
                    >
                      Quản lý chiến dịch quảng cáo
                    </div>
                  </div></a
                >
              </div>
            </div>
            <div data-v-1b27b06e="" class="solution-menu">
              <div data-v-1b27b06e="" class="title">Quản lý hỗ trợ</div>
              <div data-v-1b27b06e="" class="items">
                <a
                  data-v-1b27b06e=""
                  href="/smit-translator"
                  class="solution-menu-box"
                  style="margin-top: 20px"
                  ><img
                    data-v-1b27b06e=""
                    src="/images/navbar/menu-smit-translator.svg"
                  />
                  <div data-v-1b27b06e="">
                    <div
                      data-v-1b27b06e=""
                      style="
                        font-family: Bold;
                        font-size: 15px;
                        color: rgb(35, 38, 47);
                      "
                    >
                      NOLIMIT Translator
                    </div>
                    <div
                      data-v-1b27b06e=""
                      style="
                        font-family: Regular;
                        color: rgb(119, 126, 144);
                        font-size: 15px;
                      "
                    >
                      Dịch hội thoại đa nền tảng
                    </div>
                  </div></a
                ><a
                  data-v-1b27b06e=""
                  href="/smit-cookies"
                  class="solution-menu-box"
                  style="margin-top: 20px"
                  ><img
                    data-v-1b27b06e=""
                    src="/images/navbar/menu-cookie-manager.svg"
                  />
                  <div data-v-1b27b06e="">
                    <div
                      data-v-1b27b06e=""
                      style="
                        font-family: Bold;
                        font-size: 15px;
                        color: rgb(35, 38, 47);
                      "
                    >
                      NOLIMIT Cookies
                    </div>
                    <div
                      data-v-1b27b06e=""
                      style="
                        font-family: Regular;
                        color: rgb(119, 126, 144);
                        font-size: 15px;
                      "
                    >
                      Trình quản lý Cookies nâng cao
                    </div>
                  </div></a
                ><a
                  data-v-1b27b06e=""
                  class="solution-menu-box"
                  href="https://tools.smit.vn/find-id"
                  style="margin-top: 20px"
                  ><img
                    data-v-1b27b06e=""
                    src="/images/navbar/menu-find-fbid.svg"
                  />
                  <div data-v-1b27b06e="">
                    <div
                      data-v-1b27b06e=""
                      style="
                        font-family: Bold;
                        font-size: 15px;
                        color: rgb(35, 38, 47);
                      "
                    >
                      Find Facebook ID
                    </div>
                    <div
                      data-v-1b27b06e=""
                      style="
                        font-family: Regular;
                        color: rgb(119, 126, 144);
                        font-size: 15px;
                      "
                    >
                      Tra cứu Facebook UID
                    </div>
                  </div></a
                ><a
                  data-v-1b27b06e=""
                  class="solution-menu-box"
                  href="https://tools.smit.vn/smit-check"
                  style="margin-top: 20px"
                  ><img
                    data-v-1b27b06e=""
                    src="/images/navbar/menu-total-check.svg"
                  />
                  <div data-v-1b27b06e="">
                    <div
                      data-v-1b27b06e=""
                      style="
                        font-family: Bold;
                        font-size: 15px;
                        color: rgb(35, 38, 47);
                      "
                    >
                      NOLIMIT Check
                    </div>
                    <div
                      data-v-1b27b06e=""
                      style="
                        font-family: Regular;
                        color: rgb(119, 126, 144);
                        font-size: 15px;
                      "
                    >
                      Xác thực tính nguyên bản file
                    </div>
                  </div></a
                ><a
                  data-v-1b27b06e=""
                  href="/smit-ads-finder"
                  class="solution-menu-box"
                  style="margin-top: 20px"
                  ><img
                    data-v-1b27b06e=""
                    src="/images/navbar/menu-smit-adsfinder.svg"
                  />
                  <div data-v-1b27b06e="">
                    <div
                      data-v-1b27b06e=""
                      style="
                        font-family: Bold;
                        font-size: 15px;
                        color: rgb(35, 38, 47);
                      "
                    >
                      NOLIMIT Ads Finder
                    </div>
                    <div
                      data-v-1b27b06e=""
                      style="
                        font-family: Regular;
                        color: rgb(119, 126, 144);
                        font-size: 15px;
                      "
                    >
                      Tìm kiếm bài viết quảng cáo
                    </div>
                  </div></a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div data-v-2bbd8d1d="" class="wrapper">
        <div data-v-2bbd8d1d="" class="left">
          <router-link
            data-v-2bbd8d1d=""
            aria-current="page"
            to="/Policy"
            class="active-link exact-active-link item"
            >Chính sách bảo mật</router-link
          ><router-link data-v-2bbd8d1d="" to="/TemsAndPrivacy" class="item"
            >Điều khoản dịch vụ</router-link
          ><router-link data-v-2bbd8d1d="" to="/Disclaimer" class="item" style="    text-align: left;"
            >Tuyên bố miễn trừ trách nhiệm</router-link
          >
        </div>
        <div data-v-2bbd8d1d="" class="right">
          <div data-v-29f9b493="" class="_h1_8pcyr_1">
            Chính sách bảo mật của NOLIMIT
          </div>
          <div data-v-29f9b493="" class="s.bottom_border _text_8pcyr_12">
            <div data-v-29f9b493="" class="_title_8pcyr_12">
              1. Thông tin mà NOLIMIT thu thập
            </div>
            <div data-v-29f9b493="" class="_desc_8pcyr_17">
              <div data-v-29f9b493="">
                Nhằm cung cấp dịch vụ và mang đến trải nghiệm tốt hơn cho tất cả
                người dùng, chúng tôi thu nhập thông tin từ bạn khi bạn đăng ký
                trên trang web của NOLIMIT. Cụ thể, khi bạn tạo một tài khoản, bạn
                cung cấp cho chúng tôi thông tin cá nhân (bao gồm họ và tên, số
                điện thoại, địa chỉ email liên lạc). Tuy nhiên, bạn vẫn có thể
                truy cập trang của chúng tôi một cách không định danh. Điều này
                có nghĩa là bạn có thể vào trang mà không tiết lộ bất kỳ thông
                tin nào về mình.
              </div>
              <div data-v-29f9b493="">
                Về việc tùy chỉnh thông tin, bạn có thể tự chỉnh sửa họ và tên
                trong trang Quản lý tài khoản trên trang web. Nếu thay đổi email
                đăng nhập hoặc số điện thoại, bạn cần gửi yêu cầu cho chúng tôi
                và trải qua các bước xác thực.
              </div>
            </div>
          </div>
          <div data-v-29f9b493="" class="s.bottom_border _text_8pcyr_12">
            <div data-v-29f9b493="" class="_title_8pcyr_12">
              2. Mục đích NOLIMIT thu thập thông tin
            </div>
            <div data-v-29f9b493="" class="_desc_8pcyr_17">
              <div data-v-29f9b493="">
                NOLIMIT sử dụng dữ liệu thu thập được cho các mục đích chung: xác
                định và xác thực tài khoản, cải thiện dịch vụ, chăm sóc khách
                hàng và nghiên cứu. Ngoài ra, thông tin và tài khoản đăng ký của
                bạn sẽ được lưu trữ không thời hạn để bạn có thể gia hạn dịch vụ
                bất cứ lúc nào.
              </div>
            </div>
          </div>
          <div data-v-29f9b493="" class="s.bottom_border _text_8pcyr_12">
            <div data-v-29f9b493="" class="_title_8pcyr_12">
              3. Bảo mật thông tin
            </div>
            <div data-v-29f9b493="" class="_desc_8pcyr_17">
              <div data-v-29f9b493="">
                NOLIMIT rất coi trọng vấn đề bảo mật thông tin của người dùng. Vì
                vậy, chúng tôi không bán, trao đổi, hoặc sử dụng các hình thức
                thương mại khác với những thông tin cá nhân của bạn.
              </div>
            </div>
          </div>
          <div data-v-29f9b493="" class="s.bottom_border _text_8pcyr_12">
            <div data-v-29f9b493="" class="_title_8pcyr_12">
              4. Lưu trữ thông tin
            </div>
            <div data-v-29f9b493="" class="_desc_8pcyr_17">
              <div data-v-29f9b493="">
                NOLIMIT cam kết tuyệt đối không lưu trữ bất kỳ dữ liệu nào của
                người dùng trong quá trình sử dụng các sản phẩm/dịch vụ mà chúng
                tôi cung cấp.
              </div>
              <div data-v-29f9b493="">
                Tuy nhiên, Cookies Manager sẽ có chính sách lưu trữ thông tin
                riêng do sản phẩm này có tính năng đồng bộ dữ liệu lên hệ thống
                đám mây.
              </div>
              <div data-v-29f9b493="">
                Bạn có thể hiểu như sau: Khi bật chế độ đồng bộ dữ liệu tức là
                bạn đã cho phép NOLIMIT lưu trữ dữ liệu của bạn trên hệ thống đám
                mây. Các dữ liệu này đều được mã hoá bởi mật khẩu bạn đã nhập,
                và chỉ có bạn mới có thể giải mã. Chúng tôi cam kết tuyệt đối
                không lưu trữ mật khẩu mã hoá và tuyệt nhiên không thể giải mã
                dữ liệu của bạn.
              </div>
              <div data-v-29f9b493="">
                Trong bất kỳ trường hợp nào, nếu bạn quên mật khẩu mã hoá thì sẽ
                không thể giải mã các dữ liệu này. Chúng tôi cũng không có cách
                nào để hỗ trợ khôi phục dữ liệu của bạn. Chúng tôi cam kết không
                bán, trao đổi, hoặc sử dụng các hình thức thương mại khác với
                những thông tin cá nhân của bạn hay dữ liệu được lưu trữ.
              </div>
              <div data-v-29f9b493="">
                Ngoài ra, bạn hoàn toàn có thể tự xóa, chỉnh sửa dữ liệu đã lưu
                và yêu cầu xóa tài khoản của mình trên hệ thống của chúng tôi.
              </div>
            </div>
          </div>
          <div data-v-29f9b493="" class="s.bottom_border _text_8pcyr_12">
            <div data-v-29f9b493="" class="_title_8pcyr_12">
              5. Xóa thông tin thu thập
            </div>
            <div data-v-29f9b493="" class="_desc_8pcyr_17">
              <div data-v-29f9b493="">
                Bạn hoàn toàn có thể tự xóa dữ liệu được lưu trữ trên các nền
                tảng của chúng tôi.
              </div>
            </div>
          </div>
          <div data-v-29f9b493="" class="_text_8pcyr_12">
            <div data-v-29f9b493="" class="_title_8pcyr_12">6. Lưu ý</div>
            <div data-v-29f9b493="" class="_desc_8pcyr_17">
              <div data-v-29f9b493="">
                Chúng tôi có thể cần tiết lộ thông tin cá nhân trong các trường
                hợp đặc biệt liên quan đến pháp luật hoặc khi hành động của bạn
                vi phạm chính sách.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        data-v-c41066c5=""
        data-v-2bbd8d1d=""
        class="footer"
        style="background: rgb(255, 255, 255)"
      >
        <div data-v-c41066c5="" class="top">
          <img
            data-v-c41066c5=""
            src="./../../../../public/frontend/media/logos/logo3.png"
            alt=""
            style="width: 70px"
          />
          <div data-v-c41066c5="" class="image">
            <img data-v-c41066c5="" src="./images/footer/footer-in.svg" /><img
              data-v-c41066c5=""
              src="./images/footer/footer-skype.svg"
            /><img
              data-v-c41066c5=""
              src="./images/footer/footer-youtube.svg"
            /><img data-v-c41066c5="" src="./images/footer/footer-facebook.svg" />
          </div>
        </div>
        <div data-v-c41066c5="" class="mid">
          
          <div data-v-c41066c5="" class="break"></div>
          <div data-v-c41066c5="" class="item">
            <div data-v-c41066c5="" class="title">Chính sách</div>
            
            <div data-v-c41066c5="" class="desc">
              <router-link
                data-v-c41066c5=""
                target="_blank"
               to="/Policy"
                >Chính sách bảo mật</router-link
              >
            </div>
            <div data-v-c41066c5="" class="desc">
              <router-link data-v-c41066c5="" target="_blank" to="/TemsAndPrivacy"
                >Điều khoản dịch vụ</router-link
              >
            </div>
            <div data-v-c41066c5="" class="desc">
              <router-link
                data-v-c41066c5=""
                target="_blank"
                to="/Disclaimer"
                >Miễn trừ trách nhiệm</router-link
              >
            </div>
           
          </div>
          <div data-v-c41066c5="" class="break"></div>
         
          <div data-v-c41066c5="" class="break"></div>
        </div>
        <div data-v-c41066c5="" class="protect">
            <div data-v-c41066c5="" class="protect-company">
              © Công ty TNHH Truyền Thông NOLIMIT
            </div>
            <div data-v-c41066c5="" class="protect-lang">
             
              <div data-v-c41066c5="" class="lang selected">Tiếng Việt</div>
              <div data-v-c41066c5="" class="lang">English</div>
            </div>
          </div>
          <div data-v-c41066c5="" class="info">
            <div data-v-c41066c5="" class="info-address">
                Số 19, ngách 256/15 đường Xuân Đỉnh, Phường Xuân Đỉnh, Quận Bắc Từ Liêm,
              <br data-v-c41066c5="" />
                Thành phố Hà Nội, Việt Nam
            </div>
            <div data-v-c41066c5="" class="info-contact">
              <div data-v-c41066c5="">Email: trungntm03535@gmail.com</div>
              <div data-v-c41066c5="">Hotline:  079 2116 879</div>
            </div>
            <div data-v-c41066c5="" class="info-license">
              <div data-v-c41066c5="">Số Giấy CNĐKDN: 0109995071</div>
              
            </div>
          </div>
      </div>
      <div
        data-v-ffddaff7=""
        class="fallback flex-column"
        style="
          display: none;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        "
      >
        <!---->
      </div>
    </div>
    <a
      style="display: none"
      href="//www.dmca.com/Protection/Status.aspx?ID=84e3c14f-61e8-4750-b3a5-d7ae91028878"
      title="DMCA.com Protection Status"
      class="dmca-badge"
    >
    </a>
  </div>
</template>

<style scoped>
@import "./../../../../public/frontend/css/policy.css";
._title_8pcyr_12{
    font-weight: bold;
    text-align: left;
}
body{font-family: Avenir, Helvetica, Arial, sans-serif !important;
    -webkit-font-smoothing: antialiased;
}
</style>

<script>
export default {
  name: "PolicyNolimit",
};
</script>   