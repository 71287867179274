<template>
    <!--begin::Content wrapper-->
    <div class="d-flex flex-column flex-column-fluid">
      <!--begin::Toolbar-->
      <div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6" style="padding-top: 0px !important;padding-bottom: 10px !important;">
        <!--begin::Toolbar container-->
        <div
          id="kt_app_toolbar_container"
          class="app-container d-flex flex-stack"
        >
          <!--begin::Page title-->
          <div
            class="page-title d-flex flex-column justify-content-center flex-wrap me-3"
          >
            <!--begin::Title-->
            <h1
              class="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0"
            >
            {{ this.$NOLIMITResource[this.dataUser.language].TopupDetail.Title }}
            </h1>
            <!--end::Title-->
          </div>
          <!--end::Page title-->
        </div>
        <!--end::Toolbar container-->
      </div>
      <!--end::Toolbar-->
      <!--begin::Content-->
      <div id="kt_app_content" class="app-content flex-column-fluid">
        <!--begin::Content container-->
        <div id="kt_app_content_container" class="app-container">
          <!--begin::Card-->
          <div class="card">
            
            <!--begin::Card body-->
            <div class="card-body pt-0" style="margin-top: 20px;">
              <!--begin::Table-->
              <table
                class="table align-middle table-row-dashed fs-6 gy-5"
                id="kt_customers_table"
              >
                <thead>
                  <tr
                    class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0"
                  >
                    <th class="min-w-125px" style="width:1100px">Message</th>
                    <th class="min-w-125px" style="width:1100px"> {{ this.$NOLIMITResource[this.dataUser.language].TopupDetail.Time }}</th>
                  </tr>
                </thead>
                <div class="loading-1" v-if="onLoading" >
                  <span class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </span>
                </div>
                <div style="margin-top: 20px;width: 100%;" v-if="!this.onLoading && this.hasData == false"> {{ this.$NOLIMITResource[this.dataUser.language].TopUpAccount.Nodata }}</div> 
                <tbody v-if="!this.onLoading && this.hasData == true" class="fw-semibold text-gray-600">
                  <tr v-for="topUpOrderHistory in topUpOrderHistories" :key="topUpOrderHistory.topUpOrderHistoryId">
                    <td>
                      <a class="text-gray-600 text-hover-primary mb-1">{{
                        topUpOrderHistory.message
                      }}</a>
                    </td>
                    <td>{{ formatDateTime(topUpOrderHistory.createdDate) }}</td>
                  </tr>
                </tbody>
              </table>
              <div class="row">
                <div
                  class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"
                >
                  <div class="dataTables_length" id="kt_customers_table_length">
                    <label>
                      <v-select v-model="this.pageSize" id="styleSelect" :options="numberPerPage" label="value" ></v-select>
                    </label>
                  </div>
                </div>
                <div
                  class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end"
                >
                  <div
                    class="dataTables_paginate paging_simple_numbers"
                    id="kt_customers_table_paginate"
                  >
                    <ul v-if="this.totalPage >= 4" class="pagination">
                      <li
                        class="paginate_button page-item previous"
                        :class="{ disabled: this.pageNumber == 1 }"
                        id="kt_customers_table_previous"
                      >
                        <a
                          @click="prevPage"
                                                  aria-controls="kt_customers_table"
                          data-dt-idx="0"
                          tabindex="0"
                          class="page-link"
                          ><i class="previous"></i
                        ></a>
                      </li>
                      <li
                        v-for="index in 4"
                        :key="index"
                        class="paginate_button page-item"
                        :class="{ active: pageNumber == this.firstPage + index }"
                      >
                        <a
                          @click="selectPage(this.firstPage + index)"
                                                  aria-controls="kt_customers_table"
                          data-dt-idx="1"
                          tabindex="0"
                          class="page-link"
                          >{{ this.firstPage + index }}</a
                        >
                      </li>
                      <li
                        class="paginate_button page-item next"
                        :class="{ disabled: this.pageNumber == this.totalPage }"
                        id="kt_customers_table_next"
                      >
                        <a
                          @click="nextPage"
                                                  aria-controls="kt_customers_table"
                          data-dt-idx="5"
                          tabindex="0"
                          class="page-link"
                          ><i class="next"></i
                        ></a>
                      </li>
                    </ul>
                    <ul v-if="this.totalPage < 4" class="pagination">
                      <li
                        class="paginate_button page-item previous"
                        :class="{ disabled: this.pageNumber == 1 }"
                        id="kt_customers_table_previous"
                      >
                        <a
                          @click="prevPage"
                                                  aria-controls="kt_customers_table"
                          data-dt-idx="0"
                          tabindex="0"
                          class="page-link"
                          ><i class="previous"></i
                        ></a>
                      </li>
                      <li
                        v-for="index in totalPage"
                        :key="index"
                        class="paginate_button page-item"
                        :class="{ active: pageNumber == this.firstPage + index }"
                      >
                        <a
                          @click="selectPage(this.firstPage + index)"
                                                  aria-controls="kt_customers_table"
                          data-dt-idx="1"
                          tabindex="0"
                          class="page-link"
                          >{{ this.firstPage + index }}</a
                        >
                      </li>
                      <li
                        class="paginate_button page-item next"
                        :class="{ disabled: this.pageNumber == this.totalPage }"
                        id="kt_customers_table_next"
                      >
                        <a
                          @click="nextPage"
                                                  aria-controls="kt_customers_table"
                          data-dt-idx="5"
                          tabindex="0"
                          class="page-link"
                          ><i class="next"></i
                        ></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <!--end::Table-->
            </div>
            <!--end::Card body-->
          </div>
          <!--end::Card-->
          <!--begin::Modals-->
          <!--begin::Modal - User - Update-->
          <div
            class="modal fade show"
            :class="{ block: isShowModalUpdateAds }"
            id="kt_modal_add_customer"
            tabindex="-1"
            aria-hidden="true"
          >
            <!--begin::Modal dialog-->
            <div class="modal-dialog modal-dialog-centered mw-650px">
              <!--begin::Modal content-->
              <div class="modal-content">
                <!--begin::Form-->
                <form
                  class="form"
                  id="kt_modal_add_customer_form"
                  action=""
                  @submit.prevent="addTopUpOrder"
                >
                  <!--begin::Modal header-->
                  <div class="modal-header" id="kt_modal_add_customer_header">
                    <!--begin::Modal title-->
                    <h2 class="fw-bold">TopUp Order</h2>
                    <!--end::Modal title-->
                    <!--begin::Close-->
                    <div
                      @click="closeModalUpdateAds"
                      id="kt_modal_add_customer_close"
                      class="btn btn-icon btn-sm btn-active-icon-primary"
                    >
                      <i class="ki-duotone ki-cross fs-1">
                        <span class="path1"></span>
                        <span class="path2"></span>
                      </i>
                    </div>
                    <!--end::Close-->
                  </div>
                  <!--end::Modal header-->
                  <!--begin::Modal body-->
                  <div class="modal-body py-10 px-lg-17">
                    <!--begin::Scroll-->
                    <div
                      class="scroll-y me-n7 pe-7"
                      id="kt_modal_add_customer_scroll"
                      data-kt-scroll="true"
                      data-kt-scroll-activate="{default: false, lg: true}"
                      data-kt-scroll-max-height="auto"
                      data-kt-scroll-dependencies="#kt_modal_add_customer_header"
                      data-kt-scroll-wrappers="#kt_modal_add_customer_scroll"
                      data-kt-scroll-offset="300px"
                    >
                      <!--begin::Input group-->
                      <div class="d-flex flex-column mb-7 fv-row">
                        <!--begin::Label-->
                        <label
                          class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                        >
                          <span>Số tiền thêm vào tài khoản topUpOrders</span>
                          <span
                            class="ms-1"
                            data-bs-toggle="tooltip"
                            title="Số tiền thêm vào tài khoản topUpOrders"
                          >
                            <i
                              class="ki-duotone ki-information-5 text-gray-500 fs-6"
                            >
                              <span class="path1"></span>
                              <span class="path2"></span>
                              <span class="path3"></span>
                            </i>
                          </span>
                        </label>
                        <!--end::Label-->
                        <input
                          type="text"
                          class="form-control form-control-solid"
                          placeholder=""
                          name="card_name"
                          v-model="topUpOrder.amount"
                        />
                      </div>
                      <!--end::Input group-->
                      <!--begin::Input group-->
                      <div class="d-flex flex-column mb-7 fv-row">
                        <!--begin::Label-->
                        <label
                          class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                        >
                          <span>Phí topup</span>
                          <span
                            class="ms-1"
                            data-bs-toggle="tooltip"
                            title="Phí topup là 3%"
                          >
                            <i
                              class="ki-duotone ki-information-5 text-gray-500 fs-6"
                            >
                              <span class="path1"></span>
                              <span class="path2"></span>
                              <span class="path3"></span>
                            </i>
                          </span>
                        </label>
                        <!--end::Label-->
                        <input
                          type="text"
                          class="form-control form-control-solid"
                          placeholder=""
                          name="card_name"
                          v-model="topUpOrder.fee"
                          readonly
                        />
                      </div>
                      <!--end::Input group-->
                     <!--begin::Input group-->
                     <div class="d-flex flex-column mb-7 fv-row">
                        <!--begin::Label-->
                        <label
                          class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                        >
                          <span>Tổng tiền</span>
                          <span
                            class="ms-1"
                            data-bs-toggle="tooltip"
                            title="Tổng tiền sau khi thêm phí topup"
                          >
                            <i
                              class="ki-duotone ki-information-5 text-gray-500 fs-6"
                            >
                              <span class="path1"></span>
                              <span class="path2"></span>
                              <span class="path3"></span>
                            </i>
                          </span>
                        </label>
                        <!--end::Label-->
                        <input
                          readonly
                          type="text"
                          class="form-control form-control-solid"
                          placeholder=""
                          name="card_name"
                          v-model="topUpOrder.amountDisplay"
                        />
                      </div>
                      <!--end::Input group-->
                    </div>
                    <!--end::Scroll-->
                  </div>
                  <!--end::Modal body-->
                  <!--begin::Modal footer-->
                  <div class="modal-footer flex-center">
                    <!--begin::Button-->
                    <button
                      @click="closeModalUpdateAds"
                      type="reset"
                      id="kt_modal_add_customer_cancel"
                      class="btn btn-light me-3"
                    >
                      Hủy bỏ
                    </button>
                    <!--end::Button-->
                    <!--begin::Button-->
                    <button
                      type="submit"
                      id="kt_modal_add_customer_submit"
                      class="btn btn-primary"
                    >
                      <span class="indicator-label">Thêm</span>
                      <span class="indicator-progress"
                        >Please wait...
                        <span
                          class="spinner-border spinner-border-sm align-middle ms-2"
                        ></span
                      ></span>
                    </button>
                    <!--end::Button-->
                  </div>
                  <!--end::Modal footer-->
                </form>
                <!--end::Form-->
              </div>
            </div>
            
          </div>
          <!--end::Modal - User - Update-->
          <!--begin::Modal - add topUpOrders-->
          <div
            class="modal fade show"
            :class="{ block: isShowModalAddAds }"
            id="kt_modal_new_card"
            role="dialog"
            tabindex="-1"
            aria-modal="true"
          >
            <!--begin::Modal dialog-->
            <div class="modal-dialog modal-dialog-centered mw-650px">
              <!--begin::Modal content-->
              <div class="modal-content">
                <!--begin::Modal header-->
                <div class="modal-header">
                  <!--begin::Modal title-->
                  <h2>Thêm tài khoản topUpOrders</h2>
  
                  <div
                    @click="closeModalAddAds"
                    class="btn btn-sm btn-icon btn-active-color-primary"
                    data-bs-dismiss="modal"
                  >
                    <i class="ki-duotone ki-cross fs-1">
                      <span class="path1"></span>
                      <span class="path2"></span>
                    </i>
                  </div>
                </div>
                <!--end::Modal header-->
                <!--begin::Modal body-->
                <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
                  <!--begin::Form-->
                  <form
                    id="kt_modal_new_card_form"
                    class="form"
                    action=""
                    @submit.prevent="addAds"
                  >
                    <!--begin::Input group-->
                    <div class="d-flex flex-column mb-7 fv-row">
                      <!--begin::Label-->
                      <label
                        class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                      >
                        <span>FacebookAdsId</span>
                        <span
                          class="ms-1"
                          data-bs-toggle="tooltip"
                          title="FacebookId của tài khoản topUpOrders"
                        >
                          <i
                            class="ki-duotone ki-information-5 text-gray-500 fs-6"
                          >
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                          </i>
                        </span>
                      </label>
                      <!--end::Label-->
                      <input
                        type="text"
                        class="form-control form-control-solid"
                        placeholder=""
                        name="card_name"
                        v-model="topUpOrdersAdd.facebookAdsId"
                      />
                    </div>
                    <!--end::Input group-->
                    <!--begin::Input group-->
                    <div class="d-flex flex-column mb-7 fv-row">
                      <!--begin::Label-->
                      <label
                        class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                      >
                        <span>Tên tài khoản topUpOrders</span>
                        <span
                          class="ms-1"
                          data-bs-toggle="tooltip"
                          title="Tên của tài khoản topUpOrders"
                        >
                          <i
                            class="ki-duotone ki-information-5 text-gray-500 fs-6"
                          >
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                          </i>
                        </span>
                      </label>
                      <!--end::Label-->
                      <input
                        type="text"
                        class="form-control form-control-solid"
                        placeholder=""
                        name="card_name"
                        v-model="topUpOrdersAdd.name"
                      />
                    </div>
                    <!--end::Input group-->
                    <!--begin::Input group-->
                    <div class="d-flex flex-column mb-7 fv-row">
                      <!--begin::Label-->
                      <label
                        class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                      >
                        <span>Ghi chú</span>
                        <span
                          class="ms-1"
                          data-bs-toggle="tooltip"
                          title="Ghi chú tài khoản topUpOrders"
                        >
                          <i
                            class="ki-duotone ki-information-5 text-gray-500 fs-6"
                          >
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                          </i>
                        </span>
                      </label>
                      <!--end::Label-->
                      <input
                        type="text"
                        class="form-control form-control-solid"
                        placeholder=""
                        name="card_name"
                        v-model="topUpOrdersAdd.notes"
                      />
                    </div>
                    <!--end::Input group-->
                    
                    <!--begin::Actions-->
                    <div class="text-center pt-15">
                      <button
                        @click="closeModalAddAds"
                        type="reset"
                        id="kt_modal_new_card_cancel"
                        class="btn btn-light me-3"
                      >
                        Hủy bỏ
                      </button>
                      <button
                        type="submit"
                        id="kt_modal_new_card_submit"
                        class="btn btn-primary"
                      >
                        <span
                          class="indicator-label"
                          >Thêm</span
                        >
                        <span class="indicator-progress"
                          >Please wait...
                          <span
                            class="spinner-border spinner-border-sm align-middle ms-2"
                          ></span
                        ></span>
                      </button>
                    </div>
                    <!--end::Actions-->
                  </form>
                  <!--end::Form-->
                </div>
                <!--end::Modal body-->
              </div>
              <!--end::Modal content-->
            </div>
            <!--end::Modal dialog-->
          </div>
          <!--end::Modal - change money-->
          <!--begin::Modal - Adjust Balance-->
          <div
            class="modal fade"
            id="kt_customers_export_modal"
            tabindex="-1"
            aria-hidden="true"
          >
            <!--begin::Modal dialog-->
            <div class="modal-dialog modal-dialog-centered mw-650px">
              <!--begin::Modal content-->
              <div class="modal-content">
                <!--begin::Modal header-->
                <div class="modal-header">
                  <!--begin::Modal title-->
                  <h2 class="fw-bold">Export Customers</h2>
                  <!--end::Modal title-->
                  <!--begin::Close-->
                  <div
                    id="kt_customers_export_close"
                    class="btn btn-icon btn-sm btn-active-icon-primary"
                  >
                    <i class="ki-duotone ki-cross fs-1">
                      <span class="path1"></span>
                      <span class="path2"></span>
                    </i>
                  </div>
                  <!--end::Close-->
                </div>
                <!--end::Modal header-->
                <!--begin::Modal body-->
                <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
                  <!--begin::Form-->
                  <form id="kt_customers_export_form" class="form" action="#">
                    <!--begin::Input group-->
                    <div class="fv-row mb-10">
                      <!--begin::Label-->
                      <label class="fs-5 fw-semibold form-label mb-5"
                        >Select Export Format:</label
                      >
                      <!--end::Label-->
                      <!--begin::Input-->
                      <select
                        data-control="select2"
                        data-placeholder="Select a format"
                        data-hide-search="true"
                        name="format"
                        class="form-select form-select-solid"
                      >
                        <option value="excell">Excel</option>
                        <option value="pdf">PDF</option>
                        <option value="cvs">CVS</option>
                        <option value="zip">ZIP</option>
                      </select>
                      <!--end::Input-->
                    </div>
                    <!--end::Input group-->
                    <!--begin::Input group-->
                    <div class="fv-row mb-10">
                      <!--begin::Label-->
                      <label class="fs-5 fw-semibold form-label mb-5"
                        >Select Date Range:</label
                      >
                      <!--end::Label-->
                      <!--begin::Input-->
                      <input
                        class="form-control form-control-solid"
                        placeholder="Pick a date"
                        name="date"
                      />
                      <!--end::Input-->
                    </div>
                    <!--end::Input group-->
                    <!--begin::Row-->
                    <div class="row fv-row mb-15">
                      <!--begin::Label-->
                      <label class="fs-5 fw-semibold form-label mb-5"
                        >Payment Type:</label
                      >
                      <!--end::Label-->
                      <!--begin::Radio group-->
                      <div class="d-flex flex-column">
                        <!--begin::Radio button-->
                        <label
                          class="form-check form-check-custom form-check-sm form-check-solid mb-3"
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="1"
                            checked="checked"
                            name="payment_type"
                          />
                          <span class="form-check-label text-gray-600 fw-semibold"
                            >All</span
                          >
                        </label>
                        <!--end::Radio button-->
                        <!--begin::Radio button-->
                        <label
                          class="form-check form-check-custom form-check-sm form-check-solid mb-3"
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="2"
                            checked="checked"
                            name="payment_type"
                          />
                          <span class="form-check-label text-gray-600 fw-semibold"
                            >Visa</span
                          >
                        </label>
                        <!--end::Radio button-->
                        <!--begin::Radio button-->
                        <label
                          class="form-check form-check-custom form-check-sm form-check-solid mb-3"
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="3"
                            name="payment_type"
                          />
                          <span class="form-check-label text-gray-600 fw-semibold"
                            >Mastercard</span
                          >
                        </label>
                        <!--end::Radio button-->
                        <!--begin::Radio button-->
                        <label
                          class="form-check form-check-custom form-check-sm form-check-solid"
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="4"
                            name="payment_type"
                          />
                          <span class="form-check-label text-gray-600 fw-semibold"
                            >American Express</span
                          >
                        </label>
                        <!--end::Radio button-->
                      </div>
                      <!--end::Input group-->
                    </div>
                    <!--end::Row-->
                    <!--begin::Actions-->
                    <div class="text-center">
                      <button
                        type="reset"
                        id="kt_customers_export_cancel"
                        class="btn btn-light me-3"
                      >
                        Discard
                      </button>
                      <button
                        type="submit"
                        id="kt_customers_export_submit"
                        class="btn btn-primary"
                      >
                        <span class="indicator-label">Submit</span>
                        <span class="indicator-progress"
                          >Please wait...
                          <span
                            class="spinner-border spinner-border-sm align-middle ms-2"
                          ></span
                        ></span>
                      </button>
                    </div>
                    <!--end::Actions-->
                  </form>
                  <!--end::Form-->
                </div>
                <!--end::Modal body-->
              </div>
              <!--end::Modal content-->
            </div>
            <!--end::Modal dialog-->
          </div>
          <!--end::Modal - New Card-->
          <!--end::Modals-->
        </div>
        <!--end::Content container-->
      </div>
      <!--end::Content-->
    </div>
  </template>
  <style scoped>


  .active__update__combobox {
    z-index: 555 !important;
  }
  .menu {
    left: -20px;
    top: 53px;
    position: relative;
  }
  td{
    text-align: start;
  }
  .menu-sub-dropdown {
    position: absolute;
  }
  .block {
    display: block;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .loading-1 {
    height: 500px;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 50px;
  }
  </style>
  <script>
    import { mapGetters } from "vuex";
  import Swal from "sweetalert2";
  export default {
    name: "TopUpListDetail",
    data() {
      return {
        dataUser:{language:"VN"},
        numberPerPage:[10,25,50,100],
        topUpOrder:{},
        idUser:0,
        onLoading: true,
        topUpOrderHistories: [],
        onShowMenu: false,
        currenOnshowId: 0,
        topUpOrdersCurrent: {},
        topUpOrdersAdd:{},
        isShowModalAddAds: false,
        currentBalanceUser: 0,
        currentTotalMoneyUser: 0,
        isShowModalUpdateAds: false,
        changeMoney: 0,
        formMode: "",
        pageSize: 10,
        pageNumber: 1,
        totalPage: 0,
        firstPage: 0,
        inputValue: "",
        hasData:true,
      };
    },
    props: ["idTopUpOrder"],
    watch: {
      pageSize() {
      this.reDisplay();
    },
        'topUpOrder.amount': function(newAmount) {
        const amountNumber = parseFloat(newAmount);
        // Tính toán fee dựa trên 3% của newAmount
        this.topUpOrder.fee = (newAmount * 0.03).toFixed(2); // Làm tròn đến 2 chữ số thập phân
        this.topUpOrder.amountDisplay = (amountNumber + parseFloat(this.topUpOrder.fee)).toString() // Làm tròn đến 2 chữ số thập phân
        // Nếu bạn muốn fee là số nguyên, bạn có thể sử dụng:
        // this.topUpOrder.fee = Math.round(newAmount * 0.03);
        },

    },
    computed:{
      ...mapGetters("listModule", [
      "formatCurrency",
      "formatDateTime",
    ]),
    },
    created() {
        this.$apiService
            .getAllTopUpOrderHistory(this.pageNumber, this.pageSize,this.idTopUpOrder)
            .then((res) => {
            setTimeout(() => {
                this.onLoading = false;
            }, 1000);
            this.topUpOrderHistories = res.data.data;
            if(res.data.data.length == 0){
                this.hasData = false;
              }
            this.totalPage = res.data.pageData.pageCount;
            })
            .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
            });
    this.$apiService
        .getTopUpOrderById(this.idTopUpOrder)
        .then((res) => {
        setTimeout(() => {
            this.onLoading = false;
        }, 1000);
        this.topUpOrder = res.data.data;
        })
        .catch((error) => {
        console.error("Lỗi khi lấy dữ liệu:", error);
        });
        this.$apiService
        .getUserProfile().then((response) => {
          if(response.data.data.language == "VN" || response.data.data.language == "EN"){
          this.dataUser = response.data.data;
          }else{
              this.dataUser = response.data.data;
              this.dataUser.language = "VN";
          }
        });
    },
  
    methods: {
      openModalAddAds() {
        this.isShowModalAddAds = true;
      },
      closeModalAddAds() {
        this.isShowModalAddAds = false;
        this.onShowMenu = false;
      },
  
      openModalTopUpAds(id) {
        
        this.isShowModalUpdateAds = true;
        this.$apiService
          .getAdsById(id)
          .then((res) => {
            this.topUpOrdersCurrent = res.data.data;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
      },
      closeModalUpdateAds() {
        this.isShowModalUpdateAds = false;
        this.onShowMenu = false;
      },
      addTopUpOrder() {
        this.isShowModalUpdateAds = false;
        this.topUpOrder.topUpOrdersId = this.currenOnshowId;
        this.$apiService.addTopUpOrder(this.topUpOrder)
          .then(() => {
            this.onShowMenu = false;
            this.onEnterSearch();
            Swal.fire({
              text: "Thêm TopUp order thành công!",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              heightAuto: false,
              customClass: {
                confirmButton: "btn fw-semobold btn-light-primary",
              },
            });
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
      },
      toggleMenuItem(id) {
        this.onShowMenu = !this.onShowMenu;
        this.currenOnshowId = id;
      },
      addAds() {
        this.isShowModalAddAds = false;
        this.$apiService
          .addAds(this.topUpOrdersAdd)
          .then(() => {
            this.onShowMenu = false;
            this.onEnterSearch();
            Swal.fire({
              text: "Thêm tài khoản topUpOrders thành công!",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              heightAuto: false,
              customClass: {
                confirmButton: "btn fw-semobold btn-light-primary",
              },
            });
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
      },
      selectPage(index) {
        this.pageNumber = index;
        if (this.totalPage >= 4) {
          if (index == this.totalPage) {
            this.firstPage = index - 4;
          } else if (index >= 3) {
            this.firstPage = index - 3;
          } else {
            this.firstPage = 0;
          }
        }
        this.onEnterSearch();
      },
      reDisplay() {
        this.hasData = true;
        this.$apiService
            .getAllTopUpOrderHistory(this.pageNumber, this.pageSize,this.idTopUpOrder)
            .then((res) => {
              this.onLoading = true;
            setTimeout(() => {
                this.onLoading = false;
            }, 1000);
            this.topUpOrderHistories = res.data.data;
            if(res.data.data.length == 0){
                this.hasData = false;
              }
            this.totalPage = res.data.pageData.pageCount;
            })
            .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
            });
        this.$apiService
            .getTopUpOrderById(this.idTopUpOrder)
            .then((res) => {
            setTimeout(() => {
                this.onLoading = false;
            }, 1000);
            this.topUpOrder = res.data.data;
            })
            .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
            });
          },
      /**
       * Chọn next trang tiếp theo trong mục phân trang
       * Author: ndanh (20/09/2023)
       */
      nextPage() {
        if (this.pageNumber != this.totalPage) {
          this.pageNumber += 1;
          if (this.totalPage >= 4) {
            if (this.pageNumber == this.totalPage) {
              this.firstPage = this.pageNumber - 4;
            } else if (this.pageNumber >= 3) {
              this.firstPage = this.pageNumber - 3;
            } else {
              this.firstPage = 0;
            }
          }
        }
        this.onEnterSearch();
      },
      /**
       * Chọn prev trang trước đó trong mục phân trang
       * Author: ndanh (20/09/2023)
       */
      prevPage() {
        if (this.pageNumber != 1) {
          this.pageNumber -= 1;
          if (this.totalPage >= 4) {
            if (this.pageNumber == this.totalPage) {
              this.firstPage = this.pageNumber - 4;
            } else if (this.pageNumber >= 3) {
              this.firstPage = this.pageNumber - 3;
            } else {
              this.firstPage = 0;
            }
          }
        }
        this.onEnterSearch();
      },
      onEnterSearch() {
        this.hasData = true;
        if (this.inputValue == "") {
          this.reDisplay();
        } else {
          this.$apiService
            .getAllAdsSearchByUser(
              this.pageNumber,
              this.pageSize,
              this.idUser,
              this.inputValue
            )
            .then((res) => {
              this.onLoading = true;
              setTimeout(() => {
                this.onLoading = false;
              }, 1000);
              this.topUpOrderHistories = res.data.data;
              if(res.data.data.length == 0){
                this.hasData = false;
              }
              this.totalPage = res.data.pageData.pageCount;
            })
            .catch((error) => {
              console.error("Lỗi khi lấy dữ liệu:", error);
            });
        }
      },
      choosePageSize(event) {
        this.pageSize = event.target.value;
        this.reDisplay();
      },


    },
  };
  </script>
  