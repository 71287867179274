<template>
  <div v-if="this.isAdmin == false"> {{ this.$NOLIMITResource[this.dataUser.language].PermissionPage }}</div> 
    <!--begin::Content wrapper-->
    <div v-else class="d-flex flex-column flex-column-fluid">
      <!--begin::Toolbar-->
      <div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6" style="padding-top: 0px !important;padding-bottom: 10px !important;">
        <!--begin::Toolbar container-->
        <div
          id="kt_app_toolbar_container"
          class="app-container d-flex flex-stack"
        >
          <!--begin::Page title-->
          <div
            class="page-title d-flex flex-column justify-content-center flex-wrap me-3"
          >
            <!--begin::Title-->
            <h1
              class="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0"
            >
            {{ this.$NOLIMITResource[this.dataUser.language].AdsHistory.TitleAll }}
            </h1>
            <!--end::Title-->
          </div>
          <!--end::Page title-->
        </div>
        <!--end::Toolbar container-->
      </div>
      <!--end::Toolbar-->
      <!--begin::Content-->
      <div id="kt_app_content" class="app-content flex-column-fluid">
        <!--begin::Content container-->
        <div id="kt_app_content_container" class="app-container">
          <!--begin::Card-->
          <div class="card">
            <!--begin::Card header-->
          <div class="card-header border-0 pt-6" style="align-items: center;padding-top: 0px !important;">
            <!--begin::Card title-->
            <div class="card-title">
            <!--begin::Search-->
            <div class="d-flex align-items-center position-relative my-1">
                <i class="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                  <span @click="onEnterSearchFirst" class="path1" style="cursor:pointer;z-index: 2;"></span>
                  <span class="path2"></span>
                </i>
                <input
                  v-on:keyup.enter="onEnterSearchFirst"
                  v-model="inputValue"
                  type="text"
                  class="form-control form-control-solid w-350px ps-12"
                  :placeholder="this.$NOLIMITResource[this.dataUser.language].AdsHistory.TextSearch"
                />
              </div>
              <!--end::Search-->
            </div>
            <!--end::Card title-->
          </div>
          <!--end::Card header-->
            <!--begin::Card body-->
            <div class="card-body pt-0" style="margin-top: 20px;">
              <!--begin::Table-->
              <table
                class="table align-middle table-row-dashed fs-6 gy-5"
                id="kt_customers_table"
              >
                <thead>
                  <tr
                    class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0"
                  >
                    <!-- <th class="min-w-125px">idAds</th>
                    <th class="min-w-125px">Mã giao tài khoản</th>
                    <th class="min-w-125px">Mã trả tài khoản</th>
                    <th class="min-w-125px">Ngày giao tài khoản</th>
                    <th class="min-w-125px">Ngày trả tài khoản</th>
                    <th class="min-w-125px">Message</th> -->
                    <template v-for="(column, index) in columns" :key="index" >
                      <th class="min-w-125px" :class="{
                        sorting_desc: this.sortingNumber == index,
                        sorting_asc: this.sortingAsc == index,
                      }" :style="{ 'width': column.width + 'px !important'}">
                          <div @mousedown="startResize($event, index)"><span
                            @click="
                              onSorting(index, column.property, 'changeSort')
                            "
                            >{{ column.label }}</span
                          ></div>
                      </th>
                  </template>
                  </tr>

                  
                </thead>
                <div class="loading-1" v-if="onLoading" >
                  <span class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </span>
                </div>
                <div style="margin-top: 20px;width: 100%;" v-if="!this.onLoading && this.hasData == false"> {{ this.$NOLIMITResource[this.dataUser.language].AdsHistory.EmpryData }}</div> 
                <tbody v-if="!this.onLoading && this.hasData == true" class="fw-semibold text-gray-600">
                  <tr v-for="AdsHistoryHistory in adsHistoryHistories" :key="AdsHistoryHistory.AdsHistoryHistoryId">
                    <td>
                      <a class="text-gray-600 text-hover-primary mb-1">{{
                        AdsHistoryHistory.idAds
                      }}</a>
                    </td>
                    <td>{{ AdsHistoryHistory.facebookAdsId }}</td>
                    <td>{{ AdsHistoryHistory.deliveryCode }}</td>
                    <td>{{ AdsHistoryHistory.returnCode }}</td>
                    <td>{{ formatDateTime(AdsHistoryHistory.deliveryDate) }}</td>
                    <td>{{ formatDateTime(AdsHistoryHistory.returnDate) }}</td>
                    <td v-if="this.dataUser.language == 'VN' || AdsHistoryHistory.messageEn == null" style="width:100%;">
                            {{ AdsHistoryHistory.message }}
                        </td>
                        <td v-else style="width:100%;">
                            {{ AdsHistoryHistory.messageEn }}
                        </td>
                  </tr>
                </tbody>
              </table>
              <div class="row">
                <div
                  class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"
                >
                  <div class="dataTables_length" id="kt_customers_table_length">
                    <label>
                      <v-select :calculate-position="withPopper" v-model="this.pageSize" id="styleSelect" :options="numberPerPage" label="value" ></v-select>
                    </label>
                  </div>
                </div>
                <div
                  class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end"
                >
                  <div
                    class="dataTables_paginate paging_simple_numbers"
                    id="kt_customers_table_paginate"
                  >
                    <ul v-if="this.totalPage >= 4" class="pagination">
                      <li
                        class="paginate_button page-item previous"
                        :class="{ disabled: this.pageNumber == 1 }"
                        id="kt_customers_table_previous"
                      >
                        <a
                          @click="prevPage"
                                                  aria-controls="kt_customers_table"
                          data-dt-idx="0"
                          tabindex="0"
                          class="page-link"
                          ><i class="previous"></i
                        ></a>
                      </li>
                      <li
                        v-for="index in 4"
                        :key="index"
                        class="paginate_button page-item"
                        :class="{ active: pageNumber == this.firstPage + index }"
                      >
                        <a
                          @click="selectPage(this.firstPage + index)"
                                                  aria-controls="kt_customers_table"
                          data-dt-idx="1"
                          tabindex="0"
                          class="page-link"
                          >{{ this.firstPage + index }}</a
                        >
                      </li>
                      <li
                        class="paginate_button page-item next"
                        :class="{ disabled: this.pageNumber == this.totalPage }"
                        id="kt_customers_table_next"
                      >
                        <a
                          @click="nextPage"
                                                  aria-controls="kt_customers_table"
                          data-dt-idx="5"
                          tabindex="0"
                          class="page-link"
                          ><i class="next"></i
                        ></a>
                      </li>
                    </ul>
                    <ul v-if="this.totalPage < 4" class="pagination">
                      <li
                        class="paginate_button page-item previous"
                        :class="{ disabled: this.pageNumber == 1 }"
                        id="kt_customers_table_previous"
                      >
                        <a
                          @click="prevPage"
                                                  aria-controls="kt_customers_table"
                          data-dt-idx="0"
                          tabindex="0"
                          class="page-link"
                          ><i class="previous"></i
                        ></a>
                      </li>
                      <li
                        v-for="index in totalPage"
                        :key="index"
                        class="paginate_button page-item"
                        :class="{ active: pageNumber == this.firstPage + index }"
                      >
                        <a
                          @click="selectPage(this.firstPage + index)"
                                                  aria-controls="kt_customers_table"
                          data-dt-idx="1"
                          tabindex="0"
                          class="page-link"
                          >{{ this.firstPage + index }}</a
                        >
                      </li>
                      <li
                        class="paginate_button page-item next"
                        :class="{ disabled: this.pageNumber == this.totalPage }"
                        id="kt_customers_table_next"
                      >
                        <a
                          @click="nextPage"
                                                  aria-controls="kt_customers_table"
                          data-dt-idx="5"
                          tabindex="0"
                          class="page-link"
                          ><i class="next"></i
                        ></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <!--end::Table-->
            </div>
            <!--end::Card body-->
          </div>
          <!--end::Card-->
          <!--begin::Modals-->
          
          
          
          <!--end::Modal - New Card-->
          <!--end::Modals-->
        </div>
        <!--end::Content container-->
      </div>
      <!--end::Content-->
    </div>
  </template>
  <style scoped>


  .active__update__combobox {
    z-index: 555 !important;
  }
  .menu {
    left: -20px;
    top: 53px;
    position: relative;
  }
  td{
    text-align: start;
  }
  .menu-sub-dropdown {
    position: absolute;
  }
  .block {
    display: block;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .loading-1 {
    height: 500px;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 50px;
  }
  </style>
  <script>
  import router from '@/router/router.js';
    import {mapGetters} from "vuex";
  export default {
    name: "AllAdsHistory",
    data() {
      return {
        dataUser:{language:"VN"},
        currentIndex: null,
        currentProperty: null,
        sortingAsc: null,
        sortingNumber: null,
        numberPerPage:[10,25,50,100],
        adsHistory:{},
        idUser:0,
        onLoading: true,
        adsHistoryHistories: [],
        currenOnshowId: 0,
        adsHistorysCurrent: {},
        adsHistorysAdd:{},
        currentBalanceUser: 0,
        currentTotalMoneyUser: 0,
        changeMoney: 0,
        formMode: "",
        pageSize: 10,
        pageNumber: 1,
        totalPage: 0,
        firstPage: 0,
        isAdmin:true,
        inputValue: "",
        hasData:true,
        columns: [
            { label: 'Id', width: 350, property: "IdAds" },
            { label: 'Id Facebook Ads', width: 350, property: "FacebookAdsId" },
            { label: this.$NOLIMITResource["VN"].AdsHistory.DeliveryCode, width: 350, property: "DeliveryCode" },
            { label:  this.$NOLIMITResource["VN"].AdsHistory.ReturnCode, width: 350, property: "ReturnCode" },
            { label: this.$NOLIMITResource["VN"].AdsHistory.DeliveryDate, width: 350, property: "DeliveryDate" },
            { label: this.$NOLIMITResource["VN"].AdsHistory.ReturnDate, width: 350, property: "ReturnDate" },
            { label: 'Message', width: 350, property: "Message"},
        ],
      };
    },
    watch: {
      dataUser:{
      handler: function() {
        this.columns = [
            { label: 'Id', width: 350, property: "IdAds" },
            { label: 'Id Facebook Ads', width: 350, property: "FacebookAdsId" },
            { label: this.$NOLIMITResource[this.dataUser.language].AdsHistory.DeliveryCode, width: 350, property: "DeliveryCode" },
            { label:  this.$NOLIMITResource[this.dataUser.language].AdsHistory.ReturnCode, width: 350, property: "ReturnCode" },
            { label: this.$NOLIMITResource[this.dataUser.language].AdsHistory.DeliveryDate, width: 350, property: "DeliveryDate" },
            { label: this.$NOLIMITResource[this.dataUser.language].AdsHistory.ReturnDate, width: 350, property: "ReturnDate" },
            { label: 'Message', width: 350, property: "Message"},
        ]
      }
    },
    pageSize() {
      this.reDisplay();
    },
  },
    created() {
      this.$apiService
      .getUserProfile()
      .then((response) => {
        if(response.data.data.isAdmin == false){
                this.isAdmin=false
                router.push({ name: 'ErrorNotFound' });
            }
      })
        this.$apiService
        .getAllAdsHistory(this.pageNumber, this.pageSize)
        .then((res) => {
        setTimeout(() => {
            this.onLoading = false;
        }, 1000);
        this.adsHistoryHistories = res.data.data;
        if(res.data.data.length == 0){
            this.hasData = false;
          }
        this.totalPage = res.data.pageData.pageCount;
        })
        .catch((error) => {
        console.error("Lỗi khi lấy dữ liệu:", error);
        });
        this.$apiService
        .getUserProfile().then((response) => {
          if(response.data.data.language == "VN" || response.data.data.language == "EN"){
              this.dataUser = response.data.data;
          }else{
              this.dataUser = response.data.data;
              this.dataUser.language = "VN";
          }
        });

    },
    computed:{
      ...mapGetters("listModule", [
      "formatCurrency",
      "formatDateTime",
    ]),
    },
    methods: {
      withPopper(dropdownList) {
      dropdownList.classList.add("style-chooser");
    },
      selectPage(index) {
        this.pageNumber = index;
        if (this.totalPage >= 4) {
          if (index == this.totalPage) {
            this.firstPage = index - 4;
          } else if (index >= 3) {
            this.firstPage = index - 3;
          } else {
            this.firstPage = 0;
          }
        }
        if (this.sortingNumber != null || this.sortingAsc != null) {
          this.onSorting(this.currentIndex, this.currentProperty, "selectPage");
        } else {
          this.onEnterSearch();
        }
      },
      reDisplay() {
        this.hasData = true;
        this.$apiService
        .getAllAdsHistory(this.pageNumber, this.pageSize)
        .then((res) => {
          this.onLoading=true
        setTimeout(() => {
            this.onLoading = false;
        }, 1000);
        this.adsHistoryHistories = res.data.data;
        if(res.data.data.length == 0){
            this.hasData = false;
          }
        this.totalPage = res.data.pageData.pageCount;
        })
        .catch((error) => {
        console.error("Lỗi khi lấy dữ liệu:", error);
        });
      },
      /**
       * Chọn next trang tiếp theo trong mục phân trang
       * Author: ndanh (20/09/2023)
       */
      nextPage() {
        if (this.pageNumber != this.totalPage) {
          this.pageNumber += 1;
          if (this.totalPage >= 4) {
            if (this.pageNumber == this.totalPage) {
              this.firstPage = this.pageNumber - 4;
            } else if (this.pageNumber >= 3) {
              this.firstPage = this.pageNumber - 3;
            } else {
              this.firstPage = 0;
            }
          }
        }
        if (this.sortingNumber != null || this.sortingAsc != null) {
          this.onSorting(this.currentIndex, this.currentProperty, "selectPage");
        } else {
          this.onEnterSearch();
        }
      },
      /**
       * Chọn prev trang trước đó trong mục phân trang
       * Author: ndanh (20/09/2023)
       */
      prevPage() {
        if (this.pageNumber != 1) {
          this.pageNumber -= 1;
          if (this.totalPage >= 4) {
            if (this.pageNumber == this.totalPage) {
              this.firstPage = this.pageNumber - 4;
            } else if (this.pageNumber >= 3) {
              this.firstPage = this.pageNumber - 3;
            } else {
              this.firstPage = 0;
            }
          }
        }
        if (this.sortingNumber != null || this.sortingAsc != null) {
          this.onSorting(this.currentIndex, this.currentProperty, "selectPage");
        } else {
          this.onEnterSearch();
        }
      },
    
      choosePageSize(event) {
        this.pageSize = event.target.value;
        this.reDisplay();
      },

    startResize(event, index) {
      if (event.offsetX > event.target.offsetWidth - 10) {
        this.isResizing = true;
        this.initialX = event.clientX;
        this.initialWidth = this.columns[index].width;
        this.resizingCol = index;

        document.addEventListener("mousemove", this.handleMouseMove);
        document.addEventListener("mouseup", this.handleMouseUp);
      }
    },
    handleMouseMove(event) {
      if (this.isResizing && this.resizingCol !== null) {
        const delta = event.clientX - this.initialX;
        const newWidth = Math.max(50, this.initialWidth + delta);
        this.columns[this.resizingCol] = {
          ...this.columns[this.resizingCol],
          width: newWidth,
        };
      }
    },
    handleMouseUp() {
      this.isResizing = false;
      this.initialX = 0;
      this.initialWidth = 0;
      this.resizingCol = null;

      document.removeEventListener("mousemove", this.handleMouseMove);
      document.removeEventListener("mouseup", this.handleMouseUp);
    },
    onSorting(index, property, mode) {
      this.currentIndex = index;
      this.currentProperty = property;
      if (this.sortingNumber == index && mode == "changeSort") {
        this.sortingNumber = null;
        this.sortingAsc = index;
        this.$apiService
          .getAdsHistorySortAscByAdmin(
            this.pageNumber,
            this.pageSize,
            property
          )
          .then((res) => {
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.adsHistoryHistories = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
      } else if (this.sortingNumber == index && mode == "selectPage") {
        this.sortingNumber = null;
        this.sortingAsc = null;
        this.sortingNumber = index;
        this.$apiService
          .getAdsHistorySortDescByAdmin(
            this.pageNumber,
            this.pageSize,
            property
          )
          .then((res) => {
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.adsHistoryHistories = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
      } else if (this.sortingAsc == index && mode == "changeSort") {
        this.sortingAsc = null;
        this.sortingNumber = index;
        this.$apiService
          .getAdsHistorySortDescByAdmin(
            this.pageNumber,
            this.pageSize,
            property
          )
          .then((res) => {
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.adsHistoryHistories = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
      } else if (this.sortingAsc == index && mode == "selectPage") {
        this.sortingAsc = null;
        this.sortingNumber = null;
        this.sortingAsc = index;
        this.$apiService
          .getAdsHistorySortAscByAdmin(
            this.pageNumber,
            this.pageSize,
            property
          )
          .then((res) => {
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.adsHistoryHistories = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
      } else {
        this.sortingNumber = null;
        this.sortingAsc = null;
        this.sortingNumber = index;
        this.$apiService
          .getAdsHistorySortDescByAdmin(
            this.pageNumber,
            this.pageSize,
            property
          )
          .then((res) => {
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.adsHistoryHistories = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
      }
    },

    onEnterSearch() {
      this.hasData = true;
      if (this.inputValue == "") {
        this.reDisplay();
      } else {
        this.$apiService
          .getAllAdsHistorySearch(
            this.pageNumber,
            this.pageSize,
            this.inputValue
          )
          .then((res) => {
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.adsHistoryHistories = res.data.data;
            if(res.data.data.length == 0){
                this.hasData = false;
              }
            this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
      }
    },
    onEnterSearchFirst() {
      this.hasData = true;
      if (this.inputValue == "") {
        this.reDisplay();
      } else {
        this.pageNumber = 1;
        this.firstPage = 0;
        this.$apiService
          .getAllAdsHistorySearch(
            this.pageNumber,
            this.pageSize,
            this.inputValue
          )
          .then((res) => {
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.adsHistoryHistories = res.data.data;
            if(res.data.data.length == 0){
                this.hasData = false;
              }
            this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });   
      }
    },

    },
  };
  </script>
  