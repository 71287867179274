<template>
  <div class="detail-deposit">
    <div class="col-xl-4" style="margin-left: 40px; margin-right: 20px">
      <!--begin::List Widget 1-->
      <div class="card card-xl-stretch mb-xl-8">
        <div
          class="d-print-none border border-dashed border-gray-300 card-rounded h-lg-100 min-w-md-350px p-9 bg-lighten" style="    min-height: 510px;"
        >
          <!--begin::Labels-->
          <div class="mb-8 flex">
            <span
              v-if="this.depositHistory.depositStatus == 'Đã thanh toán'"
              class="badge badge-light-success me-2"
              > {{ this.$NOLIMITResource[this.dataUser.language].DetailDeposit.StatusPaid }}</span
            >
            <span v-else class="badge badge-light-warning"
              >{{ this.$NOLIMITResource[this.dataUser.language].DetailDeposit.AwaitingPayment }}</span
            >
          </div>
          <!--end::Labels-->
          <!--begin::Title-->
          <h6 class="mb-8 fw-bolder text-gray-600 text-hover-primary flex">
            {{ this.$NOLIMITResource[this.dataUser.language].DetailDeposit.DetailedInvoice }}
          </h6>
          <!--end::Title-->
          <!--begin::Item-->
          <div class="mb-6 flex">
            <div class="fw-semibold text-gray-600 fs-7">{{ this.$NOLIMITResource[this.dataUser.language].DetailDeposit.Bank }}:</div>
            <div v-if="this.depositHistory.depositStatus == 'Chưa thanh toán'" class="fw-bold text-gray-800 fs-6">{{ this.accountCurrent.bank }}</div>
            <div v-else class="fw-bold text-gray-800 fs-6">{{ this.depositHistory.nameBank }}</div>
          </div>
          <!--end::Item-->
          <!--begin::Item-->
          <div class="mb-6 flex">
            <div class="fw-semibold text-gray-600 fs-7">{{ this.$NOLIMITResource[this.dataUser.language].DetailDeposit.BankNumber }}:</div>
            <div v-if="this.depositHistory.depositStatus == 'Chưa thanh toán'" class="fw-bold text-gray-800 fs-6">{{ this.accountCurrent.value }}</div>
            <div v-else class="fw-bold text-gray-800 fs-6">{{ this.depositHistory.numberBank }}</div>
          </div>
          <!--end::Item-->

          <!--begin::Item-->  
          <div class="mb-6 flex">
            <div class="fw-semibold text-gray-600 fs-7">{{ this.$NOLIMITResource[this.dataUser.language].DetailDeposit.BankOwner }}</div>
            <div v-if="this.depositHistory.depositStatus == 'Chưa thanh toán'" class="fw-bold fs-6 text-gray-800">{{ this.accountCurrent.name }}</div>
            <div v-else class="fw-bold text-gray-800 fs-6">{{ this.depositHistory.ownerBank }}</div>
          </div>
          <!--end::Item-->
          <!--begin::Item-->
          <div class="mb-6 flex">
            <div class="fw-semibold text-gray-600 fs-7">
              {{ this.$NOLIMITResource[this.dataUser.language].DetailDeposit.AmountToBePaid }}:
            </div>
            <div class="fw-bold text-gray-800 fs-6">
              {{ formatCurrency(this.depositHistory.depositAmount) }}
            </div>
          </div>

          <!--end::Item-->
          <!--begin::Item-->
          <div class="mb-6 flex">
            <div class="fw-semibold text-gray-600 fs-7">
              {{ this.$NOLIMITResource[this.dataUser.language].DetailDeposit.DepositCode }}:
            </div>
            <div class="fw-bold text-gray-800 fs-6">{{this.depositHistory.depositHistoryCode}}</div>
          </div>
          <!--end::Item-->
          <!--begin::Item-->
          <div v-if="this.depositHistory.depositStatus == 'Chưa thanh toán'" class="mb-0 flex">
            <div class="fw-semibold text-gray-600 fs-7">{{ this.$NOLIMITResource[this.dataUser.language].DetailDeposit.PaymentTerm }}:</div>
            <div class="fw-bold fs-6 text-gray-800 d-flex align-items-center">
              
              <span class="fs-7 text-danger d-flex align-items-center">
                <span class="bullet bullet-dot bg-danger mx-2"></span>{{ this.$NOLIMITResource[this.dataUser.language].DetailDeposit.PaymentTimeRemaining }} {{this.remainingTime}}</span
              >
            </div>
          </div>
          <!--end::Item-->
        </div>
      </div>
      <!--end::List Widget 1-->
    </div>
    
    <div class="card col-xl-7" style="height: 510px">
      <div v-if="this.depositHistory.depositStatus == 'Chưa thanh toán'" class="block-choose-bank">
          <h2>{{ this.$NOLIMITResource[this.dataUser.language].DetailDeposit.SelectPaymentAccount }}</h2>

          <button @click="chooseBank(account.bank,account.name,account.value)" v-for="(account, index) in accountSetting" :key="index" class="btn btn-primary"> {{ account.bank }} </button>

        </div>
      <!--begin::Card body-->
      <div class="card-body">
        
        <!--begin::Heading-->
        <div class="card-px text-center pt-15 pb-15">
          <!--begin::Title-->
          <h2 class="fs-2x fw-bold mb-0">{{ this.$NOLIMITResource[this.dataUser.language].DetailDeposit.ScanQRCodeToPay }}</h2>
          <!--end::Title-->
          <!--begin::Description-->
          <p class="text-gray-500 fs-4 fw-semibold py-7">
            {{ this.$NOLIMITResource[this.dataUser.language].DetailDeposit.Use }} <b> App Internet Banking</b> {{ this.$NOLIMITResource[this.dataUser.language].DetailDeposit.Qr }}
          </p>
          <!--end::Description-->
          <img
            class="img-qr"
            :src="this.generateImageUrl"
            width="100%"
          />
        </div>
        <!--end::Heading-->
       
      </div>
      <!--end::Card body-->
    </div>
  </div>
</template>
<style scoped>
.d-flex {
}
.img-qr {
  width: 300px;
}
.py-7 {
  padding: 0px !important;
  font-size: 13px !important;
}
.card-px {
  padding-top: 10px !important;
}
.flex {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}
.block-choose-bank button{
  margin-right: 10px;
}
.block-choose-bank{
  margin-top: 20px;
}
.block-choose-bank h2 {
  color: #34bbc3;
}
@media only screen and (max-width: 5000px) {
  .detail-deposit{
    display: flex;
  }
}
@media only screen and (max-width: 600px) {
  .detail-deposit{
    display: block;
  }
}
</style>
<script>
  import {mapActions } from "vuex";
export default {

  name: "DetailDeposit",
  data() {
    return {
      dataUser:{language:"VN"},
      depositHistory: {},
      remainingTime: "",
      accountSetting:[],
      accountCurrent: {
      
      },
    };
  },
  computed: {
    generateImageUrl: function () {
      if(this.depositHistory.depositStatus == 'Chưa thanh toán'){
        return `https://api.vietqr.io/${this.accountCurrent.bank}/${this.accountCurrent.value}/${this.depositHistory.depositAmount}/${this.depositHistory.depositHistoryCode}/vietqr_net_2.jpg?accountName=${this.accountCurrent.name}`;
      }
      return `https://api.vietqr.io/${this.depositHistory.nameBank}/${this.depositHistory.numberBank}/${this.depositHistory.depositAmount}/${this.depositHistory.depositHistoryCode}/vietqr_net_2.jpg?accountName=${this.depositHistory.ownerBank}`;
    },
  },
  props: ["idDeposit"],
  async created() {
   await  this.$apiService
      .getDepositHistoryById(this.idDeposit)
      .then((res) => {
        this.depositHistory = res.data.data;

        // Gọi hàm cập nhật thời gian mỗi giây
        this.updateRemainingTime();
        setInterval(this.updateRemainingTime, 1000); // Cập nhật mỗi giây
      })
      .catch((error) => {
        console.error("Lỗi khi lấy dữ liệu:", error);
      });
    this.$apiService
      .getAllSettingAccountNoPaging()
      .then((res) => {
        this.accountSetting = res.data.data;
        for(let i = 0;i<res.data.data.length;i++){
          if(res.data.data[i].status == true && res.data.data[i].bankPrioritize){
              this.accountCurrent = {...res.data.data[i]};
              break;
          }
        }
        
      })
      .catch((error) => {
        console.error("Lỗi khi lấy dữ liệu:", error);
    });  

    this.$apiService.getUserProfile().then((response) => {
      if(response.data.data.language == "VN" || response.data.data.language == "EN"){
          this.dataUser = response.data.data;
      }else{
          this.dataUser = response.data.data;
          this.dataUser.language = "VN";
      }
    })

    
      this.interval = setInterval(this.callApis, 2000);
    
    
  },
  methods: {
    ...mapActions("listModule", ["swalSuccess","swalError","swalWarning"]),
    callApis(){
      if(this.depositHistory.depositStatus == 'Chưa thanh toán'){
        this.$apiService
        .getDepositHistoryById(this.idDeposit)
        .then((res) => {
          this.depositHistory = res.data.data;
          if(this.depositHistory.depositStatus == "Đã thanh toán"){
            this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].DetailDeposit.PaymentSuccessful)
          }
          // Gọi hàm cập nhật thời gian mỗi giây
          this.updateRemainingTime();
          setInterval(this.updateRemainingTime, 1000); // Cập nhật mỗi giây
        })
        .catch((error) => {
          console.error("Lỗi khi lấy dữ liệu:", error);
        });
    }
    },
    tinhThoiGianThanhToan(thoiGianTaoHoaDon) {
    const thoiGianTaoHoaDonObj = new Date(thoiGianTaoHoaDon);
    const thoiGianThanhToanObj = new Date(thoiGianTaoHoaDonObj.getTime() + 24 * 60 * 60 * 1000);
    const thoiGianHienTaiObj = new Date();
    const khoangCachThoiGian = thoiGianThanhToanObj - thoiGianHienTaiObj;

    // Chia khoảng cách thời gian cho 1000 để đổi sang giây
    const giay = Math.floor(khoangCachThoiGian / 1000);

    // Tính số giờ, phút và giây
    const gio = Math.floor(giay / 3600);
    const phut = Math.floor((giay % 3600) / 60);
    const giayConLai = giay % 60;

    // Định dạng kết quả
    const thoiGianThanhToanFormatted = `${gio.toString().padStart(2, '0')}:${phut.toString().padStart(2, '0')}:${giayConLai.toString().padStart(2, '0')}`;

    return thoiGianThanhToanFormatted;
  },
    updateRemainingTime() {
      this.remainingTime = this.tinhThoiGianThanhToan(this.depositHistory.depositCreateDate);
    },
    formatCurrency(number) {
      const formattedCurrency = number.toLocaleString('vi-VN', {
          style: 'currency',
          currency: 'VND'
      });
      return formattedCurrency;
      },
      chooseBank(bank,name,numberBank){
        this.accountCurrent.bank = bank;
        this.accountCurrent.name = name;
        this.accountCurrent.value = numberBank;
      }  
  },
};
</script>
