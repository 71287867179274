<template>
    
    <RouterView></RouterView>
</template>
<style scope>
</style>
<script>

export default {
    name: "TheContainer",

}

</script>