import {createRouter,createWebHistory} from 'vue-router'
import HomePage from './../views/apps/home/Home.vue'
import SignIn from './../views/apps/authetication/SignIn.vue'
import SignUp from './../views/apps/authetication/SignUp.vue'
import ListDeposit from './../views/container/deposit/ListDeposit.vue'
import DepositListAll from './../views/container/adminList/DepositListAll.vue'
import DetailDeposit from './../views/container/deposit/DetailDeposit.vue'
import DepositRecharge from './../views/container/deposit/Recharge.vue'
import UserList from './../views/container/adminList/UserList.vue'
import ListAds from './../views/container/adminList/ListAds.vue'
import AdsAccount from './../views/container/account/AdsAccount.vue'
import TopUpAccount from './../views/container/account/TopUpAccount.vue'
import TopUpListDetail from './../views/container/topUp/TopUpListDetail.vue'
import TopUpList from './../views/container/adminList/TopUpList.vue'
import UserProfile from './../views/container/account/ProfileSetting.vue'
import TopUpListByAds from './../views/container/topUp/TopUpListByAds.vue'
import SettingGeneral from './../views/container/adminList/Setting.vue'
import BankFeeCard from './../views/container/adminList/BankFeeCard.vue'
import AdsHistory from './../views/container/ads/AdsHistory.vue'
import ErrorNotFound from './../views/general/error404.vue'
import apiService from './../service/apiService.js';
// import DashBoard from './../views/container/dashboard/DashBoard.vue'
import ListNotification from './../views/container/notification/ListNotification.vue'
import AllAdsHistory from './../views/container/adminList/AllAdsHistory.vue'
import BalanceHistory from './../views/container/balanceHistory/BalanceHistory'
import AllBalanceHistory from './../views/container/adminList/AllBalanceHistory'
import DashBoardV2 from './../views/container/dashboard/DashBoardV2.vue'
import PolicyNolimit from './../views/apps/tems/policy.vue'
import TemsAndPrivacyNolimit from './../views/apps/tems/TemsAndPrivacy.vue'
import DisclaimerNolimit from './../views/apps/tems/Disclaimer.vue'
import ListRefund from './../views/container/refund/ListRefund.vue'
import AllRefund from './../views/container/adminList/AllRefund.vue'
import ListAdsByUser from './../views/container/adminList/ListAdsByUser.vue'
import BillLog from './../views/container/adminList/BillLog.vue'
import ListUserByAccountant from './../views/container/account/ListUserByAccountant.vue'
import CardManager from './../views/container/adminList/CardManager.vue'
//ĐỊnh nghĩa các route
import axios from 'axios';
import Swal from "sweetalert2";

const routers = [
    {
        path: "/SignIn",name:"SignIn", component:SignIn,meta: { requiresAuth: false } 
    },
    {
        path: "/SignUp",name:"SignUp", component:SignUp,meta: { requiresAuth: false } 
    },
    {
      path: "/Policy",name:"PolicyNolimit", component:PolicyNolimit,meta: { requiresAuth: false } 
  },
  {
    path: "/TemsAndPrivacy",name:"TemsAndPrivacyNolimit", component:TemsAndPrivacyNolimit,meta: { requiresAuth: false } 
},
{
  path: "/Disclaimer",name:"DisclaimerNolimit", component:DisclaimerNolimit,meta: { requiresAuth: false } 
},
    {
      path: "/error404",name:"ErrorNotFound", component:ErrorNotFound,meta: { requiresAuth: true } 
  },
    {
        path: "/",name:"Home", component:HomePage,meta: { requiresAuth: true }, 
        children: [{
            path: "/listUser",name:"UserList", component:UserList,meta: { requiresAuth: true } },
            // {
            //   path: "/",name:"DashBoard", component:DashBoard,meta: { requiresAuth: true } },
              {
                path: "/",name:"DashBoardV2", component:DashBoardV2,meta: { requiresAuth: true } },
            {
              path: "/listAds",name:"ListAds", component:ListAds,meta: { requiresAuth: true }, props: route => ({ idAds: route.query.idAds }) },
              {
                path: "/setting",name:"SettingGeneral", component:SettingGeneral,meta: { requiresAuth: true } },
                {
                  path: "/bankFeeCard",name:"BankFeeCard", component:BankFeeCard,meta: { requiresAuth: true } },
              {
                path: "/adsAccount",name:"AdsAccount", component:AdsAccount,meta: { requiresAuth: true } },
                {
                  path: "/adsHistory",name:"AdsHistory", component:AdsHistory,meta: { requiresAuth: true },
                  props: route => ({ idAds: route.query.idAds }) },
                {
                  path: "/topUpAccount",name:"TopUpAccount", component:TopUpAccount,meta: { requiresAuth: true } },
                  {
                    path: "/topUpListDetail",name:"TopUpListDetail", component:TopUpListDetail,meta: { requiresAuth: true },
                    props: route => ({ idTopUpOrder: route.query.idTopUpOrder })
                  },
                  {
                    path: "/topUpListByAds",name:"TopUpListByAds", component:TopUpListByAds,meta: { requiresAuth: true },
                    props: route => ({ idAds: route.query.idAds })
                  },
                  {
                    path: "/listAdsByUser",name:"ListAdsByUser", component:ListAdsByUser,meta: { requiresAuth: true },
                    props: route => ({ idUser: route.query.idUser })
                  },
                    {
                      path: "/listAllTopUp",name:"TopUpList", component:TopUpList,meta: { requiresAuth: true } },
                      {
                        path: "/billLog",name:"BillLog", component:BillLog,meta: { requiresAuth: true } },
                    {
                      path: "/allAdsHistory",name:"AllAdsHistory", component:AllAdsHistory,meta: { requiresAuth: true } },
                      {
                        path: "/balanceHistory",name:"BalanceHistory", component:BalanceHistory,meta: { requiresAuth: true } },
                        
                        {
                          path: "/allBalanceHistory",name:"AllBalanceHistory", component:AllBalanceHistory,meta: { requiresAuth: true } },
                          {
                            path: "/listRefund",name:"ListRefund", component:ListRefund,meta: { requiresAuth: true } },
                            {
                              path: "/allRefund",name:"AllRefund", component:AllRefund,meta: { requiresAuth: true } },
              {
                path: "/userProfile",name:"UserProfile", component:UserProfile,meta: { requiresAuth: true } },
                {
                  path: "/cardManager",name:"CardManager", component:CardManager,meta: { requiresAuth: true } },
                {
                  path: "/listUserByAccountant",name:"ListUserByAccountant", component:ListUserByAccountant,meta: { requiresAuth: true } },
                {
                  path: "/deposit",name:"ListDeposit", component:ListDeposit,meta: { requiresAuth: true },
                },    
                {
                  path: "/depositAll",name:"DepositListAll", component:DepositListAll,meta: { requiresAuth: true },
                }, 
                {
                  path: "/listNotification",name:"ListNotification", component:ListNotification, meta: { requiresAuth: true },
                }, 
                {
                  path: "/depositDetail",name:"DetailDeposit", component:DetailDeposit,meta: { requiresAuth: true },
                  props: route => ({ idDeposit: route.query.idDeposit })
                },    
                {
                  path: "/depositRecharge",name:"DepositRecharge", component:DepositRecharge,meta: { requiresAuth: true } }, 
        ]
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes:routers
})

router.beforeEach(async (to, from, next) => {
    let token = window.localStorage.getItem('id-token');
    
    // Kiểm tra xem trang hiện tại yêu cầu xác thực hay không
    if (to.matched.some(record => record.meta.requiresAuth)) {

      // Trang yêu cầu xác thực
      if (token) {
        // Decode token để lấy thông tin về thời gian hết hạn
      let decodedToken = decodeJwt(token);
      let expirationTime = decodedToken.exp * 1000; 
        // Token tồn tại, cho phép truy cập trang
        if (expirationTime > Date.now()) {

          const tokenIsValid = await checkToken();
          if(tokenIsValid){
              window.localStorage.removeItem('id-token')
              if (from.name !== 'SignIn') {
                next({ name: 'SignIn' });
                Swal.fire({
                  text: "Tài khoản đã bị đăng nhập ở nơi khác", 
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "Try again!",
                  heightAuto: false,
                  customClass: {
                  confirmButton: "btn fw-semobold btn-light-danger",
                  },
                })
              } else {
                next(false); // Tránh việc chuyển hướng lại lần nữa
              }
            }else{
              await apiService.verifyToken().then((res)=>window.localStorage.setItem('id-token',res.data.data.token)).catch((res)=>{

                Swal.fire({
                  text: res.response.data.message, 
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "Thử lại!",
                  heightAuto: false,
                  customClass: {
                  confirmButton: "btn fw-semobold btn-light-danger",
                  },
                })
                // Token đã hết hạn, chuyển hướng đến trang SignIn
                window.localStorage.removeItem('id-token')
                if (from.name !== 'SignIn') {
                  next({ name: 'SignIn' });
                } else {
                  next(false); // Tránh việc chuyển hướng lại lần nữa
                }
               })
              next();
            }
        }else {
          // Token đã hết hạn, chuyển hướng đến trang SignIn
          window.localStorage.removeItem('id-token')
          if (from.name !== 'SignIn') {
            next({ name: 'SignIn' });
          } else {
            next(false); // Tránh việc chuyển hướng lại lần nữa
          }
        }
        
      } else {
        // Token không tồn tại, chuyển hướng đến trang SignIn
        if (from.name !== 'SignIn') {
          next({ name: 'SignIn' });
        } else {
          next(false); // Tránh việc chuyển hướng lại lần nữa
        }
      }
    } else {
        if (token) {
            next({ name: 'Home' });
        }else{
            next();
        }
      // Trang không yêu cầu xác thực, cho phép truy cập trang
    }
  });
 
  async function checkToken() {
    try {
      // Lấy token từ local storage
      let token = window.localStorage.getItem('id-token');
  
      // Kiểm tra nếu token không tồn tại
      if (!token) {
        console.error('Token not found');
        return false;
      }
  
      // Gửi yêu cầu kiểm tra token đến server
      const response = await axios.get('https://api.nolimitadsmanager.com/api/User/UserDetails', {
        // const response = await axios.get('https://localhost:7001/api/User/UserDetails', {
        headers: { Authorization: token }
      });
  
      // Lấy token từ phản hồi của server
      const serverToken = response.data.data.token;
  
      // So sánh token từ local storage và token từ server
      if (token !== serverToken) {
        return true;
      }
  
      // Tokens khớp nhau
    } catch (error) {
      // Xử lý lỗi nếu có
      return false;
    }
  }
  function decodeJwt(token) {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  
    return JSON.parse(jsonPayload);
  }
export default router