// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyASd9y9wHd4UP5ZotLdGDe05GL0bk7dAsk",
  authDomain: "nolimitweb-4b65e.firebaseapp.com",
  projectId: "nolimitweb-4b65e",
  storageBucket: "nolimitweb-4b65e.appspot.com",
  messagingSenderId: "775009600356",
  appId: "1:775009600356:web:002edd9b37ef8f396df071",
  measurementId: "G-X1WMN52MML"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
import {getStorage} from "firebase/storage";
const storage = getStorage(app);

export {storage}