import * as signalR from '@microsoft/signalr';

const hubConnection = new signalR.HubConnectionBuilder()
    .withUrl("https://api.nolimitadsmanager.com/chatHub",{ withCredentials: true })

    .configureLogging(signalR.LogLevel.None)  // Tắt tất cả log
    .build();

export default {
    startConnection() {
        hubConnection.start()
            .then()
            .catch(err => console.error('Error while starting connection: ', err));
    },
    onReceiveMessage(callback) {
        hubConnection.on('ReceiveMessage', (user, message) => {
            callback(user, message);
        });
    },
    sendMessage(user, message) {
        hubConnection.invoke('SendMessage', user, message)
            .catch(err => console.error(err));
    }
};
