
import Swal from "sweetalert2";
  const state = {
    onShowMenuFilter:false,
    isShowModalPickRow:false,
    displayRow:{},
    checkRow:{},
    isResizing: false,
    initialX: 0,
    initialWidth: 0,
    resizingCol: null,
    onShowMenuFilterStatus: false,
    statusAds:"",
    onShowSidebarMobile:false,
    selectedOption: null,
    dropdownOptions: [],
    language:"VN",
  }
  
  const mutations = {
    setDisPlayRow(state, newData){
        state.displayRow = newData
    },
    toggleMenuFilter(state){
        state.onShowMenuFilter = !state.onShowMenuFilter
    },
    closeMenuFilter(state){
      state.onShowMenuFilter = false;
    },
    onOpenSidebarMobile(state){
      state.onShowSidebarMobile = true;
    },
    onCloseSidebarMobile(state){
      state.onShowSidebarMobile = false;
    },
    toggleModalDisplayRow(state){
        state.checkRow = state.displayRow;
        state.isShowModalPickRow = !state.isShowModalPickRow;
    },  
    toggleFilterStatus(state) {
      state.onShowMenuFilterStatus = !state.onShowMenuFilterStatus;
    },
    closeFilterStatus(state){
      state.onShowMenuFilterStatus = false;
    },
    onResetFilterStatusAds(state){
      state.statusAds = ""
    },
    updateStatusAds(state, newValue) {
      state.statusAds = newValue;
    },
    setSelectedOption(state, option) {
      state.selectedOption = option;
    },
    setDropdownOptions(state, options) {
      state.dropdownOptions = options;
    },
    setLanguage(state, newValue) {
      state.language = newValue;
    }
  };

  const actions = {
    updateLanguage({ commit }, newValue) {
      commit('setLanguage', newValue);
    },
    updateSelectedOption({ commit }, option) {
      commit('setSelectedOption', option);
    },
    updateDropdownOptions({ commit }, options) {
      commit('setDropdownOptions', options);
    },
    toggleMenuFilter({commit}){
        commit('toggleMenuFilter')
    },
    toggleModalDisplayRow({commit}){
        commit('toggleModalDisplayRow')
    },
    toggleFilterStatus({commit}){
      commit('toggleFilterStatus')
    },
    // thông báo: this.swalSuccess()
    swalSuccess(state,message){
      Swal.fire({
        text: message,
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Ok",
        heightAuto: false,
        customClass: {
          confirmButton: "btn fw-semobold btn-light-primary",
        },
      });
    },
    // error từ hệ thống: this.swalError(error.response.data.message)
    // error message: this.swalError()
    swalError(state,obj){
      if(obj.language == "EN"){
        Swal.fire({
          text: obj.message,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          heightAuto: false,
          customClass: {
            confirmButton: "btn fw-semobold btn-light-primary",
          },
        });
      }else{
        Swal.fire({
          text: obj.message,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Thử lại!",
          heightAuto: false,
          customClass: {
            confirmButton: "btn fw-semobold btn-light-primary",
          },
        });
      }
   
    },
  // cảnh báo message: this.swalWarning()
    swalWarning(state,obj){
      if(obj.language == "EN"){
        return  Swal.fire({
          title: 'Confirm',
          text: obj.message,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Confirm",
          cancelButtonText: 'Cancel',
        });
      }
      return  Swal.fire({
        title: 'Xác nhận',
        text: obj.message + ' không?',
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Xác nhận",
        cancelButtonText: 'Hủy',
      });
    },


    startResize({state,dispatch},{event, index, columns}) {
      alert(index)
      if (event.offsetX > event.target.offsetWidth - 10) {
        state.isResizing = true;
        state.initialX = event.clientX;
        state.initialWidth = columns[index].width;
        state.resizingCol = index;
        document.addEventListener("mousemove", dispatch('handleMouseMove',columns));
        document.addEventListener("mouseup", dispatch('handleMouseUp',columns));
      }
    },

    handleMouseMove(state,event,columns) {
      if (state.isResizing && state.resizingCol !== null) {
        const delta = event.clientX - state.initialX;
        const newWidth = Math.max(50, state.initialWidth + delta);
        columns[state.resizingCol] = {
          ...columns[state.resizingCol],
          width: newWidth,
        };
      }
    },


    handleMouseUp({state,dispatch}) {
      state.isResizing = false;
      state.initialX = 0;
      state.initialWidth = 0;
      state.resizingCol = null;

      document.removeEventListener("mousemove", dispatch('handleMouseMove'));
      document.removeEventListener("mouseup", dispatch('handleMouseUp'));
    },
    
    setStatusAds({ commit }, newValue) {
      commit('updateStatusAds', newValue);
    }

  }
  
  const getters = {
    language:state =>state.language,
    name: state => state.name,
    onShowMenuFilter: state => state.onShowMenuFilter,
    checkRow: state => state.checkRow,
    displayRow: state => state.displayRow,
    isShowModalPickRow: state => state.isShowModalPickRow,
    onShowMenuFilterStatus: state => state.onShowMenuFilterStatus,
    getStatusAds: state => state.statusAds,
    getOnShowSidebarMobile: state => state.onShowSidebarMobile,
    selectedOption: state => state.selectedOption,
    dropdownOptions: state => state.dropdownOptions,
    // hàm convert tiền
    formatCurrency: () => (number) => {
      if (number == null) {
        return "_";
    }

    // Kiểm tra xem có số thập phân hay không
    if (Number.isInteger(number)) {
        // Nếu là số nguyên, chỉ cần định dạng nguyên với toLocaleString
        return number.toLocaleString("en-US");
    } else {
        // Nếu có số thập phân, làm tròn đến hai chữ số sau dấu phẩy
        const roundedNumber = Number(number.toFixed(2));
        return roundedNumber.toLocaleString("en-US");
    }

    },
    // hàm convert ngày giờ
    formatDateTime: () => (dateTimeString) => {
      if (dateTimeString == null) {
        return "_";
      }
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };

      const formattedDateObject = new Date(dateTimeString);
      const formattedDate = formattedDateObject.toLocaleDateString(
        "en-GB",
        options
      );
      const time = formattedDateObject
        .toLocaleTimeString("en-GB", options)
        .split(" ")[1];
      const dateParts = formattedDate.split("/");
      const date = `${dateParts[0]}-${dateParts[1]}-${dateParts[2]}`.split(
        ", "
      )[0];
      return `${time} ${date}`;
    },
    // hàm convert sang cuối ngày
    convertToEndOfDay:()=> (date) => {
      date.setHours(23, 59, 59, 999);

      // Lấy các thành phần ngày giờ
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const seconds = date.getSeconds().toString().padStart(2, "0");
      const milliseconds = date.getMilliseconds().toString().padStart(3, "0");

      // Tạo chuỗi ISO
      const isoString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;

      return isoString;
    },

   

  };
  
  export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
  };