
import { createStore } from 'vuex'
import listModule from './modules/listModule'
import adsUser from './modules/adsUser';
const store =  createStore({
    modules:{
        listModule,
        adsUser,
    },

})

export default store;