import { createApp } from 'vue'
import route from './router/router'
import axios from 'axios';
import emitter from 'tiny-emitter/instance';
import App from './App.vue'
import apiService from './service/apiService';
import vSelect from "vue-select";
import { setupCalendar, Calendar, DatePicker } from 'v-calendar';
import 'v-calendar/style.css';
import "vue-select/dist/vue-select.css";
import NOLIMITResource from './js/NOLIMITResource';

import store from './store/index';
const app = createApp(App)
app.config.errorHandler = function (err, vm, info) {
    if (err instanceof TypeError) {
      return;
    }
    // Handle other errors or log them
    console.error('Caught an error:', err,vm, info);
  };



app.component("v-select", vSelect);
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$emitter = emitter;
app.config.globalProperties.$apiService = apiService;
app.config.globalProperties.$NOLIMITResource = NOLIMITResource;


app.use(route)

// Use plugin defaults (optional)
app.use(setupCalendar, {})
app.use(store)
// Use the components
app.component('VCalendar', Calendar)
app.component('VDatePicker', DatePicker)
app.mount('#app')
export default app;