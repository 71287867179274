<template>
  <div v-if="this.isAdmin != true && this.isSupport != true"> {{ this.$NOLIMITResource[this.dataUser.language].PermissionPage }}</div> 
  <!--begin::Content wrapper-->
  <div v-else class="d-flex flex-column flex-column-fluid">
    <!--begin::Toolbar-->
    <div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6" style="padding-top: 0px !important;padding-bottom: 10px !important;">
      <!--begin::Toolbar container-->
      <div
        id="kt_app_toolbar_container"
        class="app-container d-flex flex-stack"
      >
        <!--begin::Page title-->
        <div
          class="page-title d-flex flex-column justify-content-center flex-wrap me-3"
        >
          <!--begin::Title-->
          <h1
            class="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0"
          >
          {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.TitleAllAds }}
          </h1>
          <!--end::Title-->
        </div>
        <!--end::Page title-->
      </div>
      <!--end::Toolbar container-->
    </div>
    <!--end::Toolbar-->
    <!--begin::Content-->
    <div id="kt_app_content" class="app-content flex-column-fluid">
      <!--begin::Content container-->
      <div id="kt_app_content_container" class="app-container">
        <!--begin::Card-->
        <div class="card">
          <!--begin::Card header-->
          <div class="card-header border-0 pt-6" style="align-items: center;padding-top: 0px !important;">
            <!--begin::Card title-->
            <div class="card-title">
              <!--begin::Search-->
              <div class="d-flex align-items-center position-relative my-1">
                <i class="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                  <span @click="onEnterSearchFirst" class="path1" style="cursor:pointer;z-index: 2;"></span>
                  <span class="path2"></span>
                </i>
                <input
                  v-on:keyup.enter="onEnterSearchFirst"
                  v-model="inputValue"
                  type="text"
                  class="form-control form-control-solid w-350px ps-12"
                  :placeholder="this.$NOLIMITResource[this.dataUser.language].AdsAccount.SearhTextAllAds"
                />
              </div>
              <!--end::Search-->
            </div>
            
            <!--begin::Card toolbar-->
            <div class="card-toolbar" style="margin-left: auto;">
              <!--begin::Toolbar-->
             
              <div
                class="d-flex justify-content-end"
                data-kt-customer-table-toolbar="base"
              >
                <!--begin::Menu 1-->
                <div
                  class="menu menu-sub menu-sub-dropdown w-300px w-md-325px"
                  data-kt-menu="true"
                  id="kt-toolbar-filter"
                >
                  <!--begin::Header-->
                  <div class="px-7 py-5">
                    <div class="fs-4 text-gray-900 fw-bold">Filter Options</div>
                  </div>
                  <!--end::Header-->
                  <!--begin::Separator-->
                  <div class="separator border-gray-200"></div>
                  <!--end::Separator-->
                  <!--begin::Content-->
                  <div class="px-7 py-5">
                    <!--begin::Input group-->
                    <div class="mb-10">
                      <!--begin::Label-->
                      <label class="form-label fs-5 fw-semibold mb-3"
                        >Month:</label
                      >
                      <!--end::Label-->
                      <!--begin::Input-->
                      <select
                        class="form-select form-select-solid fw-bold"
                        data-kt-select2="true"
                        data-placeholder="Select option"
                        data-allow-clear="true"
                        data-kt-customer-table-filter="month"
                        data-dropdown-parent="#kt-toolbar-filter"
                      >
                        <option></option>
                        <option value="aug">August</option>
                        <option value="sep">September</option>
                        <option value="oct">October</option>
                        <option value="nov">November</option>
                        <option value="dec">December</option>
                      </select>
                      <!--end::Input-->
                    </div>
                    <!--end::Input group-->
                    <!--begin::Input group-->
                    <div class="mb-10">
                      <!--begin::Label-->
                      <label class="form-label fs-5 fw-semibold mb-3"
                        >Payment Type:</label
                      >
                      <!--end::Label-->
                      <!--begin::Options-->
                      <div
                        class="d-flex flex-column flex-wrap fw-semibold"
                        data-kt-customer-table-filter="payment_type"
                      >
                        <!--begin::Option-->
                        <label
                          class="form-check form-check-sm form-check-custom form-check-solid mb-3 me-5"
                        >
                          <input
                            class="form-check-input"
                            type="radio"
                            name="payment_type"
                            value="all"
                            checked="checked"
                          />
                          <span class="form-check-label text-gray-600"
                            >All</span
                          >
                        </label>
                        <!--end::Option-->
                        <!--begin::Option-->
                        <label
                          class="form-check form-check-sm form-check-custom form-check-solid mb-3 me-5"
                        >
                          <input
                            class="form-check-input"
                            type="radio"
                            name="payment_type"
                            value="visa"
                          />
                          <span class="form-check-label text-gray-600"
                            >Visa</span
                          >
                        </label>
                        <!--end::Option-->
                        <!--begin::Option-->
                        <label
                          class="form-check form-check-sm form-check-custom form-check-solid mb-3"
                        >
                          <input
                            class="form-check-input"
                            type="radio"
                            name="payment_type"
                            value="mastercard"
                          />
                          <span class="form-check-label text-gray-600"
                            >Mastercard</span
                          >
                        </label>
                        <!--end::Option-->
                        <!--begin::Option-->
                        <label
                          class="form-check form-check-sm form-check-custom form-check-solid"
                        >
                          <input
                            class="form-check-input"
                            type="radio"
                            name="payment_type"
                            value="american_express"
                          />
                          <span class="form-check-label text-gray-600"
                            >American Express</span
                          >
                        </label>
                        <!--end::Option-->
                      </div>
                      <!--end::Options-->
                    </div>
                    <!--end::Input group-->
                    <!--begin::Actions-->
                    <div class="d-flex justify-content-end">
                      <button
                        type="reset"
                        class="btn btn-light btn-active-light-primary me-2"
                        data-kt-menu-dismiss="true"
                        data-kt-customer-table-filter="reset"
                      >
                        Reset
                      </button>
                      <button
                        type="submit"
                        class="btn btn-primary"
                        data-kt-menu-dismiss="true"
                        data-kt-customer-table-filter="filter"
                      >
                        Apply
                      </button>
                    </div>
                    <!--end::Actions-->
                  </div>
                  <!--end::Content-->
                </div>
                <!--end::Menu 1-->
                
                <!--begin::Menu 2-->
                  <div class="menu menu-sub menu-sub-dropdown w-300px w-md-325px  show-dropfilter-2" :class="{show:this.onShowMenuFilterStatus == true}" data-kt-menu="true" id="kt-toolbar-filter" data-select2-id="select2-data-kt-toolbar-filter" style="">
                    <!--begin::Header-->
                    <div class="px-7 py-5">
                      <div class="fs-4 text-gray-900 fw-bold">{{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.StatusAds }}</div>
                    </div>
                    <!--end::Header-->
                    <!--begin::Separator-->
                    
                    <div class="separator border-gray-200"></div>
                    <!--end::Separator-->
                    <form  
                        action=""
                        @submit.prevent="onFilterStatusAds">
                    <!--begin::Content-->
                    <div class="px-7 py-5" data-select2-id="select2-data-128-13oj">
                      <!--begin::Input group-->
                      <div class="mb-10">
                        
                        <!--begin::Options-->
                        <div class="d-flex flex-column flex-wrap fw-semibold" data-kt-customer-table-filter="payment_type">
                          <!--begin::Option-->
                          <label class="form-check form-check-sm form-check-custom form-check-solid mb-7 me-5">
                            <input class="form-check-input" type="radio" name="payment_type" value="1" v-model="this.statusAds">
                            <div>
                              <span class="form-check-label text-gray-600">{{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.StatusWork }}</span><br>
                              <span class="form-check-label text-gray-600" style="font-size: 12px;">{{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.NumberOfAccounts }} {{ this.totalStatusAdsUsing }}</span>
                            </div>  
                          </label>
                          <!--end::Option-->
                          <!--begin::Option-->
                          <label class="form-check form-check-sm form-check-custom form-check-solid mb-7 me-5">
                            <input class="form-check-input" type="radio" name="payment_type" value="2" v-model="this.statusAds">
                            <div>
                              <span class="form-check-label text-gray-600">{{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.StatusDisable }}</span><br>
                              <span class="form-check-label text-gray-600" style="font-size: 12px;">{{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.NumberOfAccounts }} {{ this.totalStatusAdsDisable }}</span>
                            </div> 
                          </label>
                          <!--end::Option-->
                          <!--begin::Option-->
                          <label class="form-check form-check-sm form-check-custom form-check-solid mb-7 me-5">
                            <input class="form-check-input" type="radio" name="payment_type" value="3" v-model="this.statusAds">
                            <div>
                              <span class="form-check-label text-gray-600">{{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.StatusPaymentRequired }}</span><br>
                              <span class="form-check-label text-gray-600" style="font-size: 12px;">{{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.NumberOfAccounts }} {{ this.totalStatusAdsPayRequired }}</span>
                            </div> 
                          </label>
                          <!--end::Option-->
                          
                        </div>
                        <!--end::Options-->
                      </div>
                      <!--end::Input group-->
                      <!--begin::Actions-->
                      <div class="d-flex justify-content-end">
                        <button @click="onResetFilterStatusAds" type="reset" class="btn btn-light btn-active-light-primary me-2" data-kt-menu-dismiss="true" data-kt-customer-table-filter="reset">{{ this.$NOLIMITResource[this.dataUser.language].Reset }}</button>
                        <button type="submit" class="btn btn-primary" data-kt-menu-dismiss="true" data-kt-customer-table-filter="filter">{{ this.$NOLIMITResource[this.dataUser.language].Filter }}</button>
                      </div>
                      <!--end::Actions-->
                    </div>
                    <!--end::Content-->
                  </form>
                  </div>
                  <!--end::Menu 2-->

                <!--end::Filter-->  
                <div class="content__toolbar--table" >
                  <i @click="toggleModalDisplayRow" class="ki-outline ki-gear fs-2x me-3"></i>
                        <a id="selected__toolbar">{{ this.$NOLIMITResource[this.dataUser.language].Selected }} <span>{{ countSelected }}</span></a>
                        <a @click="openModalProvideAll()" id="selectedall__toolbar"> {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.ProvideAdsForManyUser }}</a>
                        <a v-if="isAdmin" @click="confirmReturnManyAds()" id="selectedall__toolbar">{{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.RecallAdsForManyUser }}</a>
                        <a v-if="isAdmin" @click="confirmUpdateAllAdsStatus()" id="selectedall__toolbar">{{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.RequestUpdateAll }}</a>
                        <a v-if="isAdmin" @click="openModalUpdateFeeTopupAll()" id="selectedall__toolbar">{{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.UpdateBatchTopupFee }}</a>
                        <a @click="exportCSV()" id="selectedall__toolbar">{{ this.$NOLIMITResource[this.dataUser.language].DownloadCSV }}</a>
                    </div>
                <!--begin::Filter-->
													<button @click="toggleMenuFilter" type="button" class="btn btn-light-primary me-3 " data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
													<i class="ki-duotone ki-filter fs-2">
														<span class="path1"></span>
														<span class="path2"></span>
													</i>{{ this.$NOLIMITResource[this.dataUser.language].FilterSearch }}</button>
													<!--begin::Menu 1-->
													<div class="menu menu-sub menu-sub-dropdown w-300px w-md-325px  show-dropfilter" :class="{show:this.onShowMenuFilter == true}" data-kt-menu="true" id="kt-toolbar-filter" data-select2-id="select2-data-kt-toolbar-filter" style="">
														<!--begin::Header-->
														<div class="px-7 py-5">
															<div class="fs-4 text-gray-900 fw-bold">{{ this.$NOLIMITResource[this.dataUser.language].FilterOptions }}</div>
														</div>
														<!--end::Header-->
														<!--begin::Separator-->
                            
														<div class="separator border-gray-200"></div>
														<!--end::Separator-->
                            <form  
                                action=""
                                @submit.prevent="onFilterSearchFirst">
														<!--begin::Content-->
														<div class="px-7 py-5" data-select2-id="select2-data-128-13oj">
                              <!--begin::Input group-->
                              <div>
                                <label class="form-label fs-5 fw-semibold mb-3">{{ this.$NOLIMITResource[this.dataUser.language].StartDate }}:</label>
                                <v-date-picker class="inline-block h-full" v-model="dateStart" :timezone="timezone2">
                                  <template v-slot="{ inputValue, togglePopover }">
                                    <div class="flex items-center">
                                      <input @click="togglePopover()" :value="inputValue" class="form-control form-control-solid flatpickr-input active" name="calendar_event_end_date" :placeholder="this.$NOLIMITResource[this.dataUser.language].StartDate" id="kt_calendar_datepicker_end_date" type="text" readonly="readonly" style="margin-bottom:20px">
                                    </div>
                                  </template>
                                </v-date-picker>
                              </div>
                              <!--end::Input group-->

                              <!--begin::Input group-->
                              <div>
                                <label class="form-label fs-5 fw-semibold mb-3">{{ this.$NOLIMITResource[this.dataUser.language].EndDate }}:</label>
                                <v-date-picker class="inline-block h-full" v-model="dateEnd" :timezone="timezone1">
                                  <template v-slot="{ inputValue, togglePopover }">
                                    <div class="flex items-center">
                                      <input @click="togglePopover()" :value="inputValue" class="form-control form-control-solid flatpickr-input active" name="calendar_event_end_date" :placeholder="this.$NOLIMITResource[this.dataUser.language].EndDate" id="kt_calendar_datepicker_end_date" type="text" readonly="readonly" style="margin-bottom:20px">
                                    </div>
                                  </template>
                                </v-date-picker>
                              </div>
                              <!--end::Input group-->

															<!--begin::Input group-->
															<div class="mb-10">
																<!--begin::Label-->
																<label class="form-label fs-5 fw-semibold mb-3">{{ this.$NOLIMITResource[this.dataUser.language].Status }}:</label>
																<!--end::Label-->
																<!--begin::Options-->
																<div class="d-flex flex-column flex-wrap fw-semibold" data-kt-customer-table-filter="payment_type">
																	<!--begin::Option-->
																	<label class="form-check form-check-sm form-check-custom form-check-solid mb-7 me-5">
																		<input class="form-check-input" type="radio" name="payment_type" value="Đang sử dụng" v-model="this.status">
                                    <div>
                                      <span class="form-check-label text-gray-600">{{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.StatusInUse }}  </span><br>
                                      <span class="form-check-label text-gray-600" style="font-size: 12px;">{{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.NumberOfAccounts }} {{ this.totalStatusUsing }}</span>
                                    </div> 

																	</label>
																	<!--end::Option-->
																	<!--begin::Option-->
																	<label class="form-check form-check-sm form-check-custom form-check-solid mb-7 me-5">
																		<input class="form-check-input" type="radio" name="payment_type" value="Đã thu hồi" v-model="this.status">
																		<div>
                                      <span class="form-check-label text-gray-600">{{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.StatusRecalled }}</span><br>
                                      <span class="form-check-label text-gray-600" style="font-size: 12px;">{{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.NumberOfAccounts }} {{ this.totalStatusReturn }}</span>
                                    </div> 
																	</label>
																	<!--end::Option-->
																	<!--begin::Option-->
																	<label class="form-check form-check-sm form-check-custom form-check-solid mb-7 me-5">
																		<input class="form-check-input" type="radio" name="payment_type" value="Chưa sử dụng" v-model="this.status">
																		<div>
                                      <span class="form-check-label text-gray-600">{{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.NotYetUsed }}</span><br>
                                      <span class="form-check-label text-gray-600" style="font-size: 12px;">{{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.NumberOfAccounts }} {{ this.totalStatusNotUse }}</span>
                                    </div> 
																	</label>
																	<!--end::Option-->
                                  <!--begin::Option-->
																	<label class="form-check form-check-sm form-check-custom form-check-solid mb-3">
																		<input class="form-check-input" type="radio" name="payment_type" value="Chờ thu hồi" v-model="this.status">
																		<div>
                                      <span class="form-check-label text-gray-600">{{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.StatusWaitingForRecall }}</span><br>
                                      <span class="form-check-label text-gray-600" style="font-size: 12px;">{{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.NumberOfAccounts }} {{ this.totalStatusWaittingReturn }}</span>
                                    </div> 
																	</label>
																	<!--end::Option-->
																</div>
																<!--end::Options-->
															</div>
															<!--end::Input group-->
															<!--begin::Actions-->
															<div class="d-flex justify-content-end">
																<button @click="onResetFilter" type="reset" class="btn btn-light btn-active-light-primary me-2" data-kt-menu-dismiss="true" data-kt-customer-table-filter="reset">{{ this.$NOLIMITResource[this.dataUser.language].Reset }}</button>
																<button type="submit" class="btn btn-primary" data-kt-menu-dismiss="true" data-kt-customer-table-filter="filter">{{ this.$NOLIMITResource[this.dataUser.language].Filter }}</button>
															</div>
															<!--end::Actions-->
														</div>
														<!--end::Content-->
                          </form>
													</div>
													<!--end::Menu 1-->
                        <!--end::Filter-->
                <!--begin::Add ads-->
                <button @click="openModalImportManyAds" type="button" class="btn btn-primary" style="margin-right: 10px;">
                  {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.AddBatchAds }}
                </button>
                <!--end::Add ads-->
                <button @click="openModalAddAds" type="button" class="btn btn-primary">
                  {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.AddAd }}
                </button>
              </div>
              <!--end::Toolbar-->
              <!--begin::Group actions-->
              <div
                class="d-flex justify-content-end align-items-center d-none"
                data-kt-customer-table-toolbar="selected"
              >
                <div class="fw-bold me-5">
                  <span
                    class="me-2"
                    data-kt-customer-table-select="selected_count"
                  ></span
                  >Selected
                </div>
                <button
                  type="button"
                  class="btn btn-danger"
                  data-kt-customer-table-select="delete_selected"
                >
                  Delete Selected
                </button>
              </div>
              <!--end::Group actions-->
            </div>
            
            <!--end::Card toolbar-->
          </div>
          <!--end::Card header-->
          <!--begin::Card body-->
          <div class="card-body pt-0" style="margin-top: 20px;">
            <!--begin::Table-->
            <table 
              class="table align-middle table-row-dashed fs-6 gy-5"
              id="kt_customers_table"
            >
              <thead>
                <tr
                  class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0"
                >
                <th class="fix-data-0">
                  <div class="form-check" >
                    <input v-model="isCheckedAll" class="form-check-input" @click="selectAllRecord" type="checkbox" :class="{active__record: onSelectAllRecord}" id="flexCheckChecked" :checked="this.selectAllRecord === true" />
                    <label for="flexCheckChecked" ></label>
                  </div>
                </th>
                <th style="cursor: pointer;" @click="toggleFilterStatus()" v-if="this.displayRow.adsStatus" class="fix-data-1"> {{ this.$NOLIMITResource[this.dataUser.language].Status }}</th>
                  <th v-if="this.displayRow.account" class="fix-data-2">{{ this.$NOLIMITResource[this.dataUser.language].Account }}</th>
                  <template v-for="(column, index) in columns" :key="index" >
                      <th v-if="shouldDisplay(index)" class="min-w-125px" :class="{
                        sorting_desc: this.sortingNumber == index,
                        sorting_asc: this.sortingAsc == index,
                      }"  :style="{ 'min-width': column.width + 'px !important'}">
                        <template v-if="shouldDisplay(index)">
                          <div @mousedown="startResize($event, index)"><span
                            @click="
                              onSorting(index, column.property, 'changeSort')
                            "
                            >{{ column.label }}</span
                          ></div>
                        </template>
                      </th>
                    </template>
                  <th class="text-end min-w-70px">{{ this.$NOLIMITResource[this.dataUser.language].Feature }}</th>

                </tr>
              </thead>
              <div class="loading-1" v-if="onLoading">
                <span class="spinner-border text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </span>
              </div>
              <div style="margin-top: 20px;" v-if="!this.onLoading && this.hasData == false"> {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.Nodata }}</div> 
              <tbody v-if="!this.onLoading && this.hasData == true" class="fw-semibold text-gray-600">
                <tr ref="source" :class="{active__record:(selectRecord && recordActiveId == ad.adsId) || onSelectAllRecord}" v-for="ad in ads" :key="ad.adsId">
                  <td  class="fix-data-0">
                        <div class="form-check">
                          <input :id="ad.adsId" type="checkbox"  :value="ad.adsId" name="check" :class="{count__record: isChecked(ad.adsId)}" class="form-check-input"  v-model="checkboxChecked" />
                          <label :for="ad.adsId"></label>
                        </div>
                    </td>
                    <td class="fix-data-1" v-if="this.displayRow.adsStatus">
                      <a class="text-gray-600 text-hover-primary mb-1">
                        <div
                        v-if="ad.adsStatus == '2'"
                          :class="`badge badge-light-danger`"
                        >
                        {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.StatusDisable }}
                        </div>
                        <div
                        v-if="ad.adsStatus == '1'"
                          :class="`badge badge-light-success`"
                        >
                        {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.StatusWork }}
                        </div>

                        <div v-if="ad.adsStatus == '3'" :class="`badge badge-light-primary`">
                          {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.StatusPaymentRequired }}
                        </div>

                        <div v-if="ad.adsStatus == '0'" :class="`badge badge-light-info`">
                          {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.StatusUpdateting }}
                        </div>
                      </a>
                    </td>
                    <td class="fix-data-2" v-if="this.displayRow.account">
                      <a class="text-gray-600 text-hover-primary mb-1">{{
                        ad.name
                      }}</a><br>
                      <a
                        class="text-gray-800 text-hover-primary mb-1"
                        >{{ ad.facebookAdsId }}</a
                      >
                      
                    </td>
                    <td >{{ ad.adsId }}</td>
                    <td v-if="this.displayRow.topupFeeUser">{{ ad.topupFee }}</td>
                    <td v-if="this.displayRow.notes">{{ ad.notes }}</td>
                 
                    <td v-if="this.displayRow.shareStatus">
                      <a class="text-gray-600 text-hover-primary mb-1">
                        <div
                        v-if="ad.shareStatus == 'Đã thu hồi'"
                          :class="`badge badge-light-danger`"
                        >
                        {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.StatusRecalled }}
                        </div>
                        <div
                        v-if="ad.shareStatus == 'Đang sử dụng'"
                          :class="`badge badge-light-success`"
                        >
                        {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.StatusInUse }}
                        </div>
                        <div v-if="ad.shareStatus == 'Chưa sử dụng'" :class="`badge badge-light-primary`">
                          {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.NotYetUsed }}
                        </div>
                        <div
                        v-if="ad.shareStatus == 'Chờ thu hồi'"
                        :class="`badge badge-light-warning`"
                      >
                      {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.StatusWaitingForRecall }}
                      </div>
                      </a>
                    </td>
                    <td v-if="this.displayRow.lastTopupDate">{{ formatDateTime(ad.lastTopupDate) }}</td>
                    <td v-if="this.displayRow.createDate">{{ formatDateTime(ad.createDate) }}</td>
                    <td v-if="this.displayRow.spendingCap">{{ formatCurrency(ad.spendingCap) }}</td>
                    <td v-if="this.displayRow.remainingAmount">{{ formatCurrency(ad.remainingAmount) }}</td>
                    <td v-if="this.displayRow.customerNow">{{ ad.customerNow }}</td>
                    <td v-if="this.displayRow.supporter">{{ ad.supporter }}</td>
                    <td v-if="this.displayRow.returnDate">{{ formatDateTime(ad.returnDate) }}</td>
                    <td v-if="this.displayRow.paymentMethod" style="position: relative;" id="payment">
                      <div v-if="ad.paymentMethod != null"> 
                        <p v-for="(payment,index) in paymentsCurrent(ad.paymentMethod)" :key="index" style="margin-bottom:2px;">
                          <img v-if="checkPaymentMastercard(payment)" src="./../../../../public/frontend/icon/payment-method/mastercard.svg" alt="Ảnh">
                          <img v-if="checkPaymentVisa(payment)" src="./../../../../public/frontend/icon/payment-method/visa.svg" alt="Ảnh">
                          <img v-if="checkPaymentCredit(payment)" src="./../../../../public/frontend/icon/payment-method/credit.svg" alt="Ảnh">
                          {{lastPayment(payment)}} 
                        </p>
                      </div>
                      <p v-if="ad.paymentMethod == 'Chưa có phương thức thanh toán' || ad.paymentMethod == null">_</p>
                    </td>          
                    <td v-if="this.displayRow.timezone">{{ ad.timezone }}</td>
                    <td v-if="this.displayRow.currency">{{ ad.currency }}</td>
                    <td v-if="this.displayRow.nationallity">{{ ad.nationallity }}</td>
                    <td v-if="this.displayRow.limit">{{ formatCurrency(ad.limit) }}</td>
                    <td v-if="this.displayRow.dailyLimit"> 
                      <span v-if="ad.dailyLimit !== -1">{{ formatCurrency(ad.dailyLimit) }}</span>
                      <span v-else>Nolimit</span>
                    </td>
                    <td v-if="this.displayRow.paymentThreshold">{{ formatCurrency(ad.paymentThreshold) }}</td>
                    <td v-if="this.displayRow.remainThreshold">{{ formatCurrency(ad.remainThreshold) }}</td>
                    <td v-if="this.displayRow.invoiceDate">{{ formatDateTime(ad.invoiceDate) }}</td>
                    <td v-if="this.displayRow.remainPaymentDate">{{ ad.remainPaymentDate }}</td>
                    <td v-if="this.displayRow.idbusinessOwner">{{ ad.idbusinessOwner }}</td>
                    <td v-if="this.displayRow.nameBusinessOwner" >{{ ad.nameBusinessOwner }}</td>
                    <td v-if="this.displayRow.idBusinessPartner">{{ ad.idBusinessPartner }}</td>
                    <td v-if="this.displayRow.spendOnDay">{{ formatCurrency(ad.spendOnDay) }}</td>
                    <td v-if="this.displayRow.spendingNow">{{ formatCurrency(ad.spendingNow) }}</td>
                    <td v-if="this.displayRow.totalSpend">{{ formatCurrency(ad.totalSpend) }}</td>
                    <td v-if="this.displayRow.invoiceAmountOnDay">{{ formatCurrency(ad.invoiceAmountOnDay) }}</td>
                    <td v-if="this.displayRow.totalInvoiceAmount">{{ formatCurrency(ad.totalInvoiceAmount) }}</td>
                    <td v-if="this.displayRow.dailyAmountTopUp">{{ formatCurrency(ad.dailyAmountTopUp) }}</td>
                    <td v-if="this.displayRow.totalAmountTopUp">{{ formatCurrency(ad.totalAmountTopUp) }}</td>
                    <td v-if="this.displayRow.lastUpdate">{{ formatDateTime(ad.lastUpdate) }}</td>
                  <td ref="target" 
                    class="text-end"
                    :class="{
                      active__update__combobox:
                        onShowMenu && currenOnshowId === ad.adsId,
                    }"
                  >
                    <a 
                      @click="toggleMenuItem(ad.adsId)"
                      class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary show menu-dropdown"
                      data-kt-menu-trigger="click"
                      data-kt-menu-placement="bottom-end"
                      >{{ this.$NOLIMITResource[this.dataUser.language].SeeDetail }} <i class="ki-duotone ki-down fs-5 ms-1"></i
                    ></a>          
                    <!--begin::Menu-->
                    <div
                      class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 py-4" style="width:135px !important"
                      :class="{
                        show: onShowMenu && currenOnshowId === ad.adsId,
                      }"
                      data-kt-menu="true"
                      @mouseleave="closeMenuItem"
                    >
                      <!--begin::Menu item-->
                      <div class="menu-item px-3">
                        <a
                          @click="openModalUpdateAds(ad.adsId,'Sửa thông tin')"
                          class="menu-link px-3"
                          >{{ this.$NOLIMITResource[this.dataUser.language].UserManagement.UpdateInformation }}</a
                        > 
                      </div>
                      <!--end::Menu item-->
                         <!--begin::Menu item-->
                         <div class="menu-item px-3">
                        <a
                          @click="openModalUpdateTopupFee(ad.adsId)"
                        
                          class="menu-link px-3"
                          >{{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.UpdateTopupFee }}</a
                        >
                      </div>
                      <!--end::Menu item-->
                      <!--begin::Menu item-->
                      <div class="menu-item px-3">
                        <!--begin::Menu item-->
                          <router-link  
                            :to="{name:'TopUpListByAds', query:{idAds: ad.adsId}}"
                            class="menu-link px-3"
                            >{{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.AllTopup }}</router-link
                          >
                        </div>
                        <!--end::Menu item-->
                        <!--begin::Menu item-->
                      <div class="menu-item px-3">
                        <!--begin::Menu item-->
                          <router-link  
                            :to="{name:'AdsHistory', query:{idAds: ad.adsId}}"
                            class="menu-link px-3"
                            >{{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.AdsHistory }}</router-link
                          >
                        </div>
                        <!--end::Menu item-->
                        <!--begin::Menu item-->
                      <div class="menu-item px-3">
                        <a
                          @click="openModalUpdateAds(ad.adsId,'Cấp tài khoản')"
                          class="menu-link px-3"
                          >{{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.ProvideAdsToUser }}</a
                        >
                      </div>
                      <!--end::Menu item-->
                      <!--begin::Menu item-->
                      <div class="menu-item px-3">
                        <a
                          @click="openModalReturnAds(ad.adsId)"
                          class="menu-link px-3"
                          >{{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.RecallAd }}</a
                        >
                      </div>
                      <!--end::Menu item-->
                      <!--begin::Menu item-->
                      <div v-if="ad.adsStatus != '0'" class="menu-item px-3">
                        <a
                          @click="updateStatusAdsByAdsId(ad.adsId)"
                          class="menu-link px-3"
                          >{{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.RequestUpdate }}</a
                        >
                      </div>
                      <!--end::Menu item-->
                    </div>
                    <!--end::Menu-->
                  </td>

                </tr>
                <tr id="total">
                   <td class="fix-data-footer"  colspan="3" style="font-weight: bold"> <img src="./../../../../public/frontend/media/logos/logo3.png" style="width:30px;height:auto"/>  {{this.adsAll.length}} {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.Ads }}</td> 
                   <td v-if="this.displayRow.topupFeeUser"></td>
                   <td v-if="this.displayRow.notes"></td>
                   <td ></td>
                   <td v-if="this.displayRow.shareStatus"></td>
                   <td v-if="this.displayRow.lastTopupDate"></td>
                    <td v-if="this.displayRow.createDate"></td>
                    <td v-if="this.displayRow.spendingCap">{{formatCurrency(totalSpencap)}} đ</td>
                    <td v-if="this.displayRow.remainingAmount">{{formatCurrency(totalBalance)}} đ</td>
                    <td v-if="this.displayRow.customerNow"></td>
                    <td v-if="this.displayRow.supporter"></td>
                    <td v-if="this.displayRow.returnDate"></td>
                    <td v-if="this.displayRow.paymentMethod">
                      
                    </td>          
                    <td v-if="this.displayRow.timezone"></td>
                    <td v-if="this.displayRow.currency"></td>
                    <td v-if="this.displayRow.nationallity"></td>
                    <td v-if="this.displayRow.limit">{{formatCurrency(totalLimit)}} đ</td>
                    <td v-if="this.displayRow.dailyLimit"> 
                      
                    </td>
                    <td v-if="this.displayRow.paymentThreshold"></td>
                    <td v-if="this.displayRow.remainThreshold"></td>
                    <td v-if="this.displayRow.invoiceDate"></td>
                    <td v-if="this.displayRow.remainPaymentDate"></td>
                    <td v-if="this.displayRow.idbusinessOwner"></td>
                    <td v-if="this.displayRow.nameBusinessOwner" ></td>
                    <td v-if="this.displayRow.idBusinessPartner"></td>
                    <td v-if="this.displayRow.spendOnDay"></td>
                    <td v-if="this.displayRow.spendingNow"></td>
                    <td v-if="this.displayRow.totalSpend"></td>
                    <td v-if="this.displayRow.invoiceAmountOnDay"></td>
                    <td v-if="this.displayRow.totalInvoiceAmount"></td>
                    <td v-if="this.displayRow.dailyAmountTopUp"></td>
                    <td v-if="this.displayRow.totalAmountTopUp"></td>
                    <td v-if="this.displayRow.lastUpdate"></td>
                </tr>
              </tbody>
            
            </table>
            <div class="row">
              <div
                class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"
              >
                <div class="dataTables_length" id="kt_customers_table_length">
                  <label>
                    <v-select v-model="this.pageSize" id="styleSelect" :options="numberPerPage" label="value" ></v-select>
                  </label>
                </div>
              </div>
              <div
                class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end"
              >
                <div
                  class="dataTables_paginate paging_simple_numbers"
                  id="kt_customers_table_paginate"
                >
                  <ul v-if="this.totalPage >= 4" class="pagination">
                    <li
                      class="paginate_button page-item previous"
                      :class="{ disabled: this.pageNumber == 1 }"
                      id="kt_customers_table_previous"
                    >
                      <a
                        @click="prevPage"
                                                aria-controls="kt_customers_table"
                        data-dt-idx="0"
                        tabindex="0"
                        class="page-link"
                        ><i class="previous"></i
                      ></a>
                    </li>
                    <li
                      v-for="index in 4"
                      :key="index"
                      class="paginate_button page-item"
                      :class="{ active: pageNumber == this.firstPage + index }"
                    >
                      <a
                        @click="selectPage(this.firstPage + index)"
                                                aria-controls="kt_customers_table"
                        data-dt-idx="1"
                        tabindex="0"
                        class="page-link"
                        >{{ this.firstPage + index }}</a
                      >
                    </li>
                    <li
                      class="paginate_button page-item next"
                      :class="{ disabled: this.pageNumber == this.totalPage }"
                      id="kt_customers_table_next"
                    >
                      <a
                        @click="nextPage"
                                                aria-controls="kt_customers_table"
                        data-dt-idx="5"
                        tabindex="0"
                        class="page-link"
                        ><i class="next"></i
                      ></a>
                    </li>
                  </ul>
                  <ul v-if="this.totalPage < 4" class="pagination">
                    <li
                      class="paginate_button page-item previous"
                      :class="{ disabled: this.pageNumber == 1 }"
                      id="kt_customers_table_previous"
                    >
                      <a
                        @click="prevPage"
                                                aria-controls="kt_customers_table"
                        data-dt-idx="0"
                        tabindex="0"
                        class="page-link"
                        ><i class="previous"></i
                      ></a>
                    </li>
                    <li
                      v-for="index in totalPage"
                      :key="index"
                      class="paginate_button page-item"
                      :class="{ active: pageNumber == this.firstPage + index }"
                    >
                      <a
                        @click="selectPage(this.firstPage + index)"
                                                aria-controls="kt_customers_table"
                        data-dt-idx="1"
                        tabindex="0"
                        class="page-link"
                        >{{ this.firstPage + index }}</a
                      >
                    </li>
                    <li
                      class="paginate_button page-item next"
                      :class="{ disabled: this.pageNumber == this.totalPage }"
                      id="kt_customers_table_next"
                    >
                      <a
                        @click="nextPage"
                                                aria-controls="kt_customers_table"
                        data-dt-idx="5"
                        tabindex="0"
                        class="page-link"
                        ><i class="next"></i
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!--end::Table-->
          </div>
          <!--end::Card body-->
        </div>
        <!--end::Card-->
        <!--begin::Modals-->
         <!--begin::Modal - User - Return-->
         <div
          class="modal fade show"
          :class="{ block: isShowModalReturnAds }"
          id="kt_modal_add_customer"
          tabindex="-1"
          aria-hidden="true"
        >
          <!--begin::Modal dialog-->
          <div class="modal-dialog modal-dialog-centered mw-650px">
            <!--begin::Modal content-->
            <div class="modal-content" style="width:160%">
              <!--begin::Form-->
              <form
                class="form"
                id="kt_modal_add_customer_form"
                action=""
                @submit.prevent="confirmReturnAllAdsAccount(this.adsCurrent.adsId)"
              >
                <!--begin::Modal header-->
                <div class="modal-header" id="kt_modal_add_customer_header">
                  <!--begin::Modal title-->
                  <h2 class="fw-bold"> {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.RecallAd }}</h2>
                  <!--end::Modal title-->
                  <!--begin::Close-->
                  <div
                    @click="closeModalReturnAds"
                    id="kt_modal_add_customer_close"
                    class="btn btn-icon btn-sm btn-active-icon-primary"
                  >
                    <i class="ki-duotone ki-cross fs-1">
                      <span class="path1"></span>
                      <span class="path2"></span>                                                                                                                                                                     
                    </i>
                  </div>
                  <!--end::Close-->
                </div>
                <!--end::Modal header-->
                <!--begin::Modal body-->
                <div class="modal-body py-10 px-lg-17">
                  <!--begin::Scroll-->
                  <div
                    class="scroll-y me-n7 pe-7"
                    id="kt_modal_add_customer_scroll"
                    data-kt-scroll="true"
                    data-kt-scroll-activate="{default: false, lg: true}"
                    data-kt-scroll-max-height="auto"
                    data-kt-scroll-dependencies="#kt_modal_add_customer_header"
                    data-kt-scroll-wrappers="#kt_modal_add_customer_scroll"
                    data-kt-scroll-offset="300px"
                  >
                  <table
              class="table align-middle table-row-dashed fs-6 gy-5"
              id="kt_customers_table"
            >
              <thead>
                <tr
                  class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0"
                >
                  <th class="min-w-125px">Id</th>
                  <th class="min-w-125px">Username</th>
                  <th class="min-w-125px">{{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.ProvideDate }}</th>
                  <th class="min-w-125px"> {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.ReturnDate }}</th>
                  <th class="text-end min-w-70px"></th>
                </tr>
              </thead>
              <div class="loading" v-if="onLoading">
                <span class="spinner-border text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </span>
              </div>
              <div id="noData" v-if="!this.onLoading && this.hasData == false">
                {{ this.$NOLIMITResource[this.dataUser.language].EmptyData }}
              </div>
              <tbody
                v-if="!this.onLoading && this.hasData == true"
                class="fw-semibold text-gray-600"
              >
                <tr
                  v-for="mediateAdUser in listMediateAdUserByAdsId"
                  :key="mediateAdUser.id"
                >
                  <td>
                    <a
                      class="text-gray-800 text-hover-primary mb-1"
                      >{{ mediateAdUser.userId }}</a
                    >
                  </td>
                  <td>
                    <a
                      class="text-gray-800 text-hover-primary mb-1"
                      >{{ mediateAdUser.userName }}</a
                    >
                  </td>
                  <td>
                    <a
                      class="text-gray-800 text-hover-primary mb-1"
                      >{{ formatDateTime(mediateAdUser.deliveryDate) }}</a
                    >
                  </td>
                  <td>
                    <a
                      class="text-gray-800 text-hover-primary mb-1"
                      >{{ formatDateTime(mediateAdUser.returnDate) }}</a
                    >
                  </td>
                  <td class="text-end" style="display: flex; height: 75px;">
                  
                    <a @click="confirmReturnAdsAccount(mediateAdUser.id,mediateAdUser.adsId,mediateAdUser.userId)"
                      class="btn btn-light-danger btn-sm"
                      data-kt-menu-trigger="click"
                      data-kt-menu-placement="bottom-end"
                      > {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.Recall }}</a
                    >
                  </td>
                </tr>
              </tbody>
            </table>
                    
                     
                 
                 
                  </div>
                  <!--end::Scroll-->
                </div>
                <!--end::Modal body-->
                <!--begin::Modal footer-->
                <div class="modal-footer flex-center">
                  <!--begin::Button-->
                  <button
                    @click="closeModalReturnAds"
                    type="reset"
                    id="kt_modal_add_customer_cancel"
                    class="btn btn-light me-3"
                  >
                  {{ this.$NOLIMITResource[this.dataUser.language].Cancel }}
                  </button>
                  <!--end::Button-->
                  <!--begin::Button-->
                  <button
                    type="submit"
                    id="kt_modal_add_customer_submit"
                    class="btn btn-primary"
                  >
                  
                    <span class="indicator-label"> {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.RecallAdsForManyUser }}</span>
                    <span class="indicator-progress"
                      >Please wait...
                      <span
                        class="spinner-border spinner-border-sm align-middle ms-2"
                      ></span
                    ></span>
                  </button>
                  <!--end::Button-->
                </div>
                <!--end::Modal footer-->
              </form>
              <!--end::Form-->
            </div>
          </div>
          
        </div>
        <!--end::Modal - User - Return-->
        <!--begin::Modal - User - Update-->
        <div
          class="modal fade show"
          :class="{ block: isShowModalUpdateAds }"
          id="kt_modal_add_customer"
          tabindex="-1"
          aria-hidden="true"
        >
          <!--begin::Modal dialog-->
          <div class="modal-dialog modal-dialog-centered mw-650px">
            <!--begin::Modal content-->
            <div class="modal-content">
              <!--begin::Form-->
              <form
                class="form"
                id="kt_modal_add_customer_form"
                action=""
                @submit.prevent="updateAds"
              >
                <!--begin::Modal header-->
                <div class="modal-header" id="kt_modal_add_customer_header">
                  <!--begin::Modal title-->
                  <h2 v-if="this.formMode =='Sửa thông tin'" class="fw-bold"> {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.EditAdAccount }}</h2>
                  <h2 v-else class="fw-bold">{{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.ProvideAdsToUser }}</h2>
                  <!--end::Modal title-->
                  <!--begin::Close-->
                  <div
                    @click="closeModalUpdateAds"
                    id="kt_modal_add_customer_close"
                    class="btn btn-icon btn-sm btn-active-icon-primary"
                  >
                    <i class="ki-duotone ki-cross fs-1">
                      <span class="path1"></span>
                      <span class="path2"></span>                                                                                                                                                                     
                    </i>
                  </div>
                  <!--end::Close-->
                </div>
                <!--end::Modal header-->
                <!--begin::Modal body-->
                <div class="modal-body py-10 px-lg-17">
                  <!--begin::Scroll-->
                  <div
                    class="scroll-y me-n7 pe-7"
                    id="kt_modal_add_customer_scroll"
                    data-kt-scroll="true"
                    data-kt-scroll-activate="{default: false, lg: true}"
                    data-kt-scroll-max-height="auto"
                    data-kt-scroll-dependencies="#kt_modal_add_customer_header"
                    data-kt-scroll-wrappers="#kt_modal_add_customer_scroll"
                    data-kt-scroll-offset="300px"
                  >
                   
                     <!--begin::Input group-->
                     <div class="d-flex flex-column mb-7 fv-row" v-if="this.formMode =='Sửa thông tin'">
                      <!--begin::Label-->
                      <label
                        class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                      >
                        <span> {{ this.$NOLIMITResource[this.dataUser.language].Note }}</span>
                        <span
                          class="ms-1"
                          data-bs-toggle="tooltip"
                          :title="this.$NOLIMITResource[this.dataUser.language].Note"
                        >
                          <i
                            class="ki-duotone ki-information-5 text-gray-500 fs-6"
                          >
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                          </i>
                        </span>
                      </label>
                      <!--end::Label-->
                      <input
                        type="text"
                        class="form-control form-control-solid"
                        placeholder=""
                        name="card_name"
                        v-model="adsCurrent.notes"
                      />
                      
                    </div>
                    <!--end::Input group-->
                   
                    <!--begin::Input group-->
                    <div class="d-flex flex-column mb-7 fv-row" v-if="this.formMode =='Cấp tài khoản'">
                      <!--begin::Label-->
                      <label
                        class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                      >
                        <span>UserId</span>
                        <span
                          class="ms-1"
                          data-bs-toggle="tooltip"
                          title="UserId của tài khoản quảng cáo"
                        >
                          <i
                            class="ki-duotone ki-information-5 text-gray-500 fs-6"
                          >
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                          </i>
                        </span>
                      </label>
                      <!--end::Label-->
                      <input
                        type="text"
                        class="form-control form-control-solid"
                        placeholder=""
                        name="card_name"
                        v-model="adsCurrent.userId"
                      />
                      <div v-if="this.errors.userId != ''" class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
                        <div data-field="userId" data-validator="notEmpty">{{ this.errors.userId }}</div>
                      </div>
                    </div>
                    <!--end::Input group-->
                     <!--begin::Input group-->
                    <div
                      class="fv-row mb-7 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid"
                      data-select2-id="select2-data-133-r1eg" v-if="this.formMode =='Sửa thông tin'"
                    >
                    <label
                        class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                      >
                        <span> {{ this.$NOLIMITResource[this.dataUser.language].Status }}</span>
                        <span
                          class="ms-1"
                          data-bs-toggle="tooltip"
                          :title="this.$NOLIMITResource[this.dataUser.language].Status"
                        >
                          <i
                            class="ki-duotone ki-information-5 text-gray-500 fs-6"
                          >
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                          </i>
                        </span>
                      </label>
                      <!--begin::Input-->
                      <v-select id="styleSelect" v-model="adsCurrent.shareStatus" :options="this.listStatus" label="status"></v-select>
                      <!--end::Input-->
                      <div
                        class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"
                      ></div>
                    </div>
                    <!--end::Input group-->
                     
                 
                 
                  </div>
                  <!--end::Scroll-->
                </div>
                <!--end::Modal body-->
                <!--begin::Modal footer-->
                <div class="modal-footer flex-center">
                  <!--begin::Button-->
                  <button
                    @click="closeModalUpdateAds"
                    type="reset"
                    id="kt_modal_add_customer_cancel"
                    class="btn btn-light me-3"
                  >
                  {{ this.$NOLIMITResource[this.dataUser.language].Cancel }}
                  </button>
                  <!--end::Button-->
                  <!--begin::Button-->
                  <button
                    type="submit"
                    id="kt_modal_add_customer_submit"
                    class="btn btn-primary"
                  >
                  <span v-if="this.formMode =='Sửa thông tin'" class="indicator-label">{{ this.$NOLIMITResource[this.dataUser.language].Save }}</span>
                    <span v-else class="indicator-label">{{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.ProvideAds }}</span>
                    <span class="indicator-progress"
                      >Please wait...
                      <span
                        class="spinner-border spinner-border-sm align-middle ms-2"
                      ></span
                    ></span>
                  </button>
                  <!--end::Button-->
                </div>
                <!--end::Modal footer-->
              </form>
              <!--end::Form-->
            </div>
          </div>
          
        </div>
        <!--end::Modal - User - Update-->

  <!--begin::Modal - User - Change - TopupFee-->
  <div
          class="modal fade show"
          :class="{ block: isShowModalUpdateTopup }"
          id="kt_modal_add_customer"
          tabindex="-1"
          aria-hidden="true"
        >
          <!--begin::Modal dialog-->
          <div class="modal-dialog modal-dialog-centered mw-650px">
            <!--begin::Modal content-->
            <div class="modal-content">
              <!--begin::Form-->
              <form
                class="form"
                id="kt_modal_add_customer_form"
                action=""
                @submit.prevent="updateTopupFee"
              >
                <!--begin::Modal header-->
                <div class="modal-header" id="kt_modal_add_customer_header">
                  <!--begin::Modal title-->
                  <h2 class="fw-bold">{{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.UpdateTopupFee }}</h2>
                  <!--end::Modal title-->
                  <!--begin::Close-->
                  <div
                    @click="closeModalUpdateTopupFee"
                    id="kt_modal_add_customer_close"
                    class="btn btn-icon btn-sm btn-active-icon-primary"
                  >
                    <i class="ki-duotone ki-cross fs-1">
                      <span class="path1"></span>
                      <span class="path2"></span>
                    </i>
                  </div>
                  <!--end::Close-->
                </div>
                <!--end::Modal header-->
                <!--begin::Modal body-->
                <div class="modal-body py-10 px-lg-17">
                  <!--begin::Scroll-->
                  <div
                    class="scroll-y me-n7 pe-7"
                    id="kt_modal_add_customer_scroll"
                    data-kt-scroll="true"
                    data-kt-scroll-activate="{default: false, lg: true}"
                    data-kt-scroll-max-height="auto"
                    data-kt-scroll-dependencies="#kt_modal_add_customer_header"
                    data-kt-scroll-wrappers="#kt_modal_add_customer_scroll"
                    data-kt-scroll-offset="300px"
                  >
                
                    <!--begin::Input group-->
                    <div class="d-flex flex-column mb-7 fv-row" >
                      <!--begin::Label-->
                      <label
                        class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                      >
                        <span> {{ this.$NOLIMITResource[this.dataUser.language].TopupFeePercent }}</span>
                        <span
                          class="ms-1"
                          data-bs-toggle="tooltip"
                          title="Họ tên của người dùng"
                        >
                          <i
                            class="ki-duotone ki-information-5 text-gray-500 fs-6"
                          >
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                          </i>
                        </span>
                      </label>
                      <!--end::Label-->
                      <input
                        type="text"
                        class="form-control form-control-solid"
                        placeholder=""
                        name="card_name"
                        v-model="adsCurrent.topupFee"
                      />
                    </div>
                    <!--end::Input group-->
                  
                  
                  </div>
                  <!--end::Scroll-->
                </div>
                <!--end::Modal body-->
                <!--begin::Modal footer-->
                <div class="modal-footer flex-center">
                  <!--begin::Button-->
                  <button
                    @click="closeModalUpdateTopupFee"
                    type="reset"
                    id="kt_modal_add_customer_cancel"
                    class="btn btn-light me-3"
                  >
                  {{ this.$NOLIMITResource[this.dataUser.language].Cancel }}
                  </button>
                  <!--end::Button-->
                  <!--begin::Button-->
                  <button
                    type="submit"
                    id="kt_modal_add_customer_submit"
                    class="btn btn-primary"
                  >
                    <span class="indicator-label"> {{ this.$NOLIMITResource[this.dataUser.language].Save }}</span>
                    <span class="indicator-progress"
                      >Please wait...
                      <span
                        class="spinner-border spinner-border-sm align-middle ms-2"
                      ></span
                    ></span>
                  </button>
                  <!--end::Button-->
                </div>
                <!--end::Modal footer-->
              </form>
              <!--end::Form-->
            </div>
          </div>
        </div>
        <!--end::Modal - User - Change - TopupFee-->
        
        <!--begin::Modal - User - Provide - All-->
        <div
          class="modal fade show"
          :class="{ block: isShowModalProvideAllAds }"
          id="kt_modal_add_customer"
          tabindex="-1"
          aria-hidden="true"
        >
          <!--begin::Modal dialog-->
          <div class="modal-dialog modal-dialog-centered mw-650px">
            <!--begin::Modal content-->
            <div class="modal-content">
              <!--begin::Form-->
              <form
                class="form"
                id="kt_modal_add_customer_form"
                action=""
                @submit.prevent="provideManyAds"
              >
                <!--begin::Modal header-->
                <div class="modal-header" id="kt_modal_add_customer_header">
                  <!--begin::Modal title-->
                  <h2 class="fw-bold"> {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.GrantAccountsToUsers }}</h2>
                  <!--end::Modal title-->
                  <!--begin::Close-->
                  <div
                    @click="closeModalProvideAll"
                    id="kt_modal_add_customer_close"
                    class="btn btn-icon btn-sm btn-active-icon-primary"
                  >
                    <i class="ki-duotone ki-cross fs-1">
                      <span class="path1"></span>
                      <span class="path2"></span>                                                                                                                                                                     
                    </i>
                  </div>
                  <!--end::Close-->
                </div>
                <!--end::Modal header-->
                <!--begin::Modal body-->
                <div class="modal-body py-10 px-lg-17">
                  <!--begin::Scroll-->
                  <div
                    class="scroll-y me-n7 pe-7"
                    id="kt_modal_add_customer_scroll"
                    data-kt-scroll="true"
                    data-kt-scroll-activate="{default: false, lg: true}"
                    data-kt-scroll-max-height="auto"
                    data-kt-scroll-dependencies="#kt_modal_add_customer_header"
                    data-kt-scroll-wrappers="#kt_modal_add_customer_scroll"
                    data-kt-scroll-offset="300px"
                  >
                    
                    <!--begin::Input group-->
                    <div class="d-flex flex-column mb-7 fv-row">
                      <!--begin::Label-->
                      <label
                        class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                      >
                        <span>UserId</span>
                        <span
                          class="ms-1"
                          data-bs-toggle="tooltip"
                          title="Tên của tài khoản quảng cáo"
                        >
                          <i
                            class="ki-duotone ki-information-5 text-gray-500 fs-6"
                          >
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                          </i>
                        </span>
                      </label>
                      <!--end::Label-->
                      <input
                        type="text"
                        class="form-control form-control-solid"
                        placeholder=""
                        name="card_name"
                        v-model="userIdProvide"
                      />
                      <div v-if="this.errors.userId != ''" class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
                        <div data-field="userId" data-validator="notEmpty">{{ this.errors.userId }}</div>
                      </div>
                    </div>
                    <!--end::Input group-->
                     
                  </div>
                  <!--end::Scroll-->
                </div>
                <!--end::Modal body-->
                <!--begin::Modal footer-->
                <div class="modal-footer flex-center">
                  <!--begin::Button-->
                  <button
                    @click="closeModalProvideAll"
                    type="reset"
                    id="kt_modal_add_customer_cancel"
                    class="btn btn-light me-3"
                  >
                  {{ this.$NOLIMITResource[this.dataUser.language].Cancel }}
                  </button>
                  <!--end::Button-->
                  <!--begin::Button-->
                  <button
                    type="submit"
                    id="kt_modal_add_customer_submit"
                    class="btn btn-primary"
                  >
                    <span class="indicator-label">{{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.ProvideAds }}</span>
                    <span class="indicator-progress"
                      >Please wait...
                      <span
                        class="spinner-border spinner-border-sm align-middle ms-2"
                      ></span
                    ></span>
                  </button>
                  <!--end::Button-->
                </div>
                <!--end::Modal footer-->
              </form>
              <!--end::Form-->
            </div>
          </div>
          
        </div>
        <!--end::Modal - User - Provide - All-->

 <!--begin::Modal - Update - TopupFee - All-->
 <div
          class="modal fade show"
          :class="{ block: isShowModalUpdateFeeTopupAll }"
          id="kt_modal_add_customer"
          tabindex="-1"
          aria-hidden="true"
        >
          <!--begin::Modal dialog-->
          <div class="modal-dialog modal-dialog-centered mw-650px">
            <!--begin::Modal content-->
            <div class="modal-content">
              <!--begin::Form-->
              <form
                class="form"
                id="kt_modal_add_customer_form"
                action=""
                @submit.prevent="updateBatchTopupFee"
              >
                <!--begin::Modal header-->
                <div class="modal-header" id="kt_modal_add_customer_header">
                  <!--begin::Modal title-->
                  <h2 class="fw-bold"> {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.UpdateBatchTopupFeePickedAds }}</h2>
                  <!--end::Modal title-->
                  <!--begin::Close-->
                  <div
                    @click="closeModalUpdateFeeTopupAll"
                    id="kt_modal_add_customer_close"
                    class="btn btn-icon btn-sm btn-active-icon-primary"
                  >
                    <i class="ki-duotone ki-cross fs-1">
                      <span class="path1"></span>
                      <span class="path2"></span>                                                                                                                                                                     
                    </i>
                  </div>
                  <!--end::Close-->
                </div>
                <!--end::Modal header-->
                <!--begin::Modal body-->
                <div class="modal-body py-10 px-lg-17">
                  <!--begin::Scroll-->
                  <div
                    class="scroll-y me-n7 pe-7"
                    id="kt_modal_add_customer_scroll"
                    data-kt-scroll="true"
                    data-kt-scroll-activate="{default: false, lg: true}"
                    data-kt-scroll-max-height="auto"
                    data-kt-scroll-dependencies="#kt_modal_add_customer_header"
                    data-kt-scroll-wrappers="#kt_modal_add_customer_scroll"
                    data-kt-scroll-offset="300px"
                  >
                    
                    <!--begin::Input group-->
                    <div class="d-flex flex-column mb-7 fv-row">
                      <!--begin::Label-->
                      <label
                        class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                      >
                        <span> {{ this.$NOLIMITResource[this.dataUser.language].TopupFeePercent }}</span>
                        <span
                          class="ms-1"
                          data-bs-toggle="tooltip"
                          title="Phí topup của tài khoản quảng cáo"
                        >
                          <i
                            class="ki-duotone ki-information-5 text-gray-500 fs-6"
                          >
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                          </i>
                        </span>
                      </label>
                      <!--end::Label-->
                      <input
                        type="text"
                        class="form-control form-control-solid"
                        placeholder=""
                        name="card_name"
                        v-model="adsCurrent.topupFee"
                      />
                      <div v-if="this.errors.userId != ''" class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
                        <div data-field="userId" data-validator="notEmpty">{{ this.errors.userId }}</div>
                      </div>
                    </div>
                    <!--end::Input group-->
                     
                  </div>
                  <!--end::Scroll-->
                </div>
                <!--end::Modal body-->
                <!--begin::Modal footer-->
                <div class="modal-footer flex-center">
                  <!--begin::Button-->
                  <button
                    @click="closeModalUpdateFeeTopupAll"
                    type="reset"
                    id="kt_modal_add_customer_cancel"
                    class="btn btn-light me-3"
                  >
                  {{ this.$NOLIMITResource[this.dataUser.language].Cancel }}
                  </button>
                  <!--end::Button-->
                  <!--begin::Button-->
                  <button
                    type="submit"
                    id="kt_modal_add_customer_submit"
                    class="btn btn-primary"
                  >
                    <span class="indicator-label">{{ this.$NOLIMITResource[this.dataUser.language].Save }}</span>
                    <span class="indicator-progress"
                      >Please wait...
                      <span
                        class="spinner-border spinner-border-sm align-middle ms-2"
                      ></span
                    ></span>
                  </button>
                  <!--end::Button-->
                </div>
                <!--end::Modal footer-->
              </form>
              <!--end::Form-->
            </div>
          </div>
          
        </div>
        <!--end::Modal - Update - TopupFee - All-->

        <!--begin::Modal - add ads-->
        <div
          class="modal fade show"
          :class="{ block: isShowModalAddAds }"
          id="kt_modal_new_card"
          role="dialog"
          tabindex="-1"
          aria-modal="true"
        >
          <!--begin::Modal dialog-->
          <div class="modal-dialog modal-dialog-centered mw-650px">
            <!--begin::Modal content-->
            <div class="modal-content">
              <!--begin::Modal header-->
              <div class="modal-header">
                <!--begin::Modal title-->
                <h2> {{ this.$NOLIMITResource[this.dataUser.language].AddAd }}</h2>

                <div
                  @click="closeModalAddAds"
                  class="btn btn-sm btn-icon btn-active-color-primary"
                  data-bs-dismiss="modal"
                >
                  <i class="ki-duotone ki-cross fs-1">
                    <span class="path1"></span>
                    <span class="path2"></span>
                  </i>
                </div>
              </div>
              <!--end::Modal header-->
              <!--begin::Modal body-->
              <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
                <!--begin::Form-->
                <form
                  id="kt_modal_new_card_form"
                  class="form"
                  action=""
                  @submit.prevent="addAds"
                >
                  <!--begin::Input group-->
                  <div class="d-flex flex-column mb-7 fv-row">
                    <!--begin::Label-->
                    <label
                      class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                    >
                      <span>FacebookAdsId</span>
                      <span
                        class="ms-1"
                        data-bs-toggle="tooltip"
                        title="FacebookId của tài khoản ads"
                      >
                        <i
                          class="ki-duotone ki-information-5 text-gray-500 fs-6"
                        >
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                        </i>
                      </span>
                    </label>
                    <!--end::Label-->
                    <input
                      type="text"
                      class="form-control form-control-solid"
                      placeholder=""
                      name="card_name"
                      v-model="adsAdd.facebookAdsId"
                    />
                    <div v-if="this.errorsAds.facebookAdsId != ''" class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
                      <div data-validator="notEmpty">{{ this.errorsAds.facebookAdsId }}</div>
                    </div>
                  </div>
                  <!--end::Input group-->
                 
                  <!--begin::Input group-->
                  <div class="d-flex flex-column mb-7 fv-row">
                    <!--begin::Label-->
                    <label
                      class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                    >
                      <span> {{ this.$NOLIMITResource[this.dataUser.language].Note }}</span>
                      <span
                        class="ms-1"
                        data-bs-toggle="tooltip"
                        title="Ghi chú tài khoản ads"
                      >
                        <i
                          class="ki-duotone ki-information-5 text-gray-500 fs-6"
                        >
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                        </i>
                      </span>
                    </label>
                    <!--end::Label-->
                    <input
                      type="text"
                      class="form-control form-control-solid"
                      placeholder=""
                      name="card_name"
                      v-model="adsAdd.notes"
                    />
                  </div>
                  <!--end::Input group-->
                 

                   <!--begin::Input group-->
                   <div class="d-flex flex-column mb-7 fv-row">
                    <!--begin::Label-->
                    <label
                      class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                    >
                      <span>{{ this.$NOLIMITResource[this.dataUser.language].TopupFeePercent }}</span>
                      <span
                        class="ms-1"
                        data-bs-toggle="tooltip"
                        title="Phí topup của tài khoản ads"
                      >
                        <i
                          class="ki-duotone ki-information-5 text-gray-500 fs-6"
                        >
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                        </i>
                      </span>
                    </label>
                    <!--end::Label-->
                    <input
                      type="text"
                      class="form-control form-control-solid"
                      placeholder=""
                      name="card_name"
                      v-model="adsAdd.topupFee"
                    />
                  </div>
                  <!--end::Input group-->
                  <!--begin::Actions-->
                  <div class="text-center pt-15">
                    <button
                      @click="closeModalAddAds"
                      type="reset"
                      id="kt_modal_new_card_cancel"
                      class="btn btn-light me-3"
                    >
                    {{ this.$NOLIMITResource[this.dataUser.language].Cancel }}
                    </button>
                    <button
                      type="submit"
                      id="kt_modal_new_card_submit"
                      class="btn btn-primary"
                    >
                      <span
                        class="indicator-label"
                        > {{ this.$NOLIMITResource[this.dataUser.language].Add }}</span
                      >
                      <span class="indicator-progress"
                        >Please wait...
                        <span
                          class="spinner-border spinner-border-sm align-middle ms-2"
                        ></span
                      ></span>
                    </button>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Modal body-->
            </div>
            <!--end::Modal content-->
          </div>
          <!--end::Modal dialog-->
        </div>
        <!--end::Modal - change money-->
        <!--begin::Modal - Adjust Balance-->
        <div
          class="modal fade show"
          :class="{ block: isShowModalImportManyAds }"
          id="kt_customers_export_modal"
          tabindex="-1"
          aria-hidden="true"
        >
          <!--begin::Modal dialog-->
          <div class="modal-dialog modal-dialog-centered mw-650px">
            <!--begin::Modal content-->
            <div class="modal-content">
              <!--begin::Modal header-->
              <div class="modal-header">
                <!--begin::Modal title-->
                <h2 class="fw-bold"> {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.AddMultipleAdsAccounts }}</h2>
                <!--end::Modal title-->
                <!--begin::Close-->
                <div
                  id="kt_customers_export_close"
                  class="btn btn-icon btn-sm btn-active-icon-primary"
                >
                  <i class="ki-duotone ki-cross fs-1" @click="closeModalImportManyAds">
                    <span class="path1"></span>
                    <span class="path2"></span>
                  </i>
                </div>
                <!--end::Close-->
              </div>
              <!--end::Modal header-->
              <!--begin::Modal body-->
              <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
                <!--begin::Form-->
                <form id="kt_customers_export_form" class="form" action="" @submit.prevent="convertStringToArray()">
                  
                  <!--begin::Input group-->
                  <div class="input-group input-group-solid " style="margin-bottom: 50px;">
                    <label
                      class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                    >
                      <span>{{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.AddMultiAdInput }}</span>
                      <span
                        class="ms-1"
                        data-bs-toggle="tooltip"
                        :title="this.$NOLIMITResource[this.dataUser.language].AdsAccount.AddMultipleAdsAccounts"
                      >
                        <i
                          class="ki-duotone ki-information-5 text-gray-500 fs-6"
                        >
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                        </i>
                      </span>
                    </label>
                      <textarea v-model="this.adsImport" class="form-control" style="width: 100%;" aria-label="With textarea"></textarea>
                  </div>
                  <!--end::Input group-->


                  <!--begin::Actions-->
                  <div class="text-center">
                    <button
                      type="reset"
                      id="kt_customers_export_cancel"
                      class="btn btn-light me-3"
                      @click="closeModalImportManyAds"
                    >
                    {{ this.$NOLIMITResource[this.dataUser.language].Cancel }}
                    </button>
                    <button
                      type="button"
                      id="kt_customers_export_cancel"
                      class="btn  btn-info me-3"
                      @click="checkDuplicate"
                    >
                    {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.RemoveDuplicateAccounts }}
                    </button>
                    <button
                      type="submit"
                      id="kt_customers_export_submit"
                      class="btn btn-primary"
                    >
                      <span class="indicator-label"> {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.AddBatchAds }}</span>
                      <span class="indicator-progress"
                        >Please wait...
                        <span
                          class="spinner-border spinner-border-sm align-middle ms-2"
                        ></span
                      ></span>
                    </button>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Modal body-->
            </div>
            <!--end::Modal content-->
          </div>
          <!--end::Modal dialog-->
        </div>
        <!--end::Modal - New Card-->
        <!--end::Modals-->
        <!--begin::Modal - Pickrow-->
        <div class="modal fade show " :class="{ block: isShowModalPickRow }">
          </div>
          <div id="kt_drawer_chat" class="bg-body drawer drawer-end " :class="{'drawer-on':isShowModalPickRow}" data-kt-drawer="true" data-kt-drawer-name="chat" data-kt-drawer-activate="true" data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'300px', 'md': '500px'}" data-kt-drawer-direction="end" data-kt-drawer-toggle="#kt_drawer_chat_toggle" data-kt-drawer-close="#kt_drawer_chat_close" style="width: 500px !important;z-index: 50000;">
			<!--begin::Messenger-->
			<div class="card w-100 border-0 rounded-0" id="kt_drawer_chat_messenger">
				<!--begin::Card header-->
				<div class="card-header pe-5" id="kt_drawer_chat_messenger_header" >
					<!--begin::Title-->
					<div class="card-title">
						<!--begin::User-->
						<div class="d-flex justify-content-center flex-column me-3">
							<a href="#" class="fs-4 fw-bold text-gray-900 text-hover-primary me-1 lh-1"> {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.ChooseDisplayColumns }}</a>
							
						</div>
						<!--end::User-->
					</div>
					<!--end::Title-->
					<!--begin::Card toolbar-->
					<div class="card-toolbar">
						<!--begin::Menu-->
						<div class="me-0">
							
							<!--begin::Menu 3-->
							<div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3" data-kt-menu="true">
								<!--begin::Heading-->
								<div class="menu-item px-3">
									<div class="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">Contacts</div>
								</div>
								<!--end::Heading-->
								<!--begin::Menu item-->
								<div class="menu-item px-3">
									<a href="#" class="menu-link px-3" data-bs-toggle="modal" data-bs-target="#kt_modal_users_search">Add Contact</a>
								</div>
								<!--end::Menu item-->
								<!--begin::Menu item-->
								<div class="menu-item px-3">
									<a href="#" class="menu-link flex-stack px-3" data-bs-toggle="modal" data-bs-target="#kt_modal_invite_friends">Invite Contacts 
									<span class="ms-2" data-bs-toggle="tooltip" aria-label="Specify a contact email to send an invitation" data-bs-original-title="Specify a contact email to send an invitation" data-kt-initialized="1">
										<i class="ki-duotone ki-information fs-7">
											<span class="path1"></span>
											<span class="path2"></span>
											<span class="path3"></span>
										</i>
									</span></a>
								</div>
								<!--end::Menu item-->
								<!--begin::Menu item-->
								<div class="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-start">
									<a href="#" class="menu-link px-3">
										<span class="menu-title">Groups</span>
										<span class="menu-arrow"></span>
									</a>
									<!--begin::Menu sub-->
									<div class="menu-sub menu-sub-dropdown w-175px py-4">
										<!--begin::Menu item-->
										<div class="menu-item px-3">
											<a href="#" class="menu-link px-3" data-bs-toggle="tooltip" data-bs-original-title="Coming soon" data-kt-initialized="1">Create Group</a>
										</div>
										<!--end::Menu item-->
										<!--begin::Menu item-->
										<div class="menu-item px-3">
											<a href="#" class="menu-link px-3" data-bs-toggle="tooltip" data-bs-original-title="Coming soon" data-kt-initialized="1">Invite Members</a>
										</div>
										<!--end::Menu item-->
										<!--begin::Menu item-->
										<div class="menu-item px-3">
											<a href="#" class="menu-link px-3" data-bs-toggle="tooltip" data-bs-original-title="Coming soon" data-kt-initialized="1">Settings</a>
										</div>
										<!--end::Menu item-->
									</div>
									<!--end::Menu sub-->
								</div>
								<!--end::Menu item-->
								<!--begin::Menu item-->
								<div class="menu-item px-3 my-1">
									<a href="#" class="menu-link px-3" data-bs-toggle="tooltip" data-bs-original-title="Coming soon" data-kt-initialized="1">Settings</a>
								</div>
								<!--end::Menu item-->
							</div>
							<!--end::Menu 3-->
						</div>
						<!--end::Menu-->
						<!--begin::Close-->
						<div class="btn btn-sm btn-icon btn-active-color-primary" id="kt_drawer_chat_close">
							<i @click="toggleModalDisplayRow" class="ki-duotone ki-cross-square fs-2">
								<span class="path1"></span>
								<span class="path2"></span>
							</i>
						</div>
						<!--end::Close-->
					</div>
					<!--end::Card toolbar-->
				</div>
				<!--end::Card header-->
				<!--begin::Card body-->
        <form action="" @submit.prevent="onSubmitModalPickRow">
				<div class="card-body" id="kt_drawer_chat_messenger_body">
					<!--begin::Messages-->
          
					<div class="scroll-y me-n5 pe-5" data-kt-element="messages" data-kt-scroll="true" data-kt-scroll-activate="true" data-kt-scroll-height="auto" data-kt-scroll-dependencies="#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer" data-kt-scroll-wrappers="#kt_drawer_chat_messenger_body" data-kt-scroll-offset="0px" style="height: calc(100vh - 200px);overflow: scroll;">
         
						<div class="form-check">
              <input v-model="this.checkRow.topupFeeUser" class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
              <label class="form-check-label" for="flexCheckChecked">
                {{ this.$NOLIMITResource[this.dataUser.language].TopupFeePercent }}
              </label>
          </div>
          <div class="form-check">
              <input v-model="this.checkRow.notes" class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
              <label class="form-check-label" for="flexCheckChecked">
                {{ this.$NOLIMITResource[this.dataUser.language].Note }}
              </label>
          </div>
          <div class="form-check">
              <input v-model="this.checkRow.shareStatus" class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
              <label class="form-check-label" for="flexCheckChecked">
                {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.SystemStatus }}
              </label>
          </div>
          <div class="form-check">
              <input v-model="this.checkRow.lastTopupDate" class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
              <label class="form-check-label" for="flexCheckChecked">
                {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.LastTopup }}
              </label>
          </div>
          <div class="form-check">
              <input v-model="this.checkRow.createDate" class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
              <label class="form-check-label" for="flexCheckChecked">
                {{ this.$NOLIMITResource[this.dataUser.language].CreatedDate }}
              </label>
          </div>
          <div class="form-check">
              <input v-model="this.checkRow.spendingCap" class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
              <label class="form-check-label" for="flexCheckChecked">
                {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.SpenCap }}
              </label>
          </div>
          <div class="form-check">
              <input v-model="this.checkRow.remainingAmount" class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
              <label class="form-check-label" for="flexCheckChecked">
                {{ this.$NOLIMITResource[this.dataUser.language].Balance }}
              </label>
          </div>

          <div class="form-check">
              <input v-model="this.checkRow.customerNow" class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
              <label class="form-check-label" for="flexCheckChecked">
                {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.Customer }}
              </label>
          </div> <div class="form-check">
              <input v-model="this.checkRow.supporter" class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
              <label class="form-check-label" for="flexCheckChecked">
                {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.Supporter }}
              </label>
          </div>
          <div class="form-check">
              <input v-model="this.checkRow.returnDate" class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
              <label class="form-check-label" for="flexCheckChecked">
                {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.ReturnDate }}
              </label>
          </div>
          <div class="form-check">
              <input v-model="this.checkRow.paymentMethod" class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
              <label class="form-check-label" for="flexCheckChecked">
                {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.PaymentMethod }}
              </label>
          </div>
          <div class="form-check">
              <input v-model="this.checkRow.timezone" class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
              <label class="form-check-label" for="flexCheckChecked">
                {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.Timezone }}
              </label>
          </div>
          <div class="form-check">
              <input v-model="this.checkRow.currency" class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
              <label class="form-check-label" for="flexCheckChecked">
                {{ this.$NOLIMITResource[this.dataUser.language].Currency }}
              </label>
          </div>
          <div class="form-check">
              <input v-model="this.checkRow.nationallity" class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
              <label class="form-check-label" for="flexCheckChecked">
                {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.Nation }}
              </label>
          </div>
          <div class="form-check">
              <input v-model="this.checkRow.limit" class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
              <label class="form-check-label" for="flexCheckChecked">
                {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.SpentLimit }}
              </label>
          </div>
          <div class="form-check">
              <input v-model="this.checkRow.dailyLimit" class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
              <label class="form-check-label" for="flexCheckChecked">
                {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.DailyLimit }}
              </label>
          </div>
          <div class="form-check">
              <input v-model="this.checkRow.paymentThreshold" class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
              <label class="form-check-label" for="flexCheckChecked">
                {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.PaymentThreshold }}
              </label>
          </div>
          <div class="form-check">
              <input v-model="this.checkRow.remainThreshold" class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
              <label class="form-check-label" for="flexCheckChecked">
                {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.RemainThreshold }}
              </label>
          </div>
          <div class="form-check">
              <input v-model="this.checkRow.invoiceDate" class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
              <label class="form-check-label" for="flexCheckChecked">
                {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.InvoiceDate }}
              </label>
          </div>
          <div class="form-check">
              <input v-model="this.checkRow.remainPaymentDate" class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
              <label class="form-check-label" for="flexCheckChecked">
                {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.RemainPaymentDate }}
              </label>
          </div>
          <div class="form-check">
              <input v-model="this.checkRow.idbusinessOwner" class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
              <label class="form-check-label" for="flexCheckChecked">
                {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.IdbusinessOwner }}
              </label>
          </div>
          <div class="form-check">
              <input v-model="this.checkRow.nameBusinessOwner" class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
              <label class="form-check-label" for="flexCheckChecked">
                {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.NameBusinessOwner }}
              </label>
          </div>


          <div class="form-check">
              <input v-model="this.checkRow.idBusinessPartner" class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
              <label class="form-check-label" for="flexCheckChecked">
                {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.IdBusinessPartner }}
              </label>
          </div>
          <div class="form-check">
              <input v-model="this.checkRow.spendOnDay" class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
              <label class="form-check-label" for="flexCheckChecked">
                {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.SpendOnDay }}
              </label>
          </div>
          <div class="form-check">
              <input v-model="this.checkRow.spendingNow" class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
              <label class="form-check-label" for="flexCheckChecked">
                {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.SpendingNow }}
              </label>
          </div>
          <div class="form-check">
              <input v-model="this.checkRow.totalSpend" class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
              <label class="form-check-label" for="flexCheckChecked">
                {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.TotalSpend }}
              </label>
          </div>
          <div class="form-check">
              <input v-model="this.checkRow.invoiceAmountOnDay" class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
              <label class="form-check-label" for="flexCheckChecked">
                {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.InvoiceAmountOnDay }}
              </label>
          </div>
          <div class="form-check">
              <input v-model="this.checkRow.totalInvoiceAmount" class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
              <label class="form-check-label" for="flexCheckChecked">
                {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.TotalInvoiceAmount }}
              </label>
          </div>
          <div class="form-check">
              <input v-model="this.checkRow.dailyAmountTopUp" class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
              <label class="form-check-label" for="flexCheckChecked">
                {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.DailyAmountTopUp }}
              </label>
          </div>
          <div class="form-check">
              <input v-model="this.checkRow.totalAmountTopUp" class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
              <label class="form-check-label" for="flexCheckChecked">
                {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.TotalAmountTopUp }}
              </label>
          </div>
          <div class="form-check">
              <input v-model="this.checkRow.lastUpdate" class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
              <label class="form-check-label" for="flexCheckChecked">
                {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.LastUpdate }}
              </label>
          </div>
          
					</div>
					<!--end::Messages-->
				</div>
				<!--end::Card body-->
				<!--begin::Card footer-->
				<div class="card-footer pt-4" id="kt_drawer_chat_messenger_footer">
				
					<!--begin:Toolbar-->
					<div class="d-flex flex-stack" style="justify-content: flex-end;">
						
						<!--begin::Send-->
						<button class="btn btn-primary" type="submit" data-kt-element="send">{{ this.$NOLIMITResource[this.dataUser.language].Save }}</button>
						<!--end::Send-->
					</div>
					<!--end::Toolbar-->
				</div>
      </form>	
				<!--end::Card footer-->
			</div>
			<!--end::Messenger-->
    </div> 
		<!--end::Modal - Pickrow-->
      </div>
      <!--end::Content container-->
    </div>
    <!--end::Content-->
  </div>
</template>

<style>
  .table {
    overflow: scroll;
    height: calc(100vh - (33vh)) !important;
    display: block;
  }
.vs__clear{
  display: none !important;
}
.table th{
  height:40px !important;

}
table tr:hover td,table tbody tr:hover td:last-child,table tr:hover td:nth-child(1),table tr:hover td:nth-child(2),table tr:hover td:nth-child(3){
    background-color: #f6fafd !important;
}

.table thead{
  position: sticky;
    top: 0;
    left: 0;
    background: #fff;
}
table tr#total:hover td{
  background-color : #fff !important;
}


.vs__dropdown-toggle{
    border:none;
    min-width: 80px;
  }
</style>

  <style scoped>
  .table tr#total{
  position: sticky;
    bottom: 0px;
    left: 0;
    background: #fff;
    z-index: 4;
}
.table tr#total{
  position: sticky;
}
.table td{
  padding:10px;
  display:table-cell
  /* min-width: 200px; */
}
  /* .all-payment{
    visibility: visible;
    position: absolute;
    width: 150px;
    top:0px;
    left: 0px;
    min-height: 60px;
    background: #fff;
    padding: 15px;
  }
  table tr:hover td#payment:hover + .all-payment{
    visibility: visible;

  } */
    th:nth-child(1):hover div::after{
    display: none;
  }
  .fix-data-footer{
    position: sticky;
    left:0px;
    background: #fff;
    z-index: 10;
  }
    .fix-data-0{
    position: sticky;
    left:0px;
    top:0px;
    background: #fff;
  }
  .fix-data-1{
    position: sticky;
    left:61px;
    top:0px;
    background: #fff;
    min-width:120px !important;
  }
  .fix-data-2{
    position: sticky;
    left:181px;
    top:0px;
    background: #fff;
    min-width:150px !important;
  }
  body{
      overflow: hidden;
    }
  .vs__dropdown-toggle{
    border:none;
  }

  .form-check{
    display: flex;
    /* min-height: 1.5rem; */
    height: 30px;
    padding-left: 2.25rem;
    margin-bottom: 0.125rem;
    align-items: center;
  }
  .form-check-input{
    margin-right: 10px;
  }
  .min-w-125px{
    min-width:200px !important
  }
  
  .table thead th:last-child{
    height: 60px !important;
    align-items: end;
    top: 20px; 
    align-items: flex-start;
  }      
  .active__update__combobox {
    z-index: 5 !important;
  }
  .menu {
    left: -20px;
    top: 53px;
    position: relative;
  }
  td {
    text-align: start;
  }
  .menu-sub-dropdown {
    position: absolute;
  }
  .block {
    display: block;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .loading-1 {
    height: 500px;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 50px;
  }
  #styleSelect{
  background: #f9f9f9 !important;
  border:none !important;
  border-radius: 5px;
}
.v-select .vs__dropdown-toggle{
  border:none !important;
  border-radius: 5px;
  height: 40px;
  color: #4b5675;
}
#vs2__combobox{
  border:none !important;
  height: 40px;
  border-radius: 5px;
  color: #4b5675;
}

.container-xxl{
  max-width: 1200px;
}

.show-dropfilter{
  z-index: 107; position: absolute; inset: 0px 0px auto auto; margin: 0px;
  top: 70px;
  right: 176px;
  text-align: start;
}
.show-dropfilter-2{
  z-index: 107; position: absolute; inset: 0px 0px auto auto; margin: 0px;
  top: 130px;
  left: 20px;
  text-align: start;
}
.calendar{
  display: none;
}
.style-chooser.vs__dropdown-menu {
    border: none;
  }
  .style-chooser.vs__dropdown-menu .vs__actions .vs__clear{
    display:none
  }
.vs__dropdown-toggle{
  width: 80px;
}
table td:first-child,table th:first-child{
  min-width: 40px;
  padding-left: 18px !important;
}
#selected__toolbar{
    margin-right: 16px;
}
#selected__toolbar span{
    font-weight: 700;
}
#unchecked__toolbar{
    color: #E61D1D;
    margin-right: 16px;
    cursor: pointer;
}
#selectedall__toolbar{
    color: #1565C0;
    font-weight: 600;
    margin-right: 25px;
    cursor: pointer;
}
.content__toolbar--table{
  align-items: center;
    display: flex;
}
th {
  padding-top: 15px !important;
  vertical-align: top;
  padding-bottom: 0 !important;
}
  </style>
<script>
import {mapGetters,mapMutations,mapActions } from "vuex";
import router from '@/router/router.js';
export default {
  name: "ListAds",
  data() {
    return {
      dataUser:{language:"VN"},
      isShowModalUpdateFeeTopupAll:false,
      isShowModalUpdateTopup:false,
      currencyConvert:{
        value:0
      },
      totalBalance:0,
      totalSpencap:0,
      totalLimit:0,
      payments:[],
      listMediateAdUserByAdsId:[],
      isShowModalReturnAds:false,
      currentIndex: null,
      currentProperty: "",
      modeSort:"",
      sortingAsc: null,
      sortingNumber: null,

      totalStatusAdsPayRequired: 0,
      totalStatusAdsDisable: 0,
      totalStatusAdsUsing: 0,
      statusAds:"",
      onShowMenuFilterStatus:false,
      isResizing: false,
      initialX: 0,
      initialWidth: 0,
      resizingCol: null,
      columns: [
      { label: 'Id', width: 200, property: "AdsId"   },
          { label: this.$NOLIMITResource["VN"].TopupFee, width: 200, property: "TopupFee"   },
          { label: this.$NOLIMITResource["VN"].Note, width: 200, property: "Notes"   },
          
          { label: this.$NOLIMITResource["VN"].AdsAccount.SystemStatus, width: 200, property: "ShareStatus"   },
          { label: this.$NOLIMITResource["VN"].AdsAccount.LastTopup, width: 200, property: "LastTopupDate"   },
          { label: this.$NOLIMITResource["VN"].CreatedDate, width: 200, property: "CreateDate"   },
          { label: this.$NOLIMITResource["VN"].AdsAccount.SpenCap, width: 200, property: "SpendingCap"   },
          { label: this.$NOLIMITResource["VN"].Balance, width: 200, property: "RemainingAmount"   },
          { label: this.$NOLIMITResource["VN"].AdsAccount.Customer, width: 200, property: "CustomerNow"   },
          { label: this.$NOLIMITResource["VN"].AdsAccount.Supporter, width: 200, property: "Supporter"   },
          { label: this.$NOLIMITResource["VN"].AdsAccount.ReturnDate, width: 200, property: "ReturnDate"   },
          { label: this.$NOLIMITResource["VN"].AdsAccount.PaymentMethod, width: 200, property: "PaymentMethod"   },
          { label: this.$NOLIMITResource["VN"].AdsAccount.Timezone, width: 200, property: "Timezone"   },
          { label: this.$NOLIMITResource["VN"].Currency, width: 200, property: "Currency"   },
          { label: this.$NOLIMITResource["VN"].AdsAccount.Nation, width: 200, property: "Nationallity"   },
          { label: this.$NOLIMITResource["VN"].AdsAccount.SpentLimit, width: 200, property: "Limit"   },
          { label: this.$NOLIMITResource["VN"].AdsAccount.DailyLimit, width: 200, property: "DailyLimit"   },
          { label: this.$NOLIMITResource["VN"].AdsAccount.PaymentThreshold, width: 200, property: "PaymentThreshold"   },
          { label: this.$NOLIMITResource["VN"].AdsAccount.RemainThreshold, width: 200, property: "RemainThreshold"   },
          { label: this.$NOLIMITResource["VN"].AdsAccount.InvoiceDate, width: 200, property: "InvoiceDate"   },
          { label: this.$NOLIMITResource["VN"].AdsAccount.RemainPaymentDate, width: 200, property: "RemainPaymentDate"   },
          { label: this.$NOLIMITResource["VN"].AdsAccount.IdbusinessOwner, width: 200, property: "IdbusinessOwner"   },
          { label: this.$NOLIMITResource["VN"].AdsAccount.NameBusinessOwner, width: 200, property: "NameBusinessOwner"   },
          { label: this.$NOLIMITResource["VN"].AdsAccount.IdBusinessPartner, width: 200, property: "IdBusinessPartner"   },
          { label: this.$NOLIMITResource["VN"].AdsAccount.SpendOnDay, width: 200, property: "SpendOnDay"   },
          { label: this.$NOLIMITResource["VN"].AdsAccount.SpendingNow, width: 200, property: "SpendingNow"   },
          { label: this.$NOLIMITResource["VN"].AdsAccount.TotalSpend, width: 200, property: "TotalSpend"   },
          { label: this.$NOLIMITResource["VN"].AdsAccount.InvoiceAmountOnDay, width: 200, property: "InvoiceAmountOnDay"   },
          { label: this.$NOLIMITResource["VN"].AdsAccount.TotalInvoiceAmount, width: 200, property: "TotalInvoiceAmount"   },
          { label: this.$NOLIMITResource["VN"].AdsAccount.DailyAmountTopUp, width: 200, property: "DailyAmountTopUp"   },
          { label: this.$NOLIMITResource["VN"].AdsAccount.TotalAmountTopUp, width: 200, property: "TotalAmountTopUp"   },
          { label: this.$NOLIMITResource["VN"].AdsAccount.LastUpdate, width: 200, property: "LastUpdate"   },
      ],

      idUser:0,
      isCheckedAll: false,
      totalStatusUsing:0,
      totalStatusReturn:0,
      totalStatusNotUse:0,
      totalStatusWaittingReturn:0,
      userIdProvide:null,
      isShowModalProvideAllAds: false,
      selectRecord: false,
      recordActiveId:0,
      countSelected:0,
      onSelectAllRecord:false,
      checkboxChecked:[],
      onShowMenuFilter:false,
      onShowCalendarStart:false,
      numberPerPage:[10,50,100,500],
      status:"",
      dateStart: null,
      dateEnd: null,
      timezoneIndex: 20,
      timezoneIndex1: 1,
      timezones: [
          'Pacific/Midway',      // -11
          'Pacific/Niue',        // -11
          'Pacific/Honolulu',    // -10
          'Pacific/Pago_Pago',   // -11
          'America/Anchorage',   // -9
          'America/Los_Angeles', // -8
          'America/Tijuana',     // -8
          'America/Denver',      // -7
          'America/Phoenix',     // -7
          'America/Chicago',      // -6
          'America/Mexico_City', // -6
          'America/Regina',      // -6
          'America/New_York',    // -5
          'America/Caracas',     // -4.5
          'America/Halifax',     // -4
          'America/Argentina/Buenos_Aires', // -3
          'America/Sao_Paulo',   // -3
          'Atlantic/Azores',     // -1
          'Atlantic/Cape_Verde', // -1
          'UTC',                 // 0
          'Europe/London',       // +0
          'Europe/Paris',        // +1
          'Europe/Istanbul',     // +3
          'Europe/Moscow',       // +3
          'Asia/Dubai',          // +4
          'Asia/Yekaterinburg',  // +5
          'Asia/Almaty',         // +6
          'Asia/Colombo',        // +6
          'Asia/Bangkok',        // +7
          'Asia/Ho_Chi_Minh',    // +7
          'Asia/Jakarta',        // +7
          'Asia/Taipei',         // +8
          'Asia/Singapore',      // +8
          'Asia/Kuala_Lumpur',   // +8
          'Asia/Hong_Kong',      // +8
          'Asia/Ulaanbaatar',    // +8
          'Asia/Pyongyang',      // +9
          'Asia/Tokyo',          // +9
          'Australia/Darwin',    // +9.5
          'Australia/Adelaide',  // +10
          'Australia/Sydney',    // +10
          'Pacific/Guam',        // +10
          'Australia/Brisbane',  // +10
          'Asia/Vladivostok',    // +10
          'Pacific/Fiji',        // +12
          'Pacific/Tongatapu',   // +13
          'Pacific/Apia',        // +13
        ],
      onLoading: true,
      ads: [],
      adsAll:[],
      onShowMenu: false,
      onShowMenuAdd:false,
      isShowModalAddTopup: false,
      isShowModalImportManyAds: false,
      currenOnshowId: 0,
      adsCurrent: {},
      adsAdd:{},
      isShowModalAddAds: false,
      currentBalanceUser: 0,
      currentTotalMoneyUser: 0,
      isShowModalUpdateAds: false,
      changeMoney: 0,
      formMode: "",
      pageSize: 10,
      pageNumber: 1,
      totalPage: 0,
      firstPage: 0,
      inputValue: "",
      isAdmin:true,
      isSupport:true,
      hasData:true,
      listStatus:['Đang sử dụng','Đã thu hồi','Chưa sử dụng'],
      adsImport:'',
      statusUpdateAds:"",
      errors:{
				userId: "",
			},	
      isValidate:false,
      isValidateAds : false,
      errorsAds:{
        facebookAdsId:"",
        notes:"",
        name:"",
        currency:"",
        timezone:"",
        nationallity:"",
        limit:"",
      },
      timezone:{},
      listTimezone:[
      { key: "Etc/GMT+12", value: "(GMT-12:00) International Date Line West" },
      { key: "Pacific/Midway", value: "(GMT-11:00) Midway Island, Samoa" },
      { key: "Pacific/Honolulu", value: "(GMT-10:00) Hawaii" },
      { key: "US/Alaska", value: "(GMT-09:00) Alaska" },
      { key: "America/Los_Angeles", value: "(GMT-08:00) Pacific Time (US & Canada)" },
      { key: "US/Arizona", value: "(GMT-07:00) Arizona" },
      { key: "America/Managua", value: "(GMT-06:00) Central America" },
      { key: "US/Central", value: "(GMT-06:00) Central Time (US & Canada)" },
      { key: "America/Bogota", value: "(GMT-05:00) Bogota, Lima, Quito, Rio Branco" },
      { key: "US/Eastern", value: "(GMT-05:00) Eastern Time (US & Canada)" },
      { key: "Canada/Atlantic", value: "(GMT-04:00) Atlantic Time (Canada)" },
      { key: "America/Argentina/Buenos_Aires", value: "(GMT-03:00) Buenos Aires, Georgetown" },
      { key: "America/Noronha", value: "(GMT-02:00) Mid-Atlantic" },
      { key: "Atlantic/Azores", value: "(GMT-01:00) Azores" },
      { key: "Etc/Greenwich", value: "(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London" },
      { key: "Europe/Amsterdam", value: "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna" },
      { key: "Europe/Helsinki", value: "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius" },
      { key: "Europe/Moscow", value: "(GMT+03:00) Moscow, St. Petersburg, Volgograd" },
      { key: "Asia/Tehran", value: "(GMT+03:30) Tehran" },
      { key: "Asia/Yerevan", value: "(GMT+04:00) Yerevan" },
      { key: "Asia/Kabul", value: "(GMT+04:30) Kabul" },
      { key: "Asia/Yekaterinburg", value: "(GMT+05:00) Yekaterinburg" },
      { key: "Asia/Karachi", value: "(GMT+05:00) Islamabad, Karachi, Tashkent" },
      { key: "Asia/Calcutta", value: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi" },
      { key: "Asia/Katmandu", value: "(GMT+05:45) Kathmandu" },
      { key: "Asia/Dhaka", value: "(GMT+06:00) Astana, Dhaka" },
      { key: "Asia/Rangoon", value: "(GMT+06:30) Yangon (Rangoon)" },
      { key: "Asia/Bangkok", value: "(GMT+07:00) Bangkok, Hanoi, Jakarta" },
      { key: "Asia/Hong_Kong", value: "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi" },
      { key: "Asia/Seoul", value: "(GMT+09:00) Seoul" },
      { key: "Australia/Adelaide", value: "(GMT+09:30) Adelaide" },
      { key: "Australia/Canberra", value: "(GMT+10:00) Canberra, Melbourne, Sydney" },
      { key: "Asia/Magadan", value: "(GMT+11:00) Magadan, Solomon Is., New Caledonia" },
      { key: "Pacific/Auckland", value: "(GMT+12:00) Auckland, Wellington" },
      { key: "Pacific/Tongatapu", value: "(GMT+13:00) Nuku'alofa" },
    ],
    currency:['VND','USD'],
    listNationality:[
    'Afghan',
        'Albanian',
        'Algerian',
        'American',
        'Andorran',
        'Angolan',
        'Antiguans',
        'Argentinean',
        'Armenian',
        'Australian',
        'Austrian',
        'Azerbaijani',
        'Bahamian',
        'Bahraini',
        'Bangladeshi',
        'Barbadian',
        'Barbudans',
        'Batswana',
        'Belarusian',
        'Belgian',
        'Belizean',
        'Beninese',
        'Bhutanese',
        'Bolivian',
        'Bosnian',
        'Brazilian',
        'British',
        'Bruneian',
        'Bulgarian',
        'Burkinabe',
        'Burmese',
        'Burundian',
        'Cambodian',
        'Cameroonian',
        'Canadian',
        'Cape Verdean',
        'Central African',
        'Chadian',
        'Chilean',
        'Chinese',
        'Colombian',
        'Comoran',
        'Congolese',
        'Costa Rican',
        'Croatian',
        'Cuban',
        'Cypriot',
        'Czech',
        'Danish',
        'Djibouti',
        'Dominican',
        'Dutch',
        'East Timorese',
        'Ecuadorean',
        'Egyptian',
        'Emirian',
        'Equatorial Guinean',
        'Eritrean',
        'Estonian',
        'Ethiopian',
        'Fijian',
        'Filipino',
        'Finnish',
        'French',
        'Gabonese',
        'Gambian',
        'Georgian',
        'German',
        'Ghanaian',
        'Greek',
        'Grenadian',
        'Guatemalan',
        'Guinea-Bissauan',
        'Guinean',
        'Guyanese',
        'Haitian',
        'Herzegovinian',
        'Honduran',
        'Hungarian',
        'I-Kiribati',
        'Icelander',
        'Indian',
        'Indonesian',
        'Iranian',
        'Iraqi',
        'Irish',
        'Israeli',
        'Italian',
        'Ivorian',
        'Jamaican',
        'Japanese',
        'Jordanian',
        'Kazakhstani',
        'Kenyan',
        'Kittian and Nevisian',
        'Kuwaiti',
        'Kyrgyz',
        'Laotian',
        'Latvian',
        'Lebanese',
        'Liberian',
        'Libyan',
        'Liechtensteiner',
        'Lithuanian',
        'Luxembourger',
        'Macedonian',
        'Malagasy',
        'Malawian',
        'Malaysian',
        'Maldivan',
        'Malian',
        'Maltese',
        'Marshallese',
        'Mauritanian',
        'Mauritian',
        'Mexican',
        'Micronesian',
        'Moldovan',
        'Monacan',
        'Mongolian',
        'Moroccan',
        'Mosotho',
        'Motswana',
        'Mozambican',
        'Namibian',
        'Nauruan',
        'Nepali',
        'New Zealander',
        'Nicaraguan',
        'Nigerian',
        'Nigerien',
        'North Korean',
        'Northern Irish',
        'Norwegian',
        'Omani',
        'Pakistani',
        'Palauan',
        'Panamanian',
        'Papua New Guinean',
        'Paraguayan',
        'Peruvian',
        'Polish',
        'Portuguese',
        'Qatari',
        'Romanian',
        'Russian',
        'Rwandan',
        'Saint Lucian',
        'Salvadoran',
        'Samoan',
        'San Marinese',
        'Sao Tomean',
        'Saudi',
        'Scottish',
        'Senegalese',
        'Serbian',
        'Seychellois',
        'Sierra Leonean',
        'Singaporean',
        'Slovakian',
        'Slovenian',
        'Solomon Islander',
        'Somali',
        'South African',
        'South Korean',
        'Spanish',
        'Sri Lankan',
        'Sudanese',
        'Surinamer',
        'Swazi',
        'Swedish',
        'Swiss',
        'Syrian',
        'Taiwanese',
        'Tajik',
        'Tanzanian',
        'Thai',
        'Togolese',
        'Tongan',
        'Trinidadian/Tobagonian',
        'Tunisian',
        'Turkish',
        'Tuvaluan',
        'Ugandan',
        'Ukrainian',
        'Uruguayan',
        'Uzbekistani',
        'Venezuelan',
        'Vietnamese',
        'Welsh',
        'Yemenite',
        'Zambian',
        'Zimbabwean'
    ]
    };
  },
  mounted() {
    this.$watch(
      () => this.$refs.source.offsetHeight,
      () => {
        const sourceHeight = this.$refs.source.offsetHeight;
        this.$refs.target.style.height = sourceHeight + 'px';
      }
    );
  },

  created() {
    this.$apiService
      .getUserProfile()
      .then((response) => {
        if(response.data.data.language == "VN" || response.data.data.language == "EN"){
          this.dataUser = response.data.data;
      }else{
          this.dataUser = response.data.data;
          this.dataUser.language = "VN";
      }
        this.idUser = response.data.data.userId;
        this.isAdmin = response.data.data.isAdmin;
        this.isSupport = response.data.data.isSupport;
        if(response.data.data.isAdmin != true && response.data.data.isSupport != true){
                this.isAdmin=false;
                this.isSupport=false;
                router.push({ name: 'ErrorNotFound' });
            }
            if(this.idAds){
              this.$apiService.getAdsById(this.idAds).then((res)=>{
                setTimeout(() => {
                      this.onLoading = false;
                    }, 1000);
                    this.ads[0] = res.data.data;
                    if(res.data.data.length == 0){
                        this.hasData = false;
                      }
                    this.totalPage = res.data.pageData.pageCount;
              }).catch((error) => {
                    console.error("Lỗi khi lấy dữ liệu:", error);
                  });
            }else{
              this.$apiService
                  .getAllAdsByAdmin(this.pageNumber, this.pageSize)
                  .then((res) => {
                    setTimeout(() => {
                      this.onLoading = false;
                    }, 1000);
                    this.ads = res.data.data;
                    if(res.data.data.length == 0){
                        this.hasData = false;
                      }
                    this.totalPage = res.data.pageData.pageCount;
                  })
                  .catch((error) => {
                    console.error("Lỗi khi lấy dữ liệu:", error);
                  });
            }
        
          this.$apiService.getDisplayPropertiesByUserId(response.data.data.userId)
          .then((res)=>{
            this.setDisPlayRow(res.data);
          })
          this.$apiService
              .getAllAdsByAdminNoPaging()
              .then((res) => {
                for(let i = 0; i<res.data.data.length;i++){
                    if(res.data.data[i].shareStatus == "Đang sử dụng"){
                      this.totalStatusUsing +=1;
                    }else if(res.data.data[i].shareStatus == "Đã thu hồi"){
                      this.totalStatusReturn +=1;
                    } else if (res.data.data[i].shareStatus == "Chờ thu hồi") {
                      this.totalStatusWaittingReturn += 1;
                    } else{
                      this.totalStatusNotUse +=1;
                    }

                    if(res.data.data[i].adsStatus == "1"){
                      this.totalStatusAdsUsing +=1;
                    }else if(res.data.data[i].statusAds == "2"){
                      this.totalStatusAdsDisable +=1;
                    }
                    else if(res.data.data[i].adsStatus == "3"){
                      this.totalStatusAdsPayRequired +=1;
                    }
                    
                }

                
              this.adsAll = res.data.data; 
              })

              this.$apiService.getSettingById(4)
              .then((res) =>{
                  this.currencyConvert.value = res.data.value
              })  
    })
      .catch((error) => {
        console.error("Lỗi khi lấy dữ liệu:", error);
      });

  },
  props:['idAds'],
  computed:{
    ...mapGetters("listModule", [
      "checkRow",
      "displayRow",
      "isShowModalPickRow",
      "formatCurrency",
      "formatDateTime",
      "convertToEndOfDay",
    ]),
    timezone2() {
      return this.timezones[this.timezoneIndex];
    },
    timezone1() {
      return this.timezones[this.timezoneIndex1];
    },
    attributes() {
      return[ {
        highlight: true,
        dates: this.dateStart,
      }];
    },
  },
  watch: {
    dataUser:{
      handler: function() {
        this.columns = [ 
        { label: 'Id', width: 200, property: "AdsId"   },
         { label: this.$NOLIMITResource[this.dataUser.language].TopupFee, width: 200, property: "TopupFee"   },
         { label: this.$NOLIMITResource[this.dataUser.language].Note, width: 200, property: "Notes"   },
    
         { label: this.$NOLIMITResource[this.dataUser.language].AdsAccount.SystemStatus, width: 200, property: "ShareStatus"   },
         { label: this.$NOLIMITResource[this.dataUser.language].AdsAccount.LastTopup, width: 200, property: "LastTopupDate"   },
         { label: this.$NOLIMITResource[this.dataUser.language].CreatedDate, width: 200, property: "CreateDate"   },
         { label: this.$NOLIMITResource[this.dataUser.language].AdsAccount.SpenCap, width: 200, property: "SpendingCap"   },
         { label: this.$NOLIMITResource[this.dataUser.language].Balance, width: 200, property: "RemainingAmount"   },
         { label: this.$NOLIMITResource[this.dataUser.language].AdsAccount.Customer, width: 200, property: "CustomerNow"   },
         { label: this.$NOLIMITResource[this.dataUser.language].AdsAccount.Supporter, width: 200, property: "Supporter"   },
         { label: this.$NOLIMITResource[this.dataUser.language].AdsAccount.ReturnDate, width: 200, property: "ReturnDate"   },
         { label: this.$NOLIMITResource[this.dataUser.language].AdsAccount.PaymentMethod, width: 200, property: "PaymentMethod"   },
         { label: this.$NOLIMITResource[this.dataUser.language].AdsAccount.Timezone, width: 200, property: "Timezone"   },
         { label: this.$NOLIMITResource[this.dataUser.language].Currency, width: 200, property: "Currency"   },
         { label: this.$NOLIMITResource[this.dataUser.language].AdsAccount.Nation, width: 200, property: "Nationallity"   },
         { label: this.$NOLIMITResource[this.dataUser.language].AdsAccount.SpentLimit, width: 200, property: "Limit"   },
         { label: this.$NOLIMITResource[this.dataUser.language].AdsAccount.DailyLimit, width: 200, property: "DailyLimit"   },
         { label: this.$NOLIMITResource[this.dataUser.language].AdsAccount.PaymentThreshold, width: 200, property: "PaymentThreshold"   },
         { label: this.$NOLIMITResource[this.dataUser.language].AdsAccount.RemainThreshold, width: 200, property: "RemainThreshold"   },
         { label: this.$NOLIMITResource[this.dataUser.language].AdsAccount.InvoiceDate, width: 200, property: "InvoiceDate"   },
         { label: this.$NOLIMITResource[this.dataUser.language].AdsAccount.RemainPaymentDate, width: 200, property: "RemainPaymentDate"   },
         { label: this.$NOLIMITResource[this.dataUser.language].AdsAccount.IdbusinessOwner, width: 200, property: "IdbusinessOwner"   },
         { label: this.$NOLIMITResource[this.dataUser.language].AdsAccount.NameBusinessOwner, width: 200, property: "NameBusinessOwner"   },
         { label: this.$NOLIMITResource[this.dataUser.language].AdsAccount.IdBusinessPartner, width: 200, property: "IdBusinessPartner"   },
         { label: this.$NOLIMITResource[this.dataUser.language].AdsAccount.SpendOnDay, width: 200, property: "SpendOnDay"   },
         { label: this.$NOLIMITResource[this.dataUser.language].AdsAccount.SpendingNow, width: 200, property: "SpendingNow"   },
         { label: this.$NOLIMITResource[this.dataUser.language].AdsAccount.TotalSpend, width: 200, property: "TotalSpend"   },
         { label: this.$NOLIMITResource[this.dataUser.language].AdsAccount.InvoiceAmountOnDay, width: 200, property: "InvoiceAmountOnDay"   },
         { label: this.$NOLIMITResource[this.dataUser.language].AdsAccount.TotalInvoiceAmount, width: 200, property: "TotalInvoiceAmount"   },
         { label: this.$NOLIMITResource[this.dataUser.language].AdsAccount.DailyAmountTopUp, width: 200, property: "DailyAmountTopUp"   },
         { label: this.$NOLIMITResource[this.dataUser.language].AdsAccount.TotalAmountTopUp, width: 200, property: "TotalAmountTopUp"   },
         { label: this.$NOLIMITResource[this.dataUser.language].AdsAccount.LastUpdate, width: 200, property: "LastUpdate"   },
     ]
      }
    },
    pageSize() {
      this.reDisplay();
    },
    idAds: function() {
      location.reload();
    },
    ads(){
      this.totalBalance = 0;
      this.totalSpencap = 0;
      this.totalLimit = 0
      for(let i = 0; i<this.ads.length;i++){
        if(this.ads[i].currency == "VND"){
          this.totalBalance += this.ads[i].remainingAmount
          this.totalSpencap += this.ads[i].spendingCap
          this.totalLimit += this.ads[i].limit
        }else if(this.ads[i].currency == "USD"){
          this.totalBalance += (this.ads[i].remainingAmount * this.currencyConvert.value)
          this.totalSpencap += (this.ads[i].spendingCap * this.currencyConvert.value)
          this.totalLimit += (this.ads[i].limit * this.currencyConvert.value)
        }
      }
      

    }
  },
  updated()
    {
      if(this.onSelectAllRecord){
          this.countSelected = this.adsAll.length;
      }else{
          this.countSelected = document.querySelectorAll('.count__record').length;
      }
    },
  methods: {
    ...mapMutations("listModule", ["setDisPlayRow"]),
    ...mapActions("listModule", ["toggleModalDisplayRow","swalSuccess","swalError","swalWarning"]),
    validate()
		{
			this.isValidate = true;
			this.errors={
				userId: "",
			};
			if(this.adsCurrent.userId == null || this.adsCurrent.userId == ''){
				this.errors.userId = this.$NOLIMITResource[this.dataUser.language].AdsAccount.ErrorValidateUserIdEmpty
				this.isValidate = false;
			}else if(!this.validUserId(this.adsCurrent.userId)){
				this.errors.userId = this.$NOLIMITResource[this.dataUser.language].AdsAccount.ErrorValidateUserId
				this.isValidate = false;
			}
			return this.isValidate;
		},
    validateProvideMany()
		{
			this.isValidate = true;
			this.errors={
				userId: "",
			};
			if(this.userIdProvide == null || this.userIdProvide == ''){
				this.errors.userId = this.$NOLIMITResource[this.dataUser.language].AdsAccount.ErrorValidateUserIdEmpty
				this.isValidate = false;
			}else if(!this.validUserId(this.userIdProvide)){
				this.errors.userId = this.$NOLIMITResource[this.dataUser.language].AdsAccount.ErrorValidateUserId
				this.isValidate = false;
			}
			return this.isValidate;
		},
    validUserId(userId){
      var re = /^\d+$/;
			return re.test(userId);
    },
    validateAds(){
      this.isValidateAds = true;
      this.errorsAds={
        facebookAdsId:"",
        notes:"",
        name:"",
        currency:"",
        timezone:"",
        nationallity:"",
        limit:"",
      }
      if(this.adsAdd.facebookAdsId == null || this.adsAdd.facebookAdsId == ''){
        this.errorsAds.facebookAdsId = this.$NOLIMITResource[this.dataUser.language].AdsAccount.ErrorValidateFacebookAdsIdIdEmpty
        this.isValidateAds = false;
      }else if(!this.validFacebookAdsIdAds(this.adsAdd.facebookAdsId)){
				this.errorsAds.facebookAdsId = this.$NOLIMITResource[this.dataUser.language].AdsAccount.ErrorValidateFacebookAdsId
				this.isValidateAds = false;
			}
     
      return this.isValidateAds;
    },
    validateUpdateAds(){
      if(this.formMode == 'Sửa thông tin'){

     
      this.isValidateAds = true;
      this.errorsAds={
        facebookAdsId:"",
        notes:"",
        name:"",
        currency:"",
        timezone:"",
        nationallity:"",
        limit:"",
      }
      if(this.adsCurrent.facebookAdsId == null || this.adsCurrent.facebookAdsId == ''){
        this.errorsAds.facebookAdsId = this.$NOLIMITResource[this.dataUser.language].AdsAccount.ErrorValidateFacebookAdsIdIdEmpty
        this.isValidateAds = false;
      }else if(!this.validFacebookAdsIdAds(this.adsCurrent.facebookAdsId)){
				this.errorsAds.facebookAdsId = this.$NOLIMITResource[this.dataUser.language].AdsAccount.ErrorValidateFacebookAdsId
				this.isValidateAds = false;
			}
     
    }else{
      this.isValidateAds = true;
    }
      return this.isValidateAds;
    },
    validFacebookAdsIdAds(facebookAdsId){
      var re = /^\d{1,16}$/;
			return re.test(facebookAdsId);
    },
    openModalUpdateFeeTopupAll(){
      this.isShowModalUpdateFeeTopupAll = true;
    },
    closeModalUpdateFeeTopupAll(){
      this.isShowModalUpdateFeeTopupAll = false;
    },
    openModalProvideAll() {
      this.errors={
				userId: "",
			};
      this.isShowModalProvideAllAds = true;
    },	

    closeModalProvideAll() {
      this.errors={
				userId: "",
			};
      this.isShowModalProvideAllAds = false;
    },
    provideManyAds(){

      if(this.validateProvideMany()){
      this.$apiService.provideAccountManyAds(this.checkboxChecked,this.userIdProvide)
      .then(()=>{
        this.isShowModalProvideAllAds = false;
        this.reDisplay();
          this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].AdsAccount.SuccessGrantAccountsToUsers)          
      }).catch((error) => {
        this.isShowModalProvideAllAds = false;
          this.swalError({message:error.response.data.message, language: this.dataUser.language})
        });
      }
    },
    returnManyAds(){
      this.$apiService.returnManyAds(this.checkboxChecked)
      .then(()=>{
        this.reDisplay();
          this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].AdsAccount.SuccessRecallAccounts)
      }).catch((error) => {
          this.swalError({message:error.response.data.message, language: this.dataUser.language})
        });
    },
    
    openModalAddAds() {
      this.errorsAds={
        facebookAdsId:"",
        notes:"",
        name:"",
        currency:"",
        timezone:"",
        nationallity:"",
        limit:"",
      }
      this.isShowModalAddAds = true;
    },
    closeModalAddAds() {
      this.isShowModalAddAds = false;
      this.onShowMenuAdd = false;
    },
    onFilterSearch(){
        this.hasData = true;
        if((this.dateStart == null || this.dateEnd == null || this.dateEnd < this.dateStart) && this.status == "" && this.inputValue == ""){
          this.reDisplay();
        }else if(this.dateStart == null || this.dateEnd == null || this.dateEnd < this.dateStart){
          this.$apiService.getAllAdsByAdminFilterStatus(
            this.pageNumber,
            this.pageSize,
            this.status,
            this.inputValue,
            this.currentProperty,
            this.modeSort,
          ).then((res)=>{
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.ads = res.data.data;
            if(res.data.data.length == 0){
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          this.$apiService.getAllAdsByAdminFilterStatusNoPaging(
            this.status,
            this.inputValue,
          ).then((res)=>{
              this.adsAll = res.data.data;
            })
        }else if(this.status == ""){
          const dateEndConvert = this.convertToEndOfDay(this.dateEnd)
          this.$apiService.getAllAdsByAdminFilter(
            this.pageNumber,
            this.pageSize,
            "",
            this.dateStart.toISOString(),
            dateEndConvert,
            this.inputValue,
            this.currentProperty,
            this.modeSort,
          ).then((res)=>{
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.ads = res.data.data;
            if(res.data.data.length == 0){
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          this.$apiService.getAllAdsByAdminFilterNoPaging(
            this.status,
            this.dateStart.toISOString(),
            dateEndConvert,
            this.inputValue,).then((res)=>{
              this.adsAll = res.data.data;
            })
        }else if(this.inputValue == ""){
          const dateEndConvert = this.convertToEndOfDay(this.dateEnd)
          this.$apiService.getAllAdsByAdminFilter(
            this.pageNumber,
            this.pageSize,
            this.status,
            this.dateStart.toISOString(),
            dateEndConvert,
            "",
            this.currentProperty,
            this.modeSort,
          ).then((res)=>{
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.ads = res.data.data;
            if(res.data.data.length == 0){
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          this.$apiService.getAllAdsByAdminFilterNoPaging(
            this.status,
            this.dateStart.toISOString(),
            dateEndConvert,
            this.inputValue,
          ).then((res)=>{
              this.adsAll = res.data.data;
            })
        }else{
          const dateEndConvert = this.convertToEndOfDay(this.dateEnd)
          this.$apiService.getAllAdsByAdminFilter(
            this.pageNumber,
            this.pageSize,
            this.status,
            this.dateStart.toISOString(),
            dateEndConvert,
            this.inputValue,
            this.currentProperty,
            this.modeSort,
          ).then((res)=>{
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.ads = res.data.data;
            if(res.data.data.length == 0){
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          this.$apiService.getAllAdsByAdminFilterNoPaging(
            this.status,
            this.dateStart.toISOString(),
            dateEndConvert,
            this.inputValue,).then((res)=>{
              this.adsAll = res.data.data;
            })
        }
        this.onShowMenuFilter=false;
      },

      onFilterSearchFirst(){
        this.hasData = true;
        if((this.dateStart == null || this.dateEnd == null || this.dateEnd < this.dateStart) && this.status == "" && this.inputValue == ""){
          this.reDisplay();
        }else{
          this.pageNumber = 1;
          this.firstPage = 0;
          if(this.dateStart == null || this.dateEnd == null || this.dateEnd < this.dateStart){
          this.$apiService.getAllAdsByAdminFilterStatus(
            this.pageNumber,
            this.pageSize,
            this.status,
            this.inputValue,
            this.currentProperty,
            this.modeSort,
          ).then((res)=>{
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.ads = res.data.data;
            if(res.data.data.length == 0){
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          this.$apiService.getAllAdsByAdminFilterStatusNoPaging(
            this.status,
            this.inputValue,
          ).then((res)=>{
              this.adsAll = res.data.data;
            })
        }else if(this.status == ""){
          const dateEndConvert = this.convertToEndOfDay(this.dateEnd)
          this.$apiService.getAllAdsByAdminFilter(
            this.pageNumber,
            this.pageSize,
            "",
            this.dateStart.toISOString(),
            dateEndConvert,
            this.inputValue,
            this.currentProperty,
            this.modeSort,
          ).then((res)=>{
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.ads = res.data.data;
            if(res.data.data.length == 0){
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          this.$apiService.getAllAdsByAdminFilterNoPaging(
            this.status,
            this.dateStart.toISOString(),
            dateEndConvert,
            this.inputValue,).then((res)=>{
              this.adsAll = res.data.data;
            })
        }else if(this.inputValue == ""){
          const dateEndConvert = this.convertToEndOfDay(this.dateEnd)
          this.$apiService.getAllAdsByAdminFilter(
            this.pageNumber,
            this.pageSize,
            this.status,
            this.dateStart.toISOString(),
            dateEndConvert,
            "",
            this.currentProperty,
            this.modeSort,
          ).then((res)=>{
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.ads = res.data.data;
            if(res.data.data.length == 0){
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          this.$apiService.getAllAdsByAdminFilterNoPaging(
            this.status,
            this.dateStart.toISOString(),
            dateEndConvert,
            this.inputValue,).then((res)=>{
              this.adsAll = res.data.data;
            })
        }else{
          const dateEndConvert = this.convertToEndOfDay(this.dateEnd)
          this.$apiService.getAllAdsByAdminFilter(
            this.pageNumber,
            this.pageSize,
            this.status,
            this.dateStart.toISOString(),
            dateEndConvert,
            this.inputValue,
            this.currentProperty,
            this.modeSort,
          ).then((res)=>{
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.ads = res.data.data;
            if(res.data.data.length == 0){
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          this.$apiService.getAllAdsByAdminFilterNoPaging(
            this.status,
            this.dateStart.toISOString(),
            dateEndConvert,
            this.inputValue,).then((res)=>{
              this.adsAll = res.data.data;
            })
        }
      }
        this.onShowMenuFilter=false;
      },
    openModalUpdateAds(id,formMode) {
      this.errorsAds={
        facebookAdsId:"",
        notes:"",
        name:"",
        currency:"",
        timezone:"",
        nationallity:"",
        limit:"",
      }
      this.errors={
				userId: "",
			};
      this.formMode = '';
      if(formMode == 'Sửa thông tin'){
        this.formMode = 'Sửa thông tin'
      }else{
        this.formMode = 'Cấp tài khoản'
      }
      
      this.$apiService
        .getAdsById(id)
        .then((res) => {
          this.adsCurrent = res.data.data;
          this.timezone = res.data.data.timezone;
          if(this.adsCurrent.shareStatus == 'Đã thu hồi' && formMode == 'Cấp tài khoản'){
          this.swalError({ message: this.$NOLIMITResource[this.dataUser.language].AdsAccount.ErrorAdRecalled, language: this.dataUser.language})
          }else{
            this.isShowModalUpdateAds = true;
          }
        })
        .catch((error) => {
          console.error("Lỗi khi lấy dữ liệu:", error);
        });
    },
      onResetFilter(){
        this.status="";
        this.dateStart = null;
        this.dateEnd = null;
      },
    openModalImportManyAds() {
      this.isShowModalImportManyAds = true;
    },
    closeModalImportManyAds() {
      this.isShowModalImportManyAds = false;
    },
    openModalReturnAds(id){
      this.$apiService.getAllMediateAdUserByAdsId(id).then((res)=>{
        this.listMediateAdUserByAdsId = res.data.data;
      })
      this.adsCurrent.adsId = id;
      this.isShowModalReturnAds = true;
    },
    closeModalReturnAds(){
      this.isShowModalReturnAds = false;
    },
    toggleMenuFilter(){
      this.onShowMenuFilter = !this.onShowMenuFilter
    },
    openModal(id) {
      this.isShowModalUpdateAds = true;
      this.$apiService
        .getAdsById(id)
        .then((res) => {
          this.adsCurrent = res.data.data;
        })
        .catch((error) => {
          console.error("Lỗi khi lấy dữ liệu:", error);
        });
    },

    closeModalUpdateAds() {
      this.errors={
				userId: "",
			};
      this.isShowModalUpdateAds = false;
      this.onShowMenuAdd = false;
    },
    updateAds() {
      if(this.validateUpdateAds()){
        
      if(this.formMode == 'Cấp tài khoản'){
        if(this.validate()){
          if(this.adsCurrent.userId==''){
          this.adsCurrent.userId = null
          this.adsCurrent.shareStatus = 'Chưa sử dụng'
        }else{
          this.adsCurrent.shareStatus = 'Đang sử dụng'
        }
      this.isShowModalUpdateAds = false;
      this.$apiService
        .provideAccountAds(this.adsCurrent)
        .then(() => {
          const adsHistory = {
              "IdAds":this.adsCurrent.adsId,
              "DeliveryCode":this.generateRandomCode(),
              "DeliveryDate":new Date(),
              "ReturnCode":null,
              "ReturnDate":null,
              "Message":"Cấp tài khoản cho người dùng "+this.adsCurrent.userId,
              "MessageEn":"Provide accounts to users "+this.adsCurrent.userId,
          }
          this.$apiService.addAdsHistoryBy(adsHistory)

          this.onShowMenuAdd = false;
          this.reDisplay();
          this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].AdsAccount.SuccessProvideAccount)
        })
        
        .catch((error) => {
          this.onShowMenuAdd = false;
          this.swalError({message:error.response.data.message, language: this.dataUser.language})
        });
        }
      }else{
      this.isShowModalUpdateAds = false;
      this.$apiService
        .updateAds(this.adsCurrent)
        .then(() => {
          
          this.onShowMenuAdd = false;
          this.reDisplay();
          this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].AdsAccount.SuccessUpdateInfoAds)
        })
        .catch((error) => {
          this.onShowMenuAdd = false;
          this.swalError({message:error.response.data.message, language: this.dataUser.language})
        });
      }
      }
    
    },
    async confirmReturnAllAdsAccount(adsId){
      const result = await this.swalWarning({ message: this.$NOLIMITResource[this.dataUser.language].AdsAccount.WarningRecallAll, language: this.dataUser.language});
      if (result.isConfirmed) {
        this.returnAdsAll(adsId)
      } else {
        // Thực hiện công việc khi người dùng hủy bỏ
      }
    },
    async confirmUpdateAllAdsStatus(){
      const result = await this.swalWarning({ message: this.$NOLIMITResource[this.dataUser.language].AdsAccount.WarningUpdateAllPickAd, language: this.dataUser.language});
      if (result.isConfirmed) {
        this.updateAllAdsStatus()
      } else {
        // Thực hiện công việc khi người dùng hủy bỏ
      }
    },

    async confirmReturnManyAds(){
      const result = await this.swalWarning({ message: this.$NOLIMITResource[this.dataUser.language].AdsAccount.WarningRecallAll, language: this.dataUser.language});
      if (result.isConfirmed) {
        this.returnManyAds()
      } else {
        // Thực hiện công việc khi người dùng hủy bỏ
      }
    },
    updateAllAdsStatus(){
      this.$apiService.updateStatusManyAdsByAdsId(this.checkboxChecked,"0")
      .then(()=>{
        this.reDisplay();
          this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].AdsAccount.SuccessRequestUpdateAd)
      }).catch((error) => {
          this.swalError({message:error.response.data.message, language: this.dataUser.language})
        });
    },
    returnAdsAll(adsId){
      this.$apiService
        .updateAllMediateAdUserById(adsId,"Đã thu hồi")
        .then(() => {
          this.$apiService.getAllMediateAdUserByAdsId(adsId).then((res)=>{
              this.listMediateAdUserByAdsId = res.data.data;
              this.$apiService.getAdsById(adsId).then((response)=>{
                this.adsCurrent = response.data.data;
                if(res.data.data.length == 0){
                  this.adsCurrent.shareStatus = 'Đã thu hồi'
                }
                this.$apiService
                  .updateAds(this.adsCurrent)
                  .then(() => {
                    this.reDisplay();
                    this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].AdsAccount.SuccessRecallAllAds)
                  })
                  .catch((error) => {
                    this.swalError({message:error.response.data.message, language: this.dataUser.language})
                  });
              })
            
          })

        })
        .catch((error) => {
          console.error("Lỗi khi lấy dữ liệu:", error);
        });
      
    },
    async confirmReturnAdsAccount(id,adsId,userId){
      const result = await this.swalWarning( { message: this.$NOLIMITResource[this.dataUser.language].AdsAccount.WarningUpdateThisAd, language: this.dataUser.language});
      if (result.isConfirmed) {
        this.returnAds(id,adsId,userId)
      } else {
        // Thực hiện công việc khi người dùng hủy bỏ
      }
    },

    returnAds(id,adsId,userId){
      this.$apiService
        .updateMediateAdUserById(id,"Đã thu hồi")
        .then(() => {
          this.$apiService.getAllMediateAdUserByAdsId(adsId).then((res)=>{
              this.listMediateAdUserByAdsId = res.data.data;
              this.$apiService.getAdsById(adsId).then((response)=>{
                this.adsCurrent = response.data.data;
                if(res.data.data.length == 0){
                  this.adsCurrent.shareStatus = 'Đã thu hồi'
                }
                this.$apiService
                  .updateAds(this.adsCurrent)
                  .then(() => {
                    const adsHistory = {
                    "IdAds":adsId,
                    "DeliveryCode":null,
                    "DeliveryDate":null,
                    "ReturnCode":this.generateRandomCode(),
                    "ReturnDate":new Date(),
                    "Message":"Thu hồi tài khoản này của người dùng "+userId,
                    "MessageEn":"Recall this user's account "+userId,
                }
                this.$apiService.addAdsHistoryBy(adsHistory)
                    this.reDisplay();
                    this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].AdsAccount.SuccessRecallAd)
                  })
                  .catch((error) => {
                    this.swalError({message:error.response.data.message, language: this.dataUser.language})
                  });
              })
            
          })  
        })
        .catch((error) => {
          console.error("Lỗi khi lấy dữ liệu:", error);
        });
      
    },
    
    toggleMenuItem(id) {
      this.onShowMenu = !this.onShowMenu;
      this.currenOnshowId = id;
    },
    closeMenuItem(){
      this.onShowMenu = false;
    },
    addAds() {
    if(this.validateAds()){
      this.isShowModalAddAds = false;
      this.adsAdd.timezone = this.timezone.value
      this.$apiService
        .addAds(this.adsAdd)
        .then(() => {
          this.onShowMenu = false;
          this.reDisplay();
          this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].AdsAccount.SuccessAddAd)
        })
        .catch((error) => {
         
          this.swalError({message: error.response.data.message, language: this.dataUser.language})
        });
    }
     
    },
    selectPage(index) {
      this.pageNumber = index;
      if (this.totalPage >= 4) {
        if (index == this.totalPage) {
          this.firstPage = index - 4;
        } else if (index >= 3) {
          this.firstPage = index - 3;
        } else {
          this.firstPage = 0;
        }
      }
      if (this.status != "" || this.dateStart != null || this.dateEnd != null) {
        this.onFilterSearch();
      } else if (this.statusAds != "") {
        this.onFilterStatusAds();
      } else if (this.sortingNumber != null || this.sortingAsc != null) {
        this.onSorting(this.currentIndex, this.currentProperty, "selectPage");
      } else {
        this.onEnterSearch();
      }
    },
    reDisplay() {
      this.hasData = true;
      this.$apiService
      .getAllAdsByAdmin(this.pageNumber, this.pageSize)
      .then((res) => {
        this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
        this.ads = res.data.data;
        if(res.data.data.length == 0){
                this.hasData = false;
              }
        this.totalPage = res.data.pageData.pageCount;
      })
      .catch((error) => {
        console.error("Lỗi khi lấy dữ liệu:", error);
      });
      this.$apiService
        .getAllAdsByAdminNoPaging()
        .then((res) => {
          this.totalStatusUsing = 0;
          this.totalStatusReturn = 0;
          this.totalStatusWaittingReturn = 0;
          this.totalStatusNotUse = 0;
          for(let i = 0; i<res.data.data.length;i++){
              if(res.data.data[i].shareStatus == "Đang sử dụng"){
                this.totalStatusUsing +=1;
              }else if(res.data.data[i].shareStatus == "Đã thu hồi"){
                this.totalStatusReturn +=1;
              } else if (res.data.data[i].shareStatus == "Chờ thu hồi") {
                this.totalStatusWaittingReturn += 1;
              }else{
                this.totalStatusNotUse +=1;
              }
          }
        this.adsAll = res.data.data; 
        })
        this.unSelectAllRecord()
    },
    /**
     * Chọn next trang tiếp theo trong mục phân trang
     */
    nextPage() {
      if (this.pageNumber != this.totalPage) {
        this.pageNumber += 1;
        if (this.totalPage >= 4) {
          if (this.pageNumber == this.totalPage) {
            this.firstPage = this.pageNumber - 4;
          } else if (this.pageNumber >= 3) {
            this.firstPage = this.pageNumber - 3;
          } else {
            this.firstPage = 0;
          }
        }
      }
      if (this.status != "" || this.dateStart != null || this.dateEnd != null) {
        this.onFilterSearch();
      } else if (this.statusAds != "") {
        this.onFilterStatusAds();
      } else if (this.sortingNumber != null || this.sortingAsc != null) {
        this.onSorting(this.currentIndex, this.currentProperty, "selectPage");
      } else {
        this.onEnterSearch();
      }
    },
    generateRandomCode() {
            const length = 8; // Độ dài của mã
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let result = '';
            for (let i = 0; i < length; i++) {
              result += characters.charAt(Math.floor(Math.random() * characters.length));
            }
            return result;
          },
    /**
     * Chọn prev trang trước đó trong mục phân trang
     * Author: ndanh (20/09/2023)
     */
    prevPage() {
      if (this.pageNumber != 1) {
        this.pageNumber -= 1;
        if (this.totalPage >= 4) {
          if (this.pageNumber == this.totalPage) {
            this.firstPage = this.pageNumber - 4;
          } else if (this.pageNumber >= 3) {
            this.firstPage = this.pageNumber - 3;
          } else {
            this.firstPage = 0;
          }
        }
      }
      if (this.status != "" || this.dateStart != null || this.dateEnd != null) {
        this.onFilterSearch();
      } else if (this.statusAds != "") {
        this.onFilterStatusAds();
      } else if (this.sortingNumber != null || this.sortingAsc != null) {
        this.onSorting(this.currentIndex, this.currentProperty, "selectPage");
      } else {
        this.onEnterSearch();
      }
    },
    onEnterSearch() {
      this.hasData = true;
      if (this.inputValue == "") {
        this.reDisplay();
      } else {
        this.$apiService
          .getAllAdsByAdminFilterStatus(
            this.pageNumber,
            this.pageSize,
            this.status,
            this.inputValue,
            this.currentProperty,
            this.modeSort,
          )
          .then((res) => {
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.ads = res.data.data;
            if(res.data.data.length == 0){
                this.hasData = false;
              }
            this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
          this.$apiService.getAllAdsByAdminFilterStatusNoPaging(
            this.status,
            this.inputValue
          ).then((res)=>{
            this.adsAll = res.data.data;
          })   
      }
    },
    onEnterSearchFirst() {
      this.hasData = true;
      if (this.inputValue == "") {
        this.reDisplay();
      } else {
        this.pageNumber = 1;
        this.firstPage = 0;
        this.$apiService
          .getAllAdsByAdminFilterStatus(
            this.pageNumber,
            this.pageSize,
            this.status,
            this.inputValue,
            this.currentProperty,
            this.modeSort,
          )
          .then((res) => {
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.ads = res.data.data;
            if(res.data.data.length == 0){
                this.hasData = false;
              }
            this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
          this.$apiService.getAllAdsByAdminFilterStatusNoPaging(
            this.status,
            this.inputValue
          ).then((res)=>{
            this.adsAll = res.data.data;
          })   
      }
    },
    choosePageSize(event) {
      this.pageSize = event.target.value;
      this.reDisplay();
    },
       /**
         * Chọn một bản ghi theo checkbox
         * Author: ndanh (20/12/2023)
         * @param {int} id là id của bản ghi được chọn theo checkbox
         * @returns {int} trả về id đang được active
         */
         selectedRecord(id){
            this.recordActiveId = id;
            this.selectRecord= true;
            return this.recordActiveId;
        },
         /**
         * Chọn tất cả bản ghi theo checkbox
         * Author: ndanh (20/12/2023)
         */
          selectAllRecord(){
            if(this.onSelectAllRecord == false){
              this.onSelectAllRecord = true;
              this.isCheckedAll = true;
            this.checkboxChecked = [];
            if(this.onSelectAllRecord){
                for(let i = 0;i<this.adsAll.length;i++){
                    this.checkboxChecked.push(this.adsAll[i].adsId);
                }
            }
            }else{
              this.selectRecord= false;
              this.isCheckedAll = false;
            this.onSelectAllRecord = false;
            this.checkboxChecked = [];
            }
        },
          /**
         * Bỏ chọn tất cả bản ghi theo checkbox
         * Author: ndanh (20/12/2023)
         */
        unSelectAllRecord(){
          this.isCheckedAll = false;
            this.selectRecord= false;
            this.onSelectAllRecord = false;
            this.checkboxChecked = [];
        },
        /**
         * Kiểm trả xem id này có đang được checkbox checked hay không
         * Author: ndanh (20/12/2023)
         * @param {int} id là id được truyền vào để kiểm tra
         * @return {boolean} giá trị trả về true nếu id đang được checked
         */
          isChecked(id) {
              return this.checkboxChecked.includes(id) 
        },
    convertStringToArray() {
      const formData = new FormData();
            formData.append('adsData', this.adsImport);
            this.$apiService.importManyAds(formData)
            .then(()=>{
              this.reDisplay()
              this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].AdsAccount.SuccessAdd)
            this.closeModalImportManyAds()
            }).catch((error)=>{
              this.swalError({message:error.response.data.message, language: this.dataUser.language})
            })
        },
    checkDuplicate(){
      const formData = new FormData();
            formData.append('adsData', this.adsImport);
            this.$apiService.checkDuplicateAdsId(formData)
            .then((res)=>{
              console.log(res.data.join('\r\n'))
              this.adsImport = res.data.join('\r\n');

            }).catch((error)=>{
              this.swalError({message:error.response.data.message, language: this.dataUser.language})
            })
    },    
    onSubmitModalPickRow(){
      this.setDisPlayRow(this.checkRow);
        this.toggleModalDisplayRow()
        this.$apiService.updateDisplayPropertiesByUserId(this.idUser,this.displayRow)
        .then(()=>{
          this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].AdsAccount.SuccessPickColumns)  
        })
        
    },
    startResize(event, index) {
        if (event.offsetX > event.target.offsetWidth - 10) {
                this.isResizing = true;
                this.initialX = event.clientX;
                this.initialWidth = this.columns[index].width;
                this.resizingCol = index;

                document.addEventListener('mousemove', this.handleMouseMove);
                document.addEventListener('mouseup', this.handleMouseUp);
        } 
      },
      handleMouseMove(event) {
          if (this.isResizing && this.resizingCol !== null) {
              const delta = event.clientX - this.initialX;
              const newWidth = Math.max(50, this.initialWidth + delta);
              this.columns[this.resizingCol] = { ...this.columns[this.resizingCol], width: newWidth };
          }
      },
      handleMouseUp() {
          this.isResizing = false;
          this.initialX = 0;
          this.initialWidth = 0;
          this.resizingCol = null;

          document.removeEventListener('mousemove', this.handleMouseMove);
          document.removeEventListener('mouseup', this.handleMouseUp);
      },
      shouldDisplay(index) {
      switch (index) {
        case 1:
          return this.displayRow.topupFeeUser;
        case 2:
          return this.displayRow.notes;
        case 3:
          return this.displayRow.shareStatus;
        case 4:
          return this.displayRow.lastTopupDate;
        case 5:
        return this.displayRow.createDate;
        case 6:
        return this.displayRow.spendingCap;
        case 7:
        return this.displayRow.remainingAmount;
        case 8:
        return this.displayRow.customerNow;
        case 9:
        return this.displayRow.supporter;
        case 10:
        return this.displayRow.returnDate;
        case 11:
        return this.displayRow.paymentMethod;
        case 12:
        return this.displayRow.timezone;
        case 13:
        return this.displayRow.currency;
        case 14:
        return this.displayRow.nationallity;
        case 15:
        return this.displayRow.limit;
        case 16:
        return this.displayRow.dailyLimit;
        case 17:
        return this.displayRow.paymentThreshold;
        case 18:
        return this.displayRow.remainThreshold;
        case 19:
        return this.displayRow.invoiceDate;
        case 20:
        return this.displayRow.remainPaymentDate;
        case 21:
        return this.displayRow.idbusinessOwner;
        case 22:
        return this.displayRow.nameBusinessOwner;
        case 23:
        return this.displayRow.idBusinessPartner;
        case 24:
        return this.displayRow.spendOnDay;
        case 25:
        return this.displayRow.spendingNow;
        case 26:
        return this.displayRow.totalSpend;
        case 27:
        return this.displayRow.invoiceAmountOnDay;
        case 28:
        return this.displayRow.totalInvoiceAmount;
        case 29:
        return this.displayRow.dailyAmountTopUp;
        case 30:
        return this.displayRow.totalAmountTopUp;
        case 31:
        return this.displayRow.lastUpdate;
        // Thêm các trường hợp khác tương ứng
        default:
          return true; // Hiển thị mặc định nếu không có điều kiện nào khớp
      }
    },
    onFilterStatusAds(){
      this.hasData = true;
      if(this.statusAds == ""){
        this.reDisplay();
      }else{
        this.$apiService.getAllAdsByAdminFilterStatusAds(
            this.pageNumber,
            this.pageSize,
            this.statusAds,
        ).then((res)=>{
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.ads = res.data.data;
            if(res.data.data.length == 0){
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
        })
        this.$apiService.getAllAdsByAdminFilterStatusAdsNoPaging(
            this.statusAds,).then((res)=>{
              this.adsAll = res.data.data;
            })
      }

      this.onShowMenuFilterStatus = false
    },
    toggleFilterStatus(){
      this.onShowMenuFilterStatus = !this.onShowMenuFilterStatus
    },
    closeFilterStatus(){
      this.onShowMenuFilterStatus = false;
    },
    closeFilterAll(){
      this.onShowMenuFilter = false;
    },
    onResetFilterStatusAds(){
      this.statusAds = ""
    },
    updateStatusAdsByAdsId(adsId){
        this.$apiService.updateStatusAdsByAdsId(adsId,"0").then(()=>{
          this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].AdsAccount.SuccessRequestUpdate )
        })
    },
    onSorting(index, property, mode) {
      this.hasData = true;
      if(index == 1){
        return null;
      }
      this.currentIndex = index;
      this.currentProperty = property;

      if(this.dateStart == null || this.dateEnd == null || this.dateEnd < this.dateStart){

      
      if (this.sortingNumber == index && mode == "changeSort") {
        this.sortingNumber = null;
        this.sortingAsc = index;
        this.$apiService
        .getAllAdsByAdminFilterStatus(
            this.pageNumber,
            this.pageSize,
            this.status,
            this.inputValue,
            property,
            "ASC"
          )
          .then((res) => {
            this.modeSort = "ASC";
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.ads = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
      } else if (this.sortingNumber == index && mode == "selectPage") {
        this.sortingNumber = null;
        this.sortingAsc = null;
        this.sortingNumber = index;
        this.$apiService
        .getAllAdsByAdminFilterStatus(
            this.pageNumber,
            this.pageSize,
            this.status,
            this.inputValue,
            property,
            "DESC"
          )
          .then((res) => {
            this.modeSort = "DESC";
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.ads = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
      } else if (this.sortingAsc == index && mode == "changeSort") {
        this.sortingAsc = null;
        this.sortingNumber = index;
        this.$apiService
        .getAllAdsByAdminFilterStatus(
            this.pageNumber,
            this.pageSize,
            this.status,
            this.inputValue,
            property,
            "DESC"
          )
          .then((res) => {
            this.modeSort = "DESC";
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.ads = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
      } else if (this.sortingAsc == index && mode == "selectPage") {
        this.sortingAsc = null;
        this.sortingNumber = null;
        this.sortingAsc = index;
        this.$apiService
        .getAllAdsByAdminFilterStatus(
            this.pageNumber,
            this.pageSize,
            this.status,
            this.inputValue,
            property,
            "ASC"
          )
          .then((res) => {
            this.modeSort = "ASC";
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.ads = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
      } else {
        this.sortingNumber = null;
        this.sortingAsc = null;
        this.sortingNumber = index;
        this.$apiService
        .getAllAdsByAdminFilterStatus(
            this.pageNumber,
            this.pageSize,
            this.status,
            this.inputValue,
            property,
            "DESC"
          )
          .then((res) => {
            this.modeSort = "DESC";
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.ads = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
      }
      this.$apiService.getAllAdsByAdminFilterStatusNoPaging(
            this.status,
            this.inputValue,
          ).then((res)=>{
              this.adsAll = res.data.data;
            })
    }else{
      const dateEndConvert = this.convertToEndOfDay(this.dateEnd)
      if (this.sortingNumber == index && mode == "changeSort") {
        this.sortingNumber = null;
        this.sortingAsc = index;
        this.$apiService
        .getAllAdsByAdminFilter(
            this.pageNumber,
            this.pageSize,
            this.status,
            this.dateStart.toISOString(),
            dateEndConvert,
            this.inputValue,
            property,
            "ASC"
          )
          .then((res) => {
            this.modeSort = "ASC";
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.ads = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
      } else if (this.sortingNumber == index && mode == "selectPage") {
        this.sortingNumber = null;
        this.sortingAsc = null;
        this.sortingNumber = index;
        this.$apiService
        .getAllAdsByAdminFilter(
            this.pageNumber,
            this.pageSize,
            this.status,
            this.dateStart.toISOString(),
            dateEndConvert,
            this.inputValue,
            property,
            "DESC"
          )
          .then((res) => {
            this.modeSort = "DESC";
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.ads = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
      } else if (this.sortingAsc == index && mode == "changeSort") {
        this.sortingAsc = null;
        this.sortingNumber = index;
        this.$apiService
        .getAllAdsByAdminFilter(
            this.pageNumber,
            this.pageSize,
            this.status,
            this.dateStart.toISOString(),
            dateEndConvert,
            this.inputValue,
            property,
            "DESC"
          )
          .then((res) => {
            this.modeSort = "DESC";
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.ads = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
      } else if (this.sortingAsc == index && mode == "selectPage") {
        this.sortingAsc = null;
        this.sortingNumber = null;
        this.sortingAsc = index;
        this.$apiService
        .getAllAdsByAdminFilter(
            this.pageNumber,
            this.pageSize,
            this.status,
            this.dateStart.toISOString(),
            dateEndConvert,
            this.inputValue,
            property,
            "ASC"
          )
          .then((res) => {
            this.modeSort = "ASC";
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.ads = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });


      } else {
        this.sortingNumber = null;
        this.sortingAsc = null;
        this.sortingNumber = index;
        this.$apiService
        .getAllAdsByAdminFilter(
            this.pageNumber,
            this.pageSize,
            this.status,
            this.dateStart.toISOString(),
            dateEndConvert,
            this.inputValue,
            property,
            "DESC"
          )
          .then((res) => {
            this.modeSort = "DESC";
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.ads = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
      }
      this.$apiService.getAllAdsByAdminFilterNoPaging(
            this.status,
            this.dateStart.toISOString(),
            dateEndConvert,
            this.inputValue,).then((res)=>{
              this.adsAll = res.data.data;
            })


    }


    },
    
    paymentMethodImage(paymentMethodString) {

        let paymentMethod = paymentMethodString.replace('MasterCard',"<img src='./../../../../public/frontend/media/logos/logo2.png'>"); // Lấy tên phương thức thanh toán từ chuỗi

        return paymentMethod; 

    },
    checkPaymentMastercard(paymentMethodString) {
      if(paymentMethodString.indexOf("MasterCard") !== -1){
        return true;
      }
    return false;
  },
  checkPaymentVisa(paymentMethodString) {
      if(paymentMethodString.indexOf("Visa") !== -1){
        return true;
      }
    return false;
  },
  checkPaymentCredit(paymentMethodString) {
      if(paymentMethodString.indexOf("credit") !== -1){
        return true;
      }
    return false;
  },
  lastPayment(paymentMethodString){
    if(paymentMethodString.indexOf("MasterCard") !== -1){
      return paymentMethodString.replace("MasterCard","");
    }else if(paymentMethodString.indexOf("Visa") !== -1){
      return paymentMethodString.replace("Visa","");
    }else if(paymentMethodString.indexOf("credit") !== -1){
      return paymentMethodString.replace("credit","");
    }
    return paymentMethodString
  },
  paymentsCurrent(paymentMethodString){

    let regex = /([A-Za-z]+ · [0-9]+|Ad credit: ₫[0-9]+)/g;
    let matches = paymentMethodString.match(regex);
    this.payments = matches;
    return matches;
  },

  exportCSV(){
      let data = [];
      for (let i = 0; i < this.ads.length; i++){
        let adsStatus;
        switch (this.ads[i].adsStatus) {
            case '0':
              adsStatus = this.$NOLIMITResource[this.dataUser.language].AdsAccount.StatusUpdateting;
                break;
            case '1':
              adsStatus = this.$NOLIMITResource[this.dataUser.language].AdsAccount.StatusWork;
                break;
            case '3':
              adsStatus = this.$NOLIMITResource[this.dataUser.language].AdsAccount.StatusPaymentRequired;
                break;
            case '2':
              adsStatus = this.$NOLIMITResource[this.dataUser.language].AdsAccount.StatusDisable;
              break;
            default:
              adsStatus = this.$NOLIMITResource[this.dataUser.language].NotDetermined;
              break;
        }

        let shareStatus;
        switch (this.ads[i].shareStatus) {
            case 'Đã thu hồi':
              shareStatus = this.$NOLIMITResource[this.dataUser.language].AdsAccount.StatusRecalled;
                break;
            case 'Chưa sử dụng':
              shareStatus = this.$NOLIMITResource[this.dataUser.language].AdsAccount.NotYetUsed;
                break;
            case 'Chờ thu hồi':
              shareStatus = this.$NOLIMITResource[this.dataUser.language].AdsAccount.StatusWaitingForRecall;
                break;
            case 'Đang sử dụng':
              shareStatus = this.$NOLIMITResource[this.dataUser.language].AdsAccount.StatusDisable;
              break;
            default:
              shareStatus = this.$NOLIMITResource[this.dataUser.language].NotDetermined;
              break;
        }
        let item = {};
          if(this.dataUser.language == "EN"){
             item = {
            'Status': adsStatus,
            'NameAds': this.ads[i].name,
            'FACEBOOKADSID': "' "+this.ads[i].facebookAdsId,
            'Note' : this.ads[i].notes,
            'System Status': shareStatus,
            'LastTopup' : this.formatDateTime(this.ads[i].lastTopupDate),
            'Created Date' : this.formatDateTime(this.ads[i].createDate),
            'SpenCap' : this.ads[i].spendingCap,
            'Customer' : this.ads[i].customerNow,
            'ReturnDate' : this.formatDateTime(this.ads[i].returnDate),

            'Timezone' : this.ads[i].timezone,
            'Currency' : this.ads[i].currency,
            'Nation' : this.ads[i].nationallity,
            'Partner Business ID' : this.ads[i].idBusinessPartner,
            'Deposit Amount Today' : this.ads[i].dailyAmountTopUp,
            'Total Amount Topup' : this.ads[i].totalAmountTopUp,
            'Last Update': this.formatDateTime(this.ads[i].lastUpdate),
          }
          }else{
             item = {
            'TRẠNG THÁI': adsStatus,
            'TÊN TÀI KHOẢN': this.ads[i].name,
            'FACEBOOKADSID': "' "+this.ads[i].facebookAdsId,
            'GHI CHÚ' : this.ads[i].notes,
            'TRẠNG THÁI Ở HỆ THỐNG': shareStatus,
            'TOPUP GẦN NHẤT' : this.formatDateTime(this.ads[i].lastTopupDate),
            'NGÀY TẠO' : this.formatDateTime(this.ads[i].createDate),
            'GIỚI HẠN CHI TIÊU CÒN LẠI' : this.ads[i].spendingCap,
            'KHÁCH HÀNG' : this.ads[i].customerNow,
            'NGÀY THU HỒI' : this.formatDateTime(this.ads[i].returnDate),

            'MÚI GIỜ' : this.ads[i].timezone,
            'ĐƠN VỊ TIỀN TỆ' : this.ads[i].currency,
            'QUỐC GIA' : this.ads[i].nationallity,
            'ID DOANH NGHIỆP ĐỐI TÁC' : this.ads[i].idBusinessPartner,
            'SỐ TIỀN NẠP TRONG NGÀY' : this.ads[i].dailyAmountTopUp,
            'TỔNG SỐ TIỀN ĐÃ TOPUP' : this.ads[i].totalAmountTopUp,
            'NGÀY CẬP NHẬT GẦN NHẤT': this.formatDateTime(this.ads[i].lastUpdate),
          }
          }
   

          data.push(item);
      }
      const csvContent1 = this.convertToCSV(data);
      var BOM = "\uFEFF";
      var csvContent = BOM + csvContent1;
      const blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8'});
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download','export_ads.csv');
      link.click();
    },
    convertToCSV(data){
        const headers = Object.keys(data[0]);
        const rows = data.map(obj => headers.map(header => obj[header]));
        const headerRow = headers.join(',');
        const csvRows = [headerRow, ...rows.map(row=> row.join(','))];
        return csvRows.join('\n');
    },
    openModalUpdateTopupFee(id) {
      this.isShowModalUpdateTopup = true;
      this.$apiService
        .getAdsById(id)
        .then((res) => {
          this.adsCurrent = res.data.data;
        })
        .catch((error) => {
          console.error("Lỗi khi lấy dữ liệu:", error);
        });
    },
    closeModalUpdateTopupFee() {
      this.isShowModalUpdateTopup = false;
      this.onShowMenu = false;
    },
    updateTopupFee(){
      this.isShowModalUpdateTopup = false;
      this.$apiService.updateAdsTopupFee(this.adsCurrent.adsId, this.adsCurrent.topupFee).then(()=> {
        this.onShowMenu = false;
        this.reDisplay();
        this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].AdsAccount.SuccessUpdateTopupFee)
      }).catch((error) => {
          this.swalError({message:error.response.data.message, language: this.dataUser.language})
      });
    },
    updateBatchTopupFee(){
      this.isShowModalUpdateFeeTopupAll = false;
      this.$apiService.updateBatchAdsTopupFee(this.checkboxChecked, this.adsCurrent.topupFee).then(()=> {
        this.onShowMenu = false;
        this.reDisplay();
        this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].AdsAccount.SuccessUpdateTopupFeeAds)
      }).catch((error) => {
          this.swalError({message:error.response.data.message, language: this.dataUser.language})
      });
    },

  },
};
</script>


