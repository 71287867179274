<template>
  <div>
    <div
      id="kt_app_toolbar_container"
      class="app-container d-flex flex-stack"
      style="margin-left: 10px !important; margin-bottom: 30px"
    >
      <!--begin::Page title-->
      <div
        class="page-title d-flex flex-column justify-content-center flex-wrap me-3"
      >
        <!--begin::Title-->
        <h1
          class="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0"
        >
        {{ this.$NOLIMITResource[this.dataUser.language].SidebarCategory.Home }}
        </h1>
        <!--end::Title-->
      </div>
      <!--end::Page title-->
    </div>
    <div>
      <div
        class="col-xxl-6 mb-md-5 mb-xl-10"
        style="width: 100%; padding: 30px"
      >
        <div class="card-container">
          <div class="card card-blue">
            <div class="card-more"></div>
            <div class="card-icon">
              <i class="ki-duotone ki-dollar">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
              </i>
            </div>
            <div class="card-content">
              <div class="amount">+ {{ formatCurrency(totalRechargeToday) }} đ <span style="font-size:12px;color:#22d770">{{ this.$NOLIMITResource[this.dataUser.language].DashBoard.Today }}</span> </div>
              <div class="description">{{ this.$NOLIMITResource[this.dataUser.language].DashBoard.Card_One }}</div>
              <div class="total">{{ formatCurrency(balanceUser) }}đ / {{ formatCurrency(totalRecharge) }}đ</div>
            </div>
          </div>
          <div class="card card-red">
            <div class="card-more"></div>
            <div class="card-icon">
              <i class="ki-duotone ki-dollar">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
              </i>
            </div>
            <div class="card-content">
              <div class="amount">+ {{ formatCurrency(totalTopupAmountToday) }} đ <span style="font-size:12px;color:#22d770">{{ this.$NOLIMITResource[this.dataUser.language].DashBoard.Today }}</span> </div>
              <div class="description">{{ this.$NOLIMITResource[this.dataUser.language].DashBoard.Card_Two }}</div>
              <div class="total">{{formatCurrency(totalTopupAmount)}}đ</div>
            </div>
          </div>
          <div class="card card-orange">
            <div class="card-more"></div>
            <div class="card-icon">
              <i class="ki-duotone ki-dollar">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
              </i>
            </div>
            <div class="card-content">
              <div class="amount"> {{ formatCurrency(totalReceiveMoneyToday) }}đ / {{ formatCurrency(totalTransferMoneyToday) }}đ <span style="font-size:12px;color:#22d770">{{ this.$NOLIMITResource[this.dataUser.language].DashBoard.Today }}</span></div>
              <div class="description">{{ this.$NOLIMITResource[this.dataUser.language].DashBoard.Card_Three }}  </div>
              <div class="total">{{ formatCurrency(totalReceiveMoney) }}đ / {{ formatCurrency(totalTransferMoney) }}đ</div>
            </div>
          </div>
          <div class="card card-green">
            <div class="card-more"></div>
            <div class="card-icon">
              <i class="ki-duotone ki-dollar">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
              </i>
            </div>
            <div class="card-content">
              <div class="amount"> {{ formatCurrency(totalAdminPlusToday) }}đ / {{ formatCurrency(totalAdminMinusToday) }}đ <span style="font-size:12px;color:#22d770">{{ this.$NOLIMITResource[this.dataUser.language].DashBoard.Today }}</span> </div>
              <div class="description">{{ this.$NOLIMITResource[this.dataUser.language].DashBoard.Card_Four }} </div>
              <div class="total">{{ formatCurrency(totalAdminPlus) }}đ / {{ formatCurrency(totalAdminMinus) }}đ</div>
            </div>
          </div>
        </div>
        <div class="row-2-apexchart">
          <div class="apex-chart-block">
            <div class="header-apex-chart">
              <p
                style="text-align: start; margin-left: 20px;text-transform:uppercase;font-size: 15px;color: #071437;"
                data-v-ffde663e=""
                class="card-label fw-bold text-gray-900"
              >
              {{ this.$NOLIMITResource[this.dataUser.language].DashBoard.Overview }}: {{ this.$NOLIMITResource[this.dataUser.language].DashBoard.Recharge }} ,{{ this.$NOLIMITResource[this.dataUser.language].DashBoard.Topup }} 
              </p>
              <div class="legend-container">
                <div class="legend-item">
                  <div class="legend-color color-napvi"></div>
                  <span>{{ this.$NOLIMITResource[this.dataUser.language].DashBoard.Recharge }}</span>
                </div>
                <div class="legend-item">
                  <div class="legend-color color-topup"></div>
                  <span>{{ this.$NOLIMITResource[this.dataUser.language].DashBoard.Topup }}</span>
                </div>
                <!-- <div class="legend-item">
                  <div class="legend-color color-tkgiao"></div>
                  <span>Tk giao</span>
                </div> -->
                <div class="dropdown-container">
                  <div class="dropdown">
                    <a>{{ selectedOption }}</a>
                    <div class="dropdown-menu">
                      <a v-for="option in dropdownOptions" :key="option" @click="selectOption(option)">{{ option }}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div ref="realtimeChart" id="kt_apexcharts_3" class="chart-container"></div>
          </div>
          <div
          class="card-user"
      v-if="this.isAdmin != true && this.isSupport != true"  style="padding:0" 
    >
      <div class="card bg-primary h-md-100 bg-recharge" data-bs-theme="light" style="width:100%;padding:0" >
        <!--begin::Body-->
        <div class="card-body d-flex flex-column pt-13 pb-14" >
          <!--begin::Heading-->
          <div class="m-0" style="    height: 400px;">
            <!--begin::Title-->
            <h1 class="fw-semibold text-white text-center lh-lg mb-9">
              Tạo hóa đơn nạp tiền thật nhanh chóng
            </h1>
            <div class="fs-4 text-white">
															<span class="opacity-75">Tạo hóa đơn ngay </span>
															<span class="position-relative d-inline-block">
																<a class="link-white opacity-75-hover fw-bold d-block mb-1">hoặc</a>
																<!--begin::Separator-->
																<span class="position-absolute opacity-50 bottom-0 start-0 border-2 border-body border-bottom w-100"></span>
																<!--end::Separator-->
															</span>
															<span class="opacity-75"> Xem hóa đơn và thanh toán để trải nghiệm dịch vụ nạp tiền real-time.</span>
														</div>
            <!--end::Title-->
            <!--begin::Illustration-->
            <div
              class="flex-grow-1 bgi-no-repeat bgi-size-contain bgi-position-x-center card-rounded-bottom h-200px mh-200px my-5 mb-lg-12 delivery"
            ></div>
            <!--end::Illustration-->
          </div>
          <!--end::Heading-->
          <!--begin::Links-->
          <div class="text-center">
            <!--begin::Link-->
            <router-link
              to="/depositRecharge"
              class="btn btn-sm bg-white btn-color-gray-800 me-2"
              >Tạo hóa đơn</router-link
            >
            <!--end::Link-->
            <!--begin::Link-->
            <router-link
              class="btn btn-sm bg-white btn-color-white bg-opacity-20"
              to="/deposit"
              >Xem hóa đơn và thanh toán</router-link
            >
            <!--end::Link-->
          </div>
          <!--end::Links-->
        </div>
        <!--end::Body-->
      </div>
    </div>
          <div v-else class="card-user" style="overflow:scroll">
            <div class="card-header-user">
              <p
                style="text-align: start; margin-left: 20px;text-transform:uppercase;font-size: 15px;color: #071437;"
                data-v-ffde663e=""
                class="card-label fw-bold text-gray-900"
              >
              {{ this.$NOLIMITResource[this.dataUser.language].DashBoard.UserWallet }}
              </p>
              <router-link v-if="this.isAdmin == true" to="/allBalanceHistory"
                  type="button"
                  class="btn btn-sm btn-flex btn-light-primary" 
                 
                >
                {{ this.$NOLIMITResource[this.dataUser.language].DashBoard.SeeAll }}
                </router-link>
            </div>
            <ul class="user-list" >
              <li class="user-item" v-for="balance in balanceHistory" :key="balance.balanceHistoryId">
                  <div class="circle">
                    {{getFirstUsername(balance.username)}}
                  </div>
                <div class="user-info">
                  <h4>{{balance.username}}</h4>
                  <p>{{ formatDateRemain(balance.changeDate) }}</p>
                </div>
                <div v-if="balance.tag == 'Nạp tiền' || balance.tag == 'Admin cộng tiền' || balance.tag == 'Nhận tiền'" class="user-amount">+{{formatCurrency(balance.amountDifference)}}</div>
                <div style="color:red" v-else class="user-amount">-{{formatCurrency(balance.amountDifference)}}</div>
              </li>
            
            </ul>
          </div>
        </div>
        <div class="row-3-amchart">
          <div class="card pt-4 mb-6 mb-xl-9 block-topup-history" >
            <!--begin::Card header-->
            <div class="card-header border-0">
              <!--begin::Card title-->
              <div class="card-title">
                <p
                style="text-align: start;text-transform:uppercase;font-size: 15px;"
                data-v-ffde663e=""
                class="card-label fw-bold text-gray-900"
              >
              {{ this.$NOLIMITResource[this.dataUser.language].DashBoard.TopupHistory }}
              </p>
              </div>
              <!--end::Card title-->
              <!--begin::Card toolbar-->
              <div class="card-toolbar">
                <!--begin::Filter-->
                <router-link to="/topUpAccount"
                  type="button"
                  class="btn btn-sm btn-flex btn-light-primary"
                 
                >
                {{ this.$NOLIMITResource[this.dataUser.language].DashBoard.SeeAll }}
                </router-link>
                <!--end::Filter-->
              </div>
              <!--end::Card toolbar-->
            </div>
            <!--end::Card header-->
            <!--begin::Card body-->
            <div class="card-body pt-0 pb-5">
              <!--begin::Table-->
              <div
                id="kt_table_customers_payment_wrapper"
                class="dataTables_wrapper dt-bootstrap4 no-footer"
              >
                <div class="table-responsive">
                  <table style="max-height:480px"
                class="table align-middle table-row-dashed fs-6 gy-5"
                id="kt_customers_table"
              >
                <thead>
                  <tr
                    class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0"
                  >
                 
                    <template v-for="(column, index) in (isAccountant ? columnsAccountant : columns)" :key="index" >
                        <th class="min-w-125px" :class="{
                          sorting_desc: this.sortingNumber == index,
                          sorting_asc: this.sortingAsc == index,
                        }" :style="{ 'width': column.width + 'px !important'}">
                            <div @mousedown="startResize($event, index)"><span
                              @click="
                                onSorting(index, column.property, 'changeSort')
                              "
                              >{{ column.label }}</span
                            ></div>
                        </th>
                      </template>
                 
                 
                  
                  </tr>
                </thead>
                <div class="loading-1" v-if="onLoading" >
                  <span class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </span>
                </div>
                <div style="margin-top: 20px;width: 100%;" v-if="!this.onLoading && this.hasData == false"> {{ this.$NOLIMITResource[this.dataUser.language].EmptyData }}</div> 
                <tbody v-if="!this.onLoading && this.hasData == true" class="fw-semibold text-gray-600">
                  <tr v-for="topUpOrder in topUpOrders" :key="topUpOrder.topupOrderId">
                    <td>
                      <a
                        class="text-gray-800 text-hover-primary mb-1"
                        >{{ topUpOrder.topupOrderId }}</a
                      >
                    </td>
                    <td>
                      <a
                        class="text-gray-800 text-hover-primary mb-1"
                        >{{ topUpOrder.name }}</a
                      >
                    </td>
                    <td v-if="isAccountant">
                      <a
                        class="text-gray-800 text-hover-primary mb-1"
                        >{{ topUpOrder.userName }}</a
                      >
                    </td>
                    <td>
                      <a class="text-gray-600 text-hover-primary mb-1">{{
                         topUpOrder.facebookAdsId                     }}</a>
                    </td>
                    <td>{{ topUpOrder.note }} <p v-if="topUpOrder.note == '' || topUpOrder.note == null">_</p></td>
                    <td>{{ formatCurrency(topUpOrder.amount) }} {{ topUpOrder.currency }}</td>
                    <td>{{ formatCurrency(topUpOrder.fee) }} VND</td>
                    <td>{{ formatCurrency(topUpOrder.amountDisplay) }} VND</td>
                    <td>
                      <a class="text-gray-600 text-hover-primary mb-1">
                        <div
                        v-if="topUpOrder.status == 'Thất bại'"
                          :class="`badge badge-light-danger`"
                        >
                        {{ this.$NOLIMITResource[this.dataUser.language].Failed }}
                        </div>
                      </a>
                      <a class="text-gray-600 text-hover-primary mb-1">
                        <div
                        v-if="topUpOrder.status == 'Thành công'"
                          :class="`badge badge-light-success`"
                        >
                        {{ this.$NOLIMITResource[this.dataUser.language].Success }}
                        </div>
                      </a>
                      <a class="text-gray-600 text-hover-primary mb-1">
                        <div
                        v-if="topUpOrder.status == 'Đang xử lý'"
                          :class="`badge badge-light-warning`"
                        >
                        {{ this.$NOLIMITResource[this.dataUser.language].Processing }}
                        </div>
                      </a>
                    </td>
                    <td>{{ formatCurrency(topUpOrder.limitBefore) }} {{ topUpOrder.currency }}</td>
                  <td>{{formatCurrency(topUpOrder.limitAfter) }} {{ topUpOrder.currency }}</td>
                    <td>{{ formatDateTime(topUpOrder.createDate) }}</td>
                    
                  </tr>
                </tbody>
              </table>
                </div>
               
              </div>
              <!--end::Table-->
            </div>
            <!--end::Card body-->
          </div>
          <div class="am-chart-block" style="text-align:start">
            <p
                style="text-align: start; margin-left: 20px;text-transform:uppercase;font-size: 15px;color: #071437;"
                data-v-ffde663e=""
                class="card-label fw-bold text-gray-900"
              >
              {{ this.$NOLIMITResource[this.dataUser.language].DashBoard.AdvertisingAccountStatistics }}
              
              </p>
              <span style="margin-left:20px;" class="text-gray-500 mt-1 fw-semibold fs-6"
                > {{ this.$NOLIMITResource[this.dataUser.language].DashBoard.AdAccountMetrics }}</span
              >
            <div id="kt_amcharts_3" style="width: 100%; height: 500px;"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import ApexCharts from "apexcharts";
import {  mapGetters} from "vuex";
export default {
  name: "CombinedCharts",
  data(){
    return{
      dataUser:{language:"VN"},
      isAdmin:false,
      isSupport:false,
      balanceHistory:[],
      colorsAimChart: ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd"],
      valueField: "value",
      categoryField: "category",
      countAllAds: 0,
      countAdsActive: 0,
      countAdsReturn: 0,
      countAdsDie: 0,
      countAdsToday: 0,
      selectedOption:  this.$NOLIMITResource["VN"].DashBoard.One_Year ,
      dropdownOptions: [this.$NOLIMITResource["VN"].DashBoard.One_Year, this.$NOLIMITResource["VN"].DashBoard.One_Week, this.$NOLIMITResource["VN"].DashBoard.One_Day],
      username:"",
      topUpOrders:[],
      onLoading: true,
      hasData: true,
      pageSize: 10,
      balanceUser:0,
      pageNumber: 1,
      sortingAsc: null,
      sortingNumber: null,
      isAccountant:false,
      columns: [
            { label: 'Id topup', width: 250, property: "TopupOrderId" },
            { label:  this.$NOLIMITResource["VN"].NameAds, width: 300, property: "Name" },
            { label: 'FacebookAdsId', width: 300, property: "FacebookAdsId" },
            { label: this.$NOLIMITResource["VN"].Note, width: 300, property: "Note" },
            { label: this.$NOLIMITResource["VN"].Amount, width: 300, property: "Amount" },
            { label: this.$NOLIMITResource["VN"].TopupFee, width: 300, property: "Fee" },
            { label: this.$NOLIMITResource["VN"].PaymentAmount, width: 300, property: "AmountDisplay" },
            { label: this.$NOLIMITResource["VN"].Status, width: 300, property: "Status" },
            { label: this.$NOLIMITResource["VN"].LimitBeforeTopup, width: 300, property: "LimitBefore" },
            { label: this.$NOLIMITResource["VN"].LimitAfterTopup, width: 300, property: "LimitAfter" },
            { label: this.$NOLIMITResource["VN"].Created, width: 300, property: "CreateDate" },
        ],
      columnsAccountant: [
          { label: 'Id topup', width: 250, property: "TopupOrderId" },
          { label:  this.$NOLIMITResource["VN"].NameAds, width: 300, property: "Name" },
          { label: this.$NOLIMITResource["VN"].UserName, width: 300, property: "UserName" },
          { label: 'FacebookAdsId', width: 300, property: "FacebookAdsId" },
          { label: this.$NOLIMITResource["VN"].Note, width: 300, property: "Note" },
          { label: this.$NOLIMITResource["VN"].Amount, width: 300, property: "Amount" },
          { label: this.$NOLIMITResource["VN"].TopupFee, width: 300, property: "Fee" },
          { label: this.$NOLIMITResource["VN"].PaymentAmount, width: 300, property: "AmountDisplay" },
          { label: this.$NOLIMITResource["VN"].Status, width: 300, property: "Status" },
          { label: this.$NOLIMITResource["VN"].LimitBeforeTopup, width: 300, property: "LimitBefore" },
          { label: this.$NOLIMITResource["VN"].LimitAfterTopup, width: 300, property: "LimitAfter" },
          { label: this.$NOLIMITResource["VN"].Created, width: 300, property: "CreateDate" },
      ],
        startDate:null,
        endDate:null,
        totalAdminPlus:0,
        totalAdminMinus:0,
        totalTopupAmount:0,
        totalRecharge:0,
        totalTransferMoney:0,
        totalReceiveMoney:0,
        totalRechargeToday:0,
        totalTopupAmountToday:0,
        totalTransferMoneyToday:0,
        totalReceiveMoneyToday:0,
        totalAdminPlusToday:0,
        totalAdminMinusToday:0,
        dataApexchartMonth:[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        dataApexchartMonthTopup:[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        dataApexchartWeek:[0, 0, 0, 0, 0, 0, 0],
        dataApexchartWeekTopup:[0, 0, 0, 0, 0, 0, 0],
        dataApexchartDay:[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,],
        dataApexchartDayTopup:[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,],
        categoriesMonth: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        months: this.$NOLIMITResource["VN"].DashBoard.Months,
        daysOfWeek: this.$NOLIMITResource["VN"].DashBoard.DaysOfWeek,
      hourOfDay: [
        "1:00",
        "2:00",
        "3:00",
        "4:00",
        "5:00",
        "6:00",
        "7:00",
        "8:00",
        "9:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
        "24:00",
      ],
        apexChart: null,
    }
  },
  watch:{
    dataUser:{
      handler: function() {
        this.selectedOption =  this.$NOLIMITResource[this.dataUser.language].DashBoard.One_Year;
        this.dropdownOptions = [this.$NOLIMITResource[this.dataUser.language].DashBoard.One_Year, this.$NOLIMITResource[this.dataUser.language].DashBoard.One_Week, this.$NOLIMITResource[this.dataUser.language].DashBoard.One_Day];
        this.months = this.$NOLIMITResource[this.dataUser.language].DashBoard.Months;
        this.daysOfWeek = this.$NOLIMITResource[this.dataUser.language].DashBoard.DaysOfWeek;
        this.updateSeriesLine();
        this.updateSeriesLineWeek();
        this.updateSeriesLineDay();
        this.columns =  [
            { label: 'Id topup', width: 250, property: "TopupOrderId" },
            { label:  this.$NOLIMITResource[this.dataUser.language].NameAds, width: 300, property: "Name" },
            { label: 'FacebookAdsId', width: 300, property: "FacebookAdsId" },
            { label: this.$NOLIMITResource[this.dataUser.language].Note, width: 300, property: "Note" },
            { label: this.$NOLIMITResource[this.dataUser.language].Amount, width: 300, property: "Amount" },
            { label: this.$NOLIMITResource[this.dataUser.language].TopupFee, width: 300, property: "Fee" },
            { label:  this.$NOLIMITResource[this.dataUser.language].PaymentAmount, width: 300, property: "AmountDisplay" },
            { label: this.$NOLIMITResource[this.dataUser.language].Status, width: 300, property: "Status" },
            { label: this.$NOLIMITResource[this.dataUser.language].LimitBeforeTopup, width: 300, property: "LimitBefore" },
            { label: this.$NOLIMITResource[this.dataUser.language].LimitAfterTopup, width: 300, property: "LimitAfter" },
            { label: this.$NOLIMITResource[this.dataUser.language].Created, width: 300, property: "CreateDate" },
        ];
      this.columnsAccountant = [
          { label: 'Id topup', width: 250, property: "TopupOrderId" },
          { label: this.$NOLIMITResource[this.dataUser.language].NameAds, width: 300, property: "Name" },
          { label: this.$NOLIMITResource[this.dataUser.language].UserName, width: 300, property: "UserName" },
          { label: 'FacebookAdsId', width: 300, property: "FacebookAdsId" },
          { label: this.$NOLIMITResource[this.dataUser.language].Note, width: 300, property: "Note" },
          { label: this.$NOLIMITResource[this.dataUser.language].Amount, width: 300, property: "Amount" },
          { label: this.$NOLIMITResource[this.dataUser.language].TopupFee, width: 300, property: "Fee" },
          { label: this.$NOLIMITResource[this.dataUser.language].PaymentAmount, width: 300, property: "AmountDisplay" },
          { label: this.$NOLIMITResource[this.dataUser.language].Status, width: 300, property: "Status" },
          { label: this.$NOLIMITResource[this.dataUser.language].LimitBeforeTopup, width: 300, property: "LimitBefore" },
          { label: this.$NOLIMITResource[this.dataUser.language].LimitAfterTopup, width: 300, property: "LimitAfter" },
          { label: this.$NOLIMITResource[this.dataUser.language].Created, width: 300, property: "CreateDate" },
      ];
      },
      deep: true 
    },
    dataApexchartMonth: {
    handler: function() {
      // Gọi phương thức initApexCharts() để khởi tạo lại biểu đồ khi dataApexchartMonth thay đổi
      this.updateSeriesLine();
    },
    deep: true // Watcher sẽ theo dõi sâu vào các phần tử của mảng hoặc đối tượng
  },
  dataApexchartWeek:{
    handler: function() {
      // Gọi phương thức initApexCharts() để khởi tạo lại biểu đồ khi dataApexchartMonth thay đổi
      this.updateSeriesLineWeek();
    },
    deep: true
  },
  dataApexchartDay:{
    handler: function() {
      // Gọi phương thức initApexCharts() để khởi tạo lại biểu đồ khi dataApexchartMonth thay đổi
      this.updateSeriesLineDay();
    },
    deep: true
  },
  countAllAds: {
    handler: function() {
      this.updateChartData(this.series,this.legend);
      
    },
    deep: true
    },
    countAdsActive: {
    handler: function() {
      this.updateChartData(this.series,this.legend);
    },
    deep: true
    },
    countAdsReturn: {
    handler: function() {
      this.updateChartData(this.series,this.legend);
    },
    deep: true
    },
    countAdsDie: {
    handler: function() {
      this.updateChartData(this.series,this.legend);
    },
    deep: true
    },
    countAdsToday: {
    handler: function() {
      this.updateChartData(this.series,this.legend);
    },
    deep: true
    },
  },
  computed:{
    ...mapGetters("listModule", [
      "onShowMenuFilter",
      "checkRow",
      "displayRow",
      "isShowModalPickRow",
      "formatCurrency",
      "formatDateTime",
      "convertToEndOfDay",
      "onShowMenuFilterStatus",
      "getStatusAds",
      "language",
    ]),
    dynamicLabels() {

      const currentTime = new Date();

      return[this.months[(currentTime.getMonth()-11 + 12) % 12],
              this.months[(currentTime.getMonth()-10 + 12) % 12],
              this.months[(currentTime.getMonth()-9 + 12) % 12],
              this.months[(currentTime.getMonth()-8 + 12) % 12],
              this.months[(currentTime.getMonth()-7 + 12) % 12],
              this.months[(currentTime.getMonth()-6 + 12) % 12],
              this.months[(currentTime.getMonth()-5 + 12) % 12],
              this.months[(currentTime.getMonth()-4 + 12) % 12],
              this.months[(currentTime.getMonth()-3 + 12) % 12],
              this.months[(currentTime.getMonth()-2 + 12) % 12],
              this.months[(currentTime.getMonth()-1 + 12) % 12],
              this.months[(currentTime.getMonth() + 12) % 12]]
    },
    dynamicLabelsWeek(){
      const currentTime = new Date();
      return [
        this.daysOfWeek[(currentTime.getDay() - 6 + 7) % 7],
        this.daysOfWeek[(currentTime.getDay() - 5 + 7) % 7],
        this.daysOfWeek[(currentTime.getDay() - 4 + 7) % 7],
        this.daysOfWeek[(currentTime.getDay() - 3 + 7) % 7],
        this.daysOfWeek[(currentTime.getDay() - 2 + 7) % 7],
        this.daysOfWeek[(currentTime.getDay() - 1 + 7) % 7],
        this.daysOfWeek[currentTime.getDay()],
      ];
    },
    dynamicLabelsDay(){
      const currentTime = new Date();
      return [
        this.hourOfDay[(currentTime.getHours() - 23 + 24) % 24],
        this.hourOfDay[(currentTime.getHours() - 22 + 24) % 24],
        this.hourOfDay[(currentTime.getHours() - 21 + 24) % 24],
        this.hourOfDay[(currentTime.getHours() - 20 + 24) % 24],
        this.hourOfDay[(currentTime.getHours() - 19 + 24) % 24],
        this.hourOfDay[(currentTime.getHours() - 18 + 24) % 24],
        this.hourOfDay[(currentTime.getHours() - 17 + 24) % 24],
        this.hourOfDay[(currentTime.getHours() - 16 + 24) % 24],
        this.hourOfDay[(currentTime.getHours() - 15 + 24) % 24],
        this.hourOfDay[(currentTime.getHours() - 14 + 24) % 24],
        this.hourOfDay[(currentTime.getHours() - 13 + 24) % 24],
        this.hourOfDay[(currentTime.getHours() - 12 + 24) % 24],
        this.hourOfDay[(currentTime.getHours() - 11 + 24) % 24],
        this.hourOfDay[(currentTime.getHours() - 10 + 24) % 24],
        this.hourOfDay[(currentTime.getHours() - 9 + 24) % 24],
        this.hourOfDay[(currentTime.getHours() - 8 + 24) % 24],
        this.hourOfDay[(currentTime.getHours() - 7 + 24) % 24],
        this.hourOfDay[(currentTime.getHours() - 6 + 24) % 24],
        this.hourOfDay[(currentTime.getHours() - 5 + 24) % 24],
        this.hourOfDay[(currentTime.getHours() - 4 + 24) % 24],
        this.hourOfDay[(currentTime.getHours() - 3 + 24) % 24],
        this.hourOfDay[(currentTime.getHours() - 2 + 24) % 24],
        this.hourOfDay[(currentTime.getHours() - 1 + 24) % 24],
        this.hourOfDay[(currentTime.getHours()  + 24) % 24],
      ];
    }
  },
  async created() {
   await this.$apiService
   .getUserProfile().then((response) => {
    if(response.data.data.language == "VN" || response.data.data.language == "EN"){
                    this.dataUser = response.data.data;
                }else{
                    this.dataUser = response.data.data;
                    this.dataUser.language = "VN";
                }
       this.idUser = response.data.data.userId;
       this.username = response.data.data.username.charAt(0).toUpperCase();
       this.balanceUser = response.data.data.balance;
       this.isAdmin = response.data.data.isAdmin;
      this.isSupport = response.data.data.isSupport;
       if(response.data.data.isAccountant == true){
         this.isAccountant = true;
         
       }
       this.$apiService
           .getAllTopUpOrder(this.pageNumber, this.pageSize,response.data.data.userId)
           .then((res) => {
           setTimeout(() => {
               this.onLoading = false;
           }, 1000);
           this.topUpOrders = res.data.data;
           if(res.data.data.length == 0){
               this.hasData = false;
             }
          
           this.totalPage = res.data.pageData.pageCount;
           })
           .catch((error) => {
           console.error("Lỗi khi lấy dữ liệu:", error);
           });

           this.$apiService.getAllTopUpOrderNoPaging(response.data.data.userId).then((res)=>{
           for(let i=0;i<res.data.data.length;i++){
                 if(res.data.data[i].status == "Thành công"){
                   this.totalStatusSuccess +=1;
                 }else if(res.data.data[i].status == "Đang xử lý"){
                   this.totalStatusProcessing +=1;
                 }else if(res.data.data[i].status == "Thất bại"){
                   this.totalStatusFailed +=1;
                 }
             }
         })

         this.$apiService.getAllAdsByUserId(response.data.data.userId).then((res) => {
          this.countAllAds = res.data.data.length;
          for (let i = 0; i < res.data.data.length; i++) {
            if (res.data.data[i].shareStatus == "Đang sử dụng") {
              this.countAdsActive += 1;
            }
            if (res.data.data[i].shareStatus == "Đã thu hồi") {
              this.countAdsReturn += 1;
            }
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            this.$apiService
              .getAdsHistoryByAdsIdNoPaging(res.data.data[i].adsId)
              .then((response) => {
                for (let j = 0; j < response.data.data.length; j++) {
                  const deliveryDate = new Date(
                    response.data.data[j].deliveryDate
                  );
                  if (deliveryDate >= today) {
                    this.countAdsToday += 1;
                  }
                }
              });
          }
        });

        if(response.data.data.isAdmin == true || response.data.data.isSupport == true){
          this.$apiService
            .getBalanceHistoryByIdPagingByAdmin(this.pageNumber, this.pageSize)
            .then((res) => {
            setTimeout(() => {
                this.onLoading = false;
            }, 1000);
            this.balanceHistory = res.data.data;
            if(res.data.data.length == 0){
                this.hasData = false;
              }
           
            this.totalPage = res.data.pageData.pageCount;
            })
            .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
            });
        }
           })
       .catch((error) => {
         console.error("Lỗi khi lấy dữ liệu:", error);
       });

      //  await this.$apiService.getRechargeByDate(this.idUser,this.startDate,this.endDate);
       await this.$apiService.getBalanceHistoryById(this.idUser).then((res)=>{
        const currentTime = new Date();
        const startOfDay = new Date(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate(), 0, 0, 0);
       
        for(let i=0;i<res.data.length;i++){
          const transactionTime = new Date(res.data[i].changeDate);

          if (transactionTime >= startOfDay && res.data[i].tag === "Nạp tiền") {
            this.totalRechargeToday += res.data[i].amountDifference;
          }else if (transactionTime >= startOfDay && res.data[i].tag === "Tạo đơn Topup") {
            this.totalTopupAmountToday += res.data[i].amountDifference;
          }else if (transactionTime >= startOfDay && res.data[i].tag === "Admin trừ tiền") {
            this.totalAdminMinusToday += res.data[i].amountDifference;
          }
          else if (transactionTime >= startOfDay && res.data[i].tag === "Admin cộng tiền") {
            this.totalAdminPlusToday += res.data[i].amountDifference;
          }
          else if (transactionTime >= startOfDay && res.data[i].tag === "Chuyển tiền") {
            this.totalTransferMoneyToday += res.data[i].amountDifference;
          }else if (transactionTime >= startOfDay && res.data[i].tag === "Nhận tiền") {
            this.totalReceiveMoneyToday += res.data[i].amountDifference;
          }
            if(res.data[i].tag == "Nạp tiền"){
              this.totalRecharge +=res.data[i].amountDifference;
            }else if(res.data[i].tag == "Tạo đơn Topup"){
              this.totalTopupAmount +=res.data[i].amountDifference;
            }else if(res.data[i].tag == "Admin trừ tiền"){
              this.totalAdminMinus +=res.data[i].amountDifference;
            }else if(res.data[i].tag == "Admin cộng tiền"){
              this.totalAdminPlus +=res.data[i].amountDifference;
            }else if(res.data[i].tag == "Chuyển tiền"){
              this.totalTransferMoney +=res.data[i].amountDifference;
            }else if(res.data[i].tag == "Nhận tiền"){
              this.totalReceiveMoney +=res.data[i].amountDifference;
            }
            for(let j=0;j<12;j++){
              const startOfMonth = new Date(currentTime.getFullYear(), currentTime.getMonth()-j, 1);
              const endOfMonth = new Date(currentTime.getFullYear(), currentTime.getMonth()-j+1, 1);
              if(res.data[i].tag == "Nạp tiền" && transactionTime >= startOfMonth && transactionTime <= endOfMonth){
                this.dataApexchartMonth[11-j] +=res.data[i].amountDifference;
                break;
              }else if(res.data[i].tag == "Tạo đơn Topup" && transactionTime >= startOfMonth && transactionTime <= endOfMonth){
                this.dataApexchartMonthTopup[11-j] +=res.data[i].amountDifference;
                break;
              }
          }
          for(let j=0;j<7;j++){
            const startOfDay = new Date(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate() - j, 0, 0, 0);
             const endOfDay = new Date(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate() - j, 23, 59, 59);
              if(res.data[i].tag == "Nạp tiền" && transactionTime >= startOfDay && transactionTime <= endOfDay){
                this.dataApexchartWeek[6-j] +=res.data[i].amountDifference;
                break;
              }else if(res.data[i].tag == "Tạo đơn Topup" && transactionTime >= startOfDay && transactionTime <= endOfDay){
                this.dataApexchartWeekTopup[6-j] +=res.data[i].amountDifference;
                break;
              }
          }
          for(let j=0;j<24;j++){
            const startOfHour = new Date(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate(), currentTime.getHours() - j, 0, 0, 0);
             const endOfHour = new Date(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate(), currentTime.getHours() - j, 59, 59, 999);
              if(res.data[i].tag == "Nạp tiền" && transactionTime >= startOfHour && transactionTime <= endOfHour){
                this.dataApexchartDay[23-j] +=res.data[i].amountDifference;
                break;
              }else if(res.data[i].tag == "Tạo đơn Topup" && transactionTime >= startOfHour && transactionTime <= endOfHour){
                this.dataApexchartDayTopup[23-j] +=res.data[i].amountDifference;
                break;
              }
          }
          // Định dạng lại các số trong mảng dataApexchart


        }

       });
   },
  mounted() {
    this.$nextTick(() => {
      this.initAmCharts();
      this.initApexCharts();

    })
  
  },
  beforeUnmount() {
    if (this.amRoot) {
      this.amRoot.dispose();
    }
    if (this.apexChart) {
      this.apexChart.destroy();
    }
    
  },


  methods: {

      initAmCharts() {

      // Create root element
      this.amRoot = am5.Root.new("kt_amcharts_3");
 
      // Set themes
      this.amRoot.setThemes([am5themes_Animated.new(this.amRoot)]);

      // Create chart
      var chart = this.amRoot.container.children.push(
        am5percent.PieChart.new(this.amRoot, {
          layout: this.amRoot.verticalLayout,
        })
      );

      // Create series
      var series = chart.series.push(
        am5percent.PieSeries.new(this.amRoot, {
          alignLabels: true,
          calculateAggregates: true,
          valueField: this.valueField,
          categoryField: this.categoryField,
        })  
      );

      series.slices.template.setAll({
        strokeWidth: 3,
        stroke: am5.color(0xffffff),
      });

      series.labelsContainer.set("paddingTop", 30);

      // Set up adapters for variable slice radius
      series.slices.template.adapters.add("radius", function (radius, target) {
        var dataItem = target.dataItem;
        var high = series.getPrivate("valueHigh");

        if (dataItem) {
          var value = target.dataItem.get("valueWorking", 0);
          return (radius * value) / high;
        }
        return radius;
      });

      // Set data
      // series.data.setAll([
      //   { value: this.countAllAds, category: "Tất cả tài khoản đã sử dụng:" },
      //   { value: 9, category: "Số tài khoản hoạt động:" },
      //   { value: 6, category: "Số tài khoản thu hồi:" },
      //   { value: 6, category: "Số tài khoản chết:" },
      //   { value: 6, category: "Số tài khoản cấp trong ngày:" },
      // ]);
     

      series.labels.template.set("text", "{value}");
      // Create legend
      var legend = chart.children.push(
        am5.Legend.new(this.amRoot, {
          centerX: am5.p50,
          x: am5.p50,
          marginTop: 15,
          marginBottom: 15,
        })
      );

      legend.labels.template.set("text", "{category}");
        // Set colors for legend markers
        legend.markers.template.setAll({
        width: 12,
        height: 12,
        strokeWidth: 0,
        fillOpacity: 1
      });

      legend.markers.template.adapters.add("fill", (fill, target) => {
        var dataItem = target.dataItem;
        if (dataItem) {
          return dataItem.get("color");
        }
        return fill;
      });
      this.updateChartData(series,legend);

      // Play initial series animation
      series.appear(1000, 100);

      this.series = series;
    },

    initApexCharts() {
    
    const element = document.getElementById("kt_apexcharts_3");
    if (!element) {
      return;
    }

    const height = 400;
    const labelColor = "#99a1b7";

    const baseColor = "#7239ea";
    const lightColor = "#f8f5ff";
    const borderColor = "#f1f1f4";
    const colors = ["#7239ea", "#ef875c", "#f6cb70"];

    const options = {
      series: [
        {
          name: this.$NOLIMITResource[this.dataUser.language].DashBoard.Recharge,
          data: this.dataApexchartMonth, // Thay đổi dữ liệu phù hợp  
        },
        {
          name: this.$NOLIMITResource[this.dataUser.language].DashBoard.Topup,
          data: this.dataApexchartMonthTopup, // Thay đổi dữ liệu phù hợp
        },
        // {
        //   name: "Tk giao",
        //   data: [20, 25, 30, 40, 35, 30, 35, 50, 55, 45, 35, 40], // Thay đổi dữ liệu phù hợp
        // },
      ],
      chart: {
        fontFamily: "inherit",
        type: "area",
        height: height,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {},
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "solid",
        opacity: 1,
      },
      stroke: {
        curve: "smooth",
        show: true,
        width: 3,
        colors: colors,
      },
      xaxis: {
        categories: this.dynamicLabels,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
        crosshairs: {
          position: "front",
          stroke: {
            color: baseColor,
            width: 1,
            dashArray: 3,
          },
        },
        tooltip: {
          enabled: true,
          offsetY: 0,
          style: {
            fontSize: "12px",
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
          formatter: function (value) {
      // Kiểm tra nếu là số nguyên thì hiển thị như số nguyên
      return Number.isInteger(value) ? value.toLocaleString("en-US") : value.toFixed(2);
    },
        },
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
        },
        y: {
          formatter: function (val) {
            if (val == null) {
              return "_";
            }

            // Kiểm tra xem có số thập phân hay không
            if (Number.isInteger(val)) {
                // Nếu là số nguyên, chỉ cần định dạng nguyên với toLocaleString
                return val.toLocaleString("en-US") + ' đ';
            } else {
                // Nếu có số thập phân, làm tròn đến hai chữ số sau dấu phẩy
                const roundedNumber = Number(val.toFixed(2));
                return roundedNumber.toLocaleString("en-US") + ' đ';
            }
            
          },
        },
      },
      colors: [lightColor],
      grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      markers: {
        strokeColor: "#ef875c",
        strokeWidth: 3,
      },
    };


    this.apexChart = new ApexCharts(element, options);
    this.apexChart.render();
  },

  formatCurrencyA(number) {
      if (number == null) {
        return "_";
      }

      if (Number.isInteger(number)) {
        return number.toLocaleString("en-US");
      } else {
        const roundedNumber = Number(number.toFixed(2));
        return roundedNumber.toLocaleString("en-US");
      }
    },
    updateSeriesLine() {
      if (this.apexChart) {
        this.apexChart.updateSeries([
      {
          name: this.$NOLIMITResource[this.dataUser.language].DashBoard.Recharge,
          data: this.dataApexchartMonth, // Thay đổi dữ liệu phù hợp  
        },
        {
          name: this.$NOLIMITResource[this.dataUser.language].DashBoard.Topup,
          data: this.dataApexchartMonthTopup, // Thay đổi dữ liệu phù hợp
        },
        // {
        //   name: "Tk giao",
        //   data: [20, 25, 30, 40, 35, 30, 35, 50, 55, 45, 35, 40], // Thay đổi dữ liệu phù hợp
        // },
      ], false, true);
      this.apexChart.updateOptions({
            xaxis: {
                categories: this.dynamicLabels
            }
        });
    }
  }, 

  updateSeriesLineWeek() {
      if (this.apexChart) {
        this.apexChart.updateSeries([
      {
          name: this.$NOLIMITResource[this.dataUser.language].DashBoard.Recharge,
          data: this.dataApexchartWeek, // Thay đổi dữ liệu phù hợp  
        },
        {
          name: this.$NOLIMITResource[this.dataUser.language].DashBoard.Topup,
          data:this.dataApexchartWeekTopup, // Thay đổi dữ liệu phù hợp
        },
      ], false, true);
      this.apexChart.updateOptions({
            xaxis: {
                categories: this.dynamicLabelsWeek
            }
        });
    }
  }, 
  updateSeriesLineDay() {
      if (this.apexChart) {
        this.apexChart.updateSeries([
      {
          name: this.$NOLIMITResource[this.dataUser.language].DashBoard.Recharge,
          data: this.dataApexchartDay, // Thay đổi dữ liệu phù hợp  
        },
        {
          name: this.$NOLIMITResource[this.dataUser.language].DashBoard.Topup,
          data:this.dataApexchartDayTopup, // Thay đổi dữ liệu phù hợp
        },
      ], false, true);
      this.apexChart.updateOptions({
            xaxis: {
                categories: this.dynamicLabelsDay
            }
        });
    }
  }, 
  updateChartData(series) {
      series.data.setAll([
        { value: this.countAllAds, category: this.$NOLIMITResource[this.dataUser.language].DashBoard.NumberOfAccountsUsed },
        { value: this.countAdsActive, category: this.$NOLIMITResource[this.dataUser.language].DashBoard.NumberOfActiveAccounts },
        { value: this.countAdsReturn, category: this.$NOLIMITResource[this.dataUser.language].DashBoard.NumberOfAccountsWithdrawn },
        { value: this.countAdsDie, category: this.$NOLIMITResource[this.dataUser.language].DashBoard.NumberOfDeadAccounts },
        { value: this.countAdsToday, category: this.$NOLIMITResource[this.dataUser.language].DashBoard.NumberOfAccountsGrantedToday },
      ]);

      series.set("valueField", this.valueField);
      series.set("categoryField", this.categoryField);
      series.labels.template.set("text", "{category}: {value}");
  


    },
    
  selectOption(option) {
      this.selectedOption = option;

      // Update series based on the selected option 
      if (option === this.$NOLIMITResource[this.dataUser.language].DashBoard.One_Year) {
        this.updateSeriesLine();
      } else if (option === this.$NOLIMITResource[this.dataUser.language].DashBoard.One_Week) {
        this.updateSeriesLineWeek();
      } else if (option === this.$NOLIMITResource[this.dataUser.language].DashBoard.One_Day) {
        this.updateSeriesLineDay();
      }

      // Move the selected option to the top
      this.dropdownOptions = this.dropdownOptions.filter(opt => opt !== option);
      this.dropdownOptions.unshift(option);
    },
    getFirstUsername(name) {
      if (name.length > 0) {
        return  name.charAt(0).toUpperCase();
      } else {
        return'';
      }
    },
    formatDateRemain(createDate) {
      let currentDate = new Date();
      const createdDateTime = new Date(createDate);
      const timeDifference = currentDate - createdDateTime;
      const oneMinuteInMillis = 60 * 1000;
      const oneHourInMillis = 60 * oneMinuteInMillis;
      const oneDayInMillis = 24 * oneHourInMillis;

      if (timeDifference < oneMinuteInMillis) {
        // Nếu thời gian tạo nhỏ hơn 1 phút
        const seconds = Math.floor(timeDifference / 1000);
        return `${seconds} giây trước`;
      } else if (timeDifference < oneHourInMillis) {
        // Nếu thời gian tạo nhỏ hơn 1 giờ
        const minutes = Math.floor(timeDifference / oneMinuteInMillis);
        return `${minutes} phút trước`;
      } else if (timeDifference < oneDayInMillis) {
        // Nếu thời gian tạo nhỏ hơn 1 ngày
        const hours = Math.floor(timeDifference / oneHourInMillis);
        return `${hours} giờ trước`;
      } else if (timeDifference < 2 * oneDayInMillis) {
        // Nếu thời gian tạo là hơn 1 ngày trước
        return "1 ngày trước";
      } else {
        // Nếu thời gian tạo lớn hơn 1 ngày
        const days = Math.floor(timeDifference / oneDayInMillis);
        return `${days} ngày trước`;
      }
    },
 
    
  },
};
</script>

<style scoped>
/* Add any styles you need here */
.apex-chart-block {
  padding: 20px;
  border-radius: 10px;
  background: #fff;
  width: 80% !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.am-chart-block {
  padding: 20px;
  border-radius: 10px;
  background: #fff;
  width: 40%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.legend-container {
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 20px;
}

.legend-item {
  display: flex;
  align-items: center;
  gap: 5px;
}

.legend-color {
  width: 20px;
  height: 20px;
  border-radius: 4px;
}

.color-napvi {
  background-color: #7239ea;
}

.color-topup {
  background-color: #ef875c;
}

.color-tkgiao {
  background-color: #f6cb70;
}

.dropdown-container {
  margin-left: auto;
  position: relative;
}

.dropdown {
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  background-color: white;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 100px;
}

.dropdown-menu a {
  display: block;
  padding: 10px;
  text-decoration: none;
  color: black;
}

.dropdown-menu a:hover {
  background-color: #f0f0f0;
}
.header-apex-chart {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* Style Card header */
.card-container {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  flex: 1 1 20%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}
.card-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #fff;
}
.card-content {
  margin-top: 10px;
}
.card-content .amount {
  font-size: 24px;
  font-weight: bold;
}
.card-content .description {
  color: #888;
  font-size: 14px;
  margin-top: 5px;
}
.card-content .total {
  font-size: 18px;
  margin-top: 10px;
}
.card-blue {
  color: #546e7a;
}
.card-blue .card-icon {
  background-color: #546e7a;
}
.card-red {
  color: #e91e63;
}
.card-red .card-icon {
  background-color: #e91e63;
}
.card-orange {
  color: #ff9800;
}
.card-orange .card-icon {
  background-color: #ff9800;
}
.card-green {
  color: #4caf50;
}
.card-green .card-icon {
  background-color: #4caf50;
}
.card-more {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  color: #aaa;
  cursor: pointer;
}
.ki-duotone {
  font-size: 4rem;
  color: #fff;
}

/** style card user */

.card-user {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 18%;
  height: 525px;
}
.card-header-user {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.card-header-user h3 {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}
.card-header-user a {
  font-size: 14px;
  color: #1b84ff;
  text-decoration: none;
}
.user-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.user-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.user-item h1 {
  background: #1b84ff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;

}
.circle {
  width: 40px;
  height: 40px;
  background-color: #4CAF50; /* Màu nền của khung tròn */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: white;
  font-weight: bold;
}
.user-info {
  flex-grow: 1;
}
.user-info h4 {
  margin: 0;
  font-size: 14px;
  color: #333;
}
.user-info p {
  margin: 0;
  font-size: 12px;
  color: #888;
}
.user-amount {
  font-size: 14px;
  color: #1b84ff;
  font-weight: bold;
  min-width: 120px;
}
.row-2-apexchart {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.row-3-amchart {
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 20px;
}

.table thead th:last-child, .table tbody td:last-child {
    position: relative !important;
    right: -1px;
    top: 4px;
    min-width: 150px !important;
    width: 120px;
    display: flex;
    align-items: center;
    z-index: 2;
    border-top: none;
    background: #fff;
    border-bottom: none;
}
.block-topup-history{
  width:58%;
  height:596px;
}

@media only screen and (min-width: 100px) {
  .apex-chart-block {
    padding: 20px;
    border-radius: 10px;
    background: #fff;
    width: 100% !important;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-bottom: 20px;
  }
  .card-user {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100% !important;
  height: 525px;
}
.row-2-apexchart{
  display: block;
}
.block-topup-history{
  width:100% !important;
  height:596px;
}
.am-chart-block {
  padding: 20px;
  border-radius: 10px;
  background: #fff;
  width: 100% !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.row-3-amchart {
  display: block;

}
.card{
  flex: 1 1 90%;
}
}
@media only screen and (min-width: 768px) {
  .card{
  flex: 1 1 40%;
}
}

@media only screen and (min-width: 1700px) {
  .apex-chart-block {
    padding: 20px;
    border-radius: 10px;
    background: #fff;
    width: 100% !important;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-bottom: 20px;
  }
  .card-user {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100% !important;
  height: 525px;
}
.row-2-apexchart{
  display: block;
}
.block-topup-history{
  width:100% !important;
  height:596px;
}
.am-chart-block {
  padding: 20px;
  border-radius: 10px;
  background: #fff;
  width: 100% !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.row-3-amchart {
  display: block;
}

.card{
  flex: 1 1 40%;
}

}

@media only screen and (min-width: 2200px) {
  .row-3-amchart {
  display: flex;

}
  .block-topup-history{
  width:58% !important;
  height:596px;
}
.am-chart-block {
  padding: 20px;
  border-radius: 10px;
  background: #fff;
  width: 40% !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
  .apex-chart-block {
    padding: 20px;
    border-radius: 10px;
    background: #fff;
    width: 80% !important;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-bottom: 0px;
  }
  .card-user {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 18% !important;
  height: 525px;
}
.row-2-apexchart{
  display: flex;
}
.bg-recharge{
  background-image: url("./../../../../public/frontend/media/svg/shapes/top-green.png");
}

.card{
  flex: 1 1 20%;
}
}
</style>
